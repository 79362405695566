import { ActivatedRouteSnapshot} from '@angular/router';
import { AppConfigurationService } from './service/app-configuration.service';
import { Injectable } from '@angular/core';
import { NavigationService } from './service/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class featureGuard  {
  constructor(
    public configService: AppConfigurationService,
    public navigationService: NavigationService,
  ) { 
  }

  canActivate(route: ActivatedRouteSnapshot) {
    const permission = this.configService.featureFlags[route.data['feature']];
    if (permission)
       return true;
     this.navigationService.navigateTo(['access-denied']); 
     return false;
  }
}
 