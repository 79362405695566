import { Injectable } from '@angular/core';
import { AuthService } from '../auth.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { facility, fieldPreferencesKey } from '../constants/auth.constant';
import { PreferenceFieldConfig, alconHttpOptions, preferenceTypes, surgeryFieldsConfig } from '../constants/constants';
import { of, map, BehaviorSubject, Observable } from 'rxjs';
import { fieldAdminPreferenceTypes } from '../constants/constants';
import { requiredOrOptional } from 'src/app/model/adminFormField';
import { FieldPreference, PreferencesData } from '../model/preference.model';
import { SessionStorageService } from '../session-storage.service';
import  * as fieldPreferenceMapping  from '../constants/field-preference-mapping.json';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class PreferencesService {

  private mccApiURL = environment.apiUrl + '/' + environment.ccApiRoute;
  private fieldPreferencesList: any;
  private fieldPreferences:  BehaviorSubject<PreferencesData | null> = new BehaviorSubject<PreferencesData | null>(null);
  exchangeSurgeryFieldsList!: any;
  cataractSurgeryFieldsList!: any;
  iolFields!: any;
  patientFieldsList!: any;
  otherSurgeryFieldsList!: any;
  allRequiredFields!:any;

  allRequiredFieldsSubject =new BehaviorSubject<{string:string[]}>(this.allRequiredFields);

  exchangeSurgeryFieldsSubject = new BehaviorSubject<string[]>(this.exchangeSurgeryFieldsList);
  exchangeSurgeryFields$ = this.exchangeSurgeryFieldsSubject.asObservable();

  cataractSurgeryFieldsSubject = new BehaviorSubject<string[]>(this.cataractSurgeryFieldsList);
  cataractSurgeryFields$ = this.cataractSurgeryFieldsSubject.asObservable();

  iolFieldsSubject = new BehaviorSubject<string[]>(this.iolFields);
  iolFields$ = this.iolFieldsSubject.asObservable();

  patientFieldsListSubject = new BehaviorSubject<string[]>(this.patientFieldsList);
  patientFields$ = this.patientFieldsListSubject.asObservable();

  otherSurgeryFieldsSubject = new BehaviorSubject<string[]>(this.otherSurgeryFieldsList);
  otherSurgeryFields$ = this.otherSurgeryFieldsSubject.asObservable();

  patientInfoReqdFieldsObjSubject  = new BehaviorSubject<any>({})

  otherSurgeryFieldsObjSubject = new BehaviorSubject<any>({});

  fieldPreferenceMapping = JSON.parse(JSON.stringify(fieldPreferenceMapping));
  
  constructor(
    private authService: AuthService,
    private httpClient: HttpClient,
    private sessionStorageService: SessionStorageService,
    private sharedService: SharedService
  ) { this.getAdminFieldPreferences();
    this.setFieldsObjSubject();
   }

  getPreferencesAPI(practice_org?: string){
    if(!practice_org){
      practice_org = this.authService.practiseOrg;
    }
    const url = this.mccApiURL + '/preferences?practiceOrg='+practice_org+'&practiceType='+facility.clinic+'&preferenceType=IOL';
    return this.httpClient
        .get<any[]>(url, alconHttpOptions);
  }

  setFieldsObjSubject() {
    this.patientInfoReqdFieldsObjSubject  = new BehaviorSubject<any>({
      email: false,
      patientPhoneNumber: false,
      alternatePhoneNumber: false,
      alternateContactName: false,
      allergies: false,
      timeOfDayPreference: false,
      patientComments: false,
      preferredLanguage: false,
      title: false,
      middleName: false
    })

    this.otherSurgeryFieldsObjSubject = new BehaviorSubject<any>({
      surgeryDate: false,
      caseComplexity: false,
      medicalClearance: false,
      surgeryComments: false,
      surgeryName: false,
      surgerySite: false
    })
  }

  getAdminFieldPreferences() {
    this.setFieldsObjSubject();
    this.fieldPreferencesList = this.getFieldAdminPreference(fieldAdminPreferenceTypes.allFields).pipe(map((res: any) => (res.fieldPreferences)))
    if(this.fieldPreferencesList) {
      this.fieldPreferencesList.subscribe({
        next:(preferences: any) => {
          if(preferences) {
            let allRequiredFieldArray:any = []
            preferences.forEach((preferenceObj: any) => {
              if(preferenceObj.preferenceType === fieldAdminPreferenceTypes.ExchangeSurgeryFields && preferenceObj.preferenceValue === requiredOrOptional.requiredForSurgery) {
                allRequiredFieldArray.exchangeSurgeryFields = [ ...preferenceObj.fieldsList]
                this.updateExchangeSurgeryFieldList(preferenceObj.fieldsList);
              }

              if(preferenceObj.preferenceType === fieldAdminPreferenceTypes.CataractSurgeryFields && preferenceObj.preferenceValue === requiredOrOptional.requiredForSurgery) {
                allRequiredFieldArray.cataractSurgeryFields = [ ...preferenceObj.fieldsList]
                this.updateCataractSurgeryFieldList(preferenceObj.fieldsList);
              }

              if(preferenceObj.preferenceType === fieldAdminPreferenceTypes.iolFields && preferenceObj.preferenceValue === requiredOrOptional.requiredForSurgery) {
                allRequiredFieldArray.iolFields = [ ...preferenceObj.fieldsList]
                this.updateIOLFieldsList(preferenceObj.fieldsList);
              }

              if(preferenceObj.preferenceType === fieldAdminPreferenceTypes.PatientFieldPreference && preferenceObj.preferenceValue === requiredOrOptional.requiredForSurgery) {
                allRequiredFieldArray.patientFields = [ ...preferenceObj.fieldsList]
                this.updatePatientFieldInfo(preferenceObj.fieldsList);
                this.patientFields$.subscribe({
                  next: (fieldValue: any) => {
                      const patientInfoReqdFieldsObj : any = this.patientInfoReqdFieldsObjSubject.getValue();
                      fieldValue.forEach((ele: any) => {
                      for(let key in patientInfoReqdFieldsObj) {
                        if(ele === key) {
                          patientInfoReqdFieldsObj[key] = true;
                          const patientInfoReqdFieldsObjUpdate = {...patientInfoReqdFieldsObj }
                          this.patientInfoReqdFieldsObjSubject.next(patientInfoReqdFieldsObjUpdate);
                        }
                      }
                     })
                  }
                })
              }

              if(preferenceObj.preferenceType === fieldAdminPreferenceTypes.OtherSurgeryFields && preferenceObj.preferenceValue === requiredOrOptional.requiredForSurgery) {
                allRequiredFieldArray.otherSurgeryFields = [ ...preferenceObj.fieldsList]
                this.updateOtherSurgeryFieldsInfo(preferenceObj.fieldsList);
                this.otherSurgeryFields$.subscribe({
                  next: (fieldValue: any) => {
                      const otherSurgeryFieldsObj = this.otherSurgeryFieldsObjSubject.getValue();
                      fieldValue.forEach((ele: any) => {
                      for(let key in otherSurgeryFieldsObj) {
                        if(ele === key) {
                          otherSurgeryFieldsObj[key] = true;
                          const otherSurgeryFieldsObjUpdate = {...otherSurgeryFieldsObj }
                          this.otherSurgeryFieldsObjSubject.next(otherSurgeryFieldsObjUpdate);
                        }
                      }
                     })
                  }
                })
              }
              this.allRequiredFieldsSubject.next(allRequiredFieldArray);
            })
          }
        }
      })
    }

  }

  getFieldPreferences(): Observable<any> {
    const sessionData = this.sessionStorageService.getItem(fieldPreferencesKey);
    if (sessionData) {
      // If data is found, emit
      this.fieldPreferences.next(sessionData);
    } else {
      // If not found, make an API call to fetch the data
      this.getFieldAdminPreference(fieldAdminPreferenceTypes.allFields).subscribe({
        next:(preferences: PreferencesData) => { 
          // Save the data to session storage
          this.sessionStorageService.setItem(fieldPreferencesKey, preferences);
          // Emit the new data through the BehaviorSubject
          this.fieldPreferences.next(preferences);
        },
        error:() => {
          this.fieldPreferences.next({fieldPreferences: []});
        }
      });
    }
    return this.fieldPreferences.asObservable();
  }

  getFieldPreference(preferences: FieldPreference[]) {
    const surgeryFields = surgeryFieldsConfig.map(fieldConfig => ({ ...fieldConfig }));
    surgeryFields.forEach((preference) => {
      preference.requiredFields = this.getFieldsList(preferences, requiredOrOptional.requiredForSurgery, preference.preferenceType);
    });
    const patientFieldPref = preferences.find(fp => fp.preferenceType === preferenceTypes.patientForm && fp.preferenceValue === requiredOrOptional.requiredForSurgery) || {fieldsList: []};
    const patientFields = patientFieldPref.fieldsList.map((field: string) => ( this.fieldPreferenceMapping[field] ) ? this.fieldPreferenceMapping[field] : field);
    return {surgeryFields, patientFields};
  }

  getFieldsList(preferences: FieldPreference[], preferenceValue: string, preferenceType: string) {
    const fieldPreference = preferences.find(fp => fp.preferenceValue === preferenceValue && fp.preferenceType === preferenceType);
    return fieldPreference ? fieldPreference.fieldsList.map( (field: string) => ( this.fieldPreferenceMapping[field] ) ? this.fieldPreferenceMapping[field] : field ) : [];
  }

  getallRequiredFields(): any {
    return  this.allRequiredFieldsSubject.getValue();
  }


  updateExchangeSurgeryFieldList(fieldValue: any) { 
    this.exchangeSurgeryFieldsSubject.next(fieldValue);
  }

  updatePatientFieldInfo(patientFields: any) {
    this.patientFieldsListSubject.next(patientFields);
  }

  updateOtherSurgeryFieldsInfo(OtherSurgeryFields: any) {
    this.otherSurgeryFieldsSubject.next(OtherSurgeryFields);
  }

  updateCataractSurgeryFieldList(cataractSurgeryFields: any) {
    this.cataractSurgeryFieldsSubject.next(cataractSurgeryFields);
  }

  updateIOLFieldsList(iolFields: any) {
    this.iolFieldsSubject.next(iolFields);
  }

  getFieldAdminPreference(preferenceType: string) {
    let practice_org = this.authService.practiseOrg;
    let practice_type = this.authService.accountType;

    const url = this.mccApiURL + '/preferences?practiceOrg='+practice_org+'&practiceType='+practice_type+'&preferenceType='+preferenceType;
    return this.httpClient
        .get<PreferencesData>(url, alconHttpOptions);

  }

  public mapRowValues(row: any) {
    if(!row) return ''
    if(row === 'model') return 'iolModel';
    if(row === 'diopter') return 'iolDiopter';
    if(row === 'phone') return 'patientPhoneNumber';
    if(row === 'additionalInfo.patientTimeOfDayPreference') return 'timeOfDayPreference';
    if(row === 'additionalInfo.allergyOther') return 'allergies';
    if(row === 'languageDisplay') return 'preferredLanguage';
    if(row === 'additionalInfo.signLanguage') return 'signLanguageInterpreter';
    if(row === 'additionalInfo.comments') return 'patientComments';
    if(row === 'prefix') return 'title';
    if(row === 'surgeryData.surgeryDate') return 'surgeryDate';
    if(row === 'surgeryData.complexity') return 'caseComplexity';
    if(row === 'surgeryData.setFor') return 'setFor';
    if(row === 'surgeryData.postopMeds') return 'postOpMedications';
    if(row === 'surgeryData.firstOrSecondEye') return '1stOr2nd';
    if(row === 'surgeryData.clearance') return 'medicalClearance';
    if(row === 'ORA') return 'ora';
    if(row === 'Femto') return 'femto';
    if(row === 'surgeryData.generalAnesthesia') return 'generalAnesthesia';
    if(row === 'surgeryData.surgeryComment') return 'surgeryComments';
    if(row === 'surgeryData.supplies') return 'supplies';
    if(row === 'surgeryData.surgeryName') return 'surgeryName';
    if(row === 'surgeryData.surgerySite') return 'surgerySite';

    return row;
}

 public mapPatientInfo( field: string) {

  if(!field) return ''
  if(field === 'title') return 'prefix';
  if(field === 'patientPhoneNumber') return 'phone';
  if(field === 'preferredLanguage') return 'languageDisplay';
  return field;
 }

 public mapPatientAdditionalInfo(field:string) {
  if(!field) return ''
  if(field === 'timeOfDayPreference') return 'patientTimeOfDayPreference';
  if(field === 'allergies') return 'allergyOther';
  if(field === 'patientComments') return 'comments';
  return field;
 }

 public mapSurgeryFields (field: string) {
  if(!field) return ''
  if(field === 'caseComplexity') return 'complexity';
  if(field === 'medicalClearance') return 'clearance';
  if(field === '1stOr2nd') return 'firstOrSecondEye';
  if(field === 'supplies') return 'otherSupplies';
  if(field === 'postOpMedications') return 'postopMeds';
  if(field === 'surgeryComments') return 'surgeryComment';
  return field;
 }

 public mapIolFields(field: any) {
  if(!field) return ''
  if(field === 'diopter') return 'iolDiopter';
  if(field === 'lensModel') return 'iolModel';
  return field;
 }

}
