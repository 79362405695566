import { Component, DestroyRef, Inject, Input } from '@angular/core';
import { AuthService } from '../../shared/auth.service';
import { OktaAuth } from '@okta/okta-auth-js';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { SessionStorageService } from 'src/app/shared/session-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { facility } from 'src/app/shared/model/account.model';
import { SubHeaderComponent } from './sub-header/sub-header.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import {AdiHeaderModule} from '@alcon/adi-header-lib';
import { AlconAccountSelectorModule } from '@alcon/alcon-account-selector';
import { environment } from 'src/environments/environment';
import { NavigationService } from 'src/app/shared/service/navigation.service';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { IdleService } from 'src/app/shared/service/idle.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { adiStorageKey, pagePermission } from 'src/app/shared/constants/auth.constant';
import { PermissionService } from 'src/app/shared/service/permission.service';
import { ViewOnlyAccessIndicatorComponent } from './view-only-access-indicator/view-only-access-indicator.component';
import { SharedService } from 'src/app/shared/service/shared.service';
@Component({
  selector: 'app-header',
  standalone: true,
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [SubHeaderComponent, MatMenuModule, MatIconModule, CommonModule, FormsModule, ReactiveFormsModule, RouterModule, AdiHeaderModule, AlconAccountSelectorModule, ViewOnlyAccessIndicatorComponent]
})
export class HeaderComponent {
  @Input() facilitiesSource: facility[] = [];
  @Input() AccountFacilityID: string = '';
  showFlyout: boolean = false;
  facilitiesVisible = false;
  currentFacility:facility={};
  accountForm = new FormGroup({
    account: new FormControl('')
  });
  headerEndPoint = environment.headerApiEndPoint;
  headerSource = environment.headerSource;
  isAccountLoaded = false;
  showViewOnlySection = false;
  currentLocale = localStorage.getItem(adiStorageKey.lang) ?? 'en_US';
  constructor(
    public authService: AuthService,
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    public authStateService: OktaAuthStateService,
    private sessionStorageService: SessionStorageService,
    public dialog: MatDialog,
    public router: Router,
    public navigationService: NavigationService,
    private loaderService: LoaderService,
    private idleService: IdleService,
    private destroyRef: DestroyRef,
    private permissionService: PermissionService,
    private readonly sharedService: SharedService
    ) {
      this.authService.entitlementValue$.pipe(
        takeUntilDestroyed(this.destroyRef)
      ).subscribe((event: boolean)=>{
        if(event)
          this.isAccountLoaded = true;
      })

      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd && !this.router.url.endsWith(this.currentLocale+ '/')) {
          this.checkIfViewOnlyAccess();
        }
      }
    );
    }

  ngOnChanges(){
    if(this.AccountFacilityID && this.facilitiesSource?.length){
      this.setCurrentFacility(this.AccountFacilityID);
    }
  }

  checkIfViewOnlyAccess(){
    this.showViewOnlySection = !this.permissionService.appPermission[pagePermission.surgeries].manageMode && !this.sharedService.isAdminURL();
  }

  async logout()
  {
      this.authService.logout();
  }

  hideMenu() {
    this.showFlyout = false;
  }

  private setCurrentFacility(accountFacilityID: string){
    this.currentFacility = this.facilitiesSource.find((facility: any)=>facility.practiceOrg === accountFacilityID) as facility;
  }

  logoutLoader($event: any){
    if($event){
      this.idleService.stopIdle();
      this.loaderService.showLoader();
      this.sessionStorageService.clear();
    }
    else
      this.loaderService.hideLoader();
  }

}
