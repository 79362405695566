import { Injectable } from '@angular/core';
import { GraphqlService } from './graphql.service';
import { InORExperienceKeys } from 'src/app/pages/or-experience/constants/alcon-or-experience.constants';
import { AuthService } from '../../auth.service';
import { patientFilterInput } from 'src/app/pages/patient/patient-dashboard/models/patient-dashboard.model';
import { UtilityService } from '../utility.service';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class PatientGraphqlService {

  constructor(private graphqlService: GraphqlService,
        private authService: AuthService,
        private utilityService: UtilityService
) { }

   getPatientDetailsGraphQuery(config:any){
    let argString = "";
    if(config.limit !== undefined || config.limit !== null){
      argString += "limit: " + config.limit + " "
    }
 
    if(config.offset !== undefined || config.offset !== null){
      argString += "offset: " + config.offset + " "
    }
 
    if(config.patientId !== undefined || config.patientId !== null){
      argString += `where: {_text: {field: "patient.patientId", op: EQ, value: "${config.patientId}"}}`
    }
    const query = `query MyQuery {
      patientResponse(${argString}) {
        totalCount
        patients {
          birthDate
          firstName
          middleName
          lastName
          prefix
          createdAt
          createdFacility {
            name
            practiceOrg
          }
          associatedMrnMappings {
            associatedMrn
            associatedPracticeId
            createdAt
            createdBy
            parentPracticeId
            patientId
            updatedAt
            updatedBy
          }
          
          gender
          email
          mrn
          languageDisplay
          languageCode
          practiceId
          additionalInfo {
            comments
            diabetesStatus
            allergyOther
            translatorNeeded
            patientTimeOfDayPreference
            alternateContactName
            alternatePhoneNumber
            phoneNumberCountryCode
            signLanguage
            phoneNumberCountryCode
            applicationMeta {
              srcSystemInformation {
                  createdSrcSystem
                  createdSrcSystemType
                  createdAt
                  latestUpdatedSrcSystem
                  latestUpdatedSrcSystemType
                  latestUpdateAt
                  lastUpdateSrcInformation {
                      lastUpdateAt
                      lastUpdatedSrcSystem
                      lastUpdatedSrcSystemType
                  }
              }
          }
          }
          phone
          patientId
          surgeries {
            surgeonId
            surgeryId
            updatedAt
            surgeryData {
              consentForm {
                status
              }
              preVerificationForm  {
                createdAt
                createdBy
              }
              applicationMeta {
                documents {
                  documentId
                }
              }
              surgeonId
              surgeryDate
              surgeryName
              surgerySite
              updatedAt
              procedure
              complexity
              clearance
              eye
              setFor
              lensx
              ora
              surgeryComment
              generalAnesthesia
              isDeleted
              status
              internalNotes
              postopMeds
              otherMeds
              firstOrSecondEye
              practiceOrg
              ascId
              clinicId
              patientId
              supplies {
                istent
                hydrus
                trypanBlue
                malyuginRings
                omni
                kdb
                gatt
                ctr
                irisHooks
                other
              }
              waitlistInterest
              otherSupplies
              includeSecondPrimary
              inORExperience {
                ${InORExperienceKeys.EnteredORDttm}
              }
              iols {
                diopter
                iolManufacturer
                lensPriority
                lensModel
                lensType
                implantationAxis
              }
              operationRoom {
                roomId
              }
            }
            iolSurgeonPreferences {
              brand
              diopter
              lensPreference
              lensType
              implantationAxis
              manufacturer
              model
            }
            surgeon {
              firstName
              lastName
              email
              id
              prefix
            }
            asc {
              name
              practiceOrg
            }
            clinic {
              name
              practiceOrg
            }
          }
        }
      }
    }
    `
    const obj = {
      query: query,
      operationName: 'MyQuery'
    }
    const objStringFy = JSON.stringify(
      obj
      );
  return this.graphqlService.callgraphApi(objStringFy);
  }
 
  // Existing patient new surgery and Existing Patient list component
  searchPatientGraphQuery (config: any) {
    let argString = "";
    argString += `where: {_and:  
       [ ${this.getWherePatientQuery(config)} ]}
        sort: {field: "patient.firstName", direction: ASC}`
    
    const query = `query MyQuery {
      patientResponse(${argString}) {
        totalCount
        patients {
          birthDate
          createdFacility {
            name
            practiceOrg
          }
          associatedMrnMappings {
            associatedMrn
            associatedPracticeId
            createdAt
            createdBy
            parentPracticeId
            patientId
            updatedAt
            updatedBy
          }
          createdAt
          createdBy
          email
          firstName
          gender
          lastName
          mrn
          patientId
          practiceId
          phone
        }
      }
    }
    `
    const obj = {
      query: query,
      operationName: 'MyQuery'
    }
    const objStringFy = JSON.stringify(
      obj
      );
  return this.graphqlService.callgraphApi(objStringFy);
  }

 searchPatientGraphQueryMrnScenario (config: any) {
    let whereString = "";
    whereString += `where: {_and:  
       [ ${this.getWherePatientQuery(config)} ]}
        sort: {field: "patient.firstName", direction: ASC}`
    
    let whereAssociatedString = "";
    whereAssociatedString += `where: {_and:  
           [ ${this.getAssociatedWhereQuery(config)} ]}
           ${this.getAssociatedMrnQuery(config)}
            sort: {field: "patient.firstName", direction: ASC}`

    const query = `query MyQuery {
      primaryMrnSearch:  patientResponse(${whereString}) {
        totalCount
        patients {
          birthDate
          createdFacility {
            name
            practiceOrg
          }
          associatedMrnMappings {
            associatedMrn
            associatedPracticeId
            createdAt
            createdBy
            parentPracticeId
            patientId
            updatedAt
            updatedBy
          }
          createdAt
          createdBy
          email
          firstName
          gender
          lastName
          mrn
          patientId
          practiceId
          phone
        }
      }
    associatedMrnSearch:  patientResponse(${whereAssociatedString}) {
        totalCount
        patients {
          birthDate
          createdFacility {
            name
            practiceOrg
          }
          associatedMrnMappings {
            associatedMrn
            associatedPracticeId
            createdAt
            createdBy
            parentPracticeId
            patientId
            updatedAt
            updatedBy
          }
          createdAt
          createdBy
          email
          firstName
          gender
          lastName
          mrn
          patientId
          practiceId
          phone
        }
      }
    }
    `
    const obj = {
      query: query,
      operationName: 'MyQuery'
    }
    const objStringFy = JSON.stringify(
      obj
      );
  return this.graphqlService.callgraphApi(objStringFy);
 }

  // Patient component
  searchPatientMrnGraphQuery(config:any) {
    let argString = "";
    if((config.patientMRN !== undefined && config.patientMRN !== null) || (config.practiceOrg !== undefined && config.practiceOrg !== null)){
      argString += `where: {_and:  
        [
        {_text: {field: "patient.mrn", op: IEQ, value: "${config.patientMRN.trim()}"}},
        {_text: {field: "patient.practiceId", op: EQ, value: "${config.practiceOrg}"}}
    ]}`
    }
    const query = `query MyQuery {
      patientResponse(${argString}) {
        totalCount
        patients {
          birthDate
      createdAt
      createdBy
      email
      firstName
      gender
      lastName
      mrn
      patientId
      practiceId
      phone
        }
      }
    }`
    const obj = {
      query: query,
      operationName: 'MyQuery'
    }
    const objStringFy = JSON.stringify(
      obj
      );
  return this.graphqlService.callgraphApi(objStringFy);
  }

  getAssociatedWhereQuery(config: any) {
    let patientWhereString = '';
    if(config.practiceOrg) {
      patientWhereString += `{_text: {field: "patient.practiceId", op: IN, value: "${config.allFacilities}"}},`
    }
    return patientWhereString;

  }
  getWherePatientQuery(config: any) {
    let patientWhereString = '';
    if(config.patientId) {
      patientWhereString += `{_text: {field: "patient.patientId", op: LIKE, value: "${config.patientId.trim()}"}},`
    }
    if(config.firstName) {
      patientWhereString += `{_text: {field: "patient.firstName", op: ILIKE, value: "${config.firstName.trim()}"}},`
    }
    if(config.lastName) {
      patientWhereString += `{_text: {field: "patient.lastName", op: ILIKE, value: "${config.lastName.trim()}"}},`
    }
    if(config.dateOfBirth) {
      patientWhereString += `{_text: {field: "patient.birthDate", op: LIKE, value: "${config.dateOfBirth}"}},`
           
    }
    if(config.practiceOrg) {
      patientWhereString += `{_text: {field: "patient.practiceId", op: IN, value: "${config.practiceOrg}"}},`
    }

    if(config.patientMRN) {
       patientWhereString += `{_text: {field: "patient.mrn", op: ILIKE, value: "${config.patientMRN.trim()}"}},`
    }

    if(config.gender) {
      patientWhereString += `{_text: {field: "patient.gender", op: LIKE, value: "${config.gender}"}},`
    }

    return patientWhereString;

  }
 
  getAssociatedMrnQuery(config: any) {
    let queryString = '';
    if(config.patientMRN.trim()) {
      queryString = `associatedMrnMappingsWhere:  {_and:  [ {
        _text: { 
          field: "associatedMrnMappings.associatedMrn", 
          op: ILIKE, 
          value: "${config.patientMRN.trim()}"}
      },{_text: {field: "associatedMrnMappings.associatedPracticeId", op: IN, value: "${config.practiceOrg}"}}
     ]
    }`  
    }
    return queryString;
  }

  getSortString(config: patientFilterInput){
    switch (config?.sort?.sortField){
      case 'name':
        return  `sort: {direction: ${config.sort?.sortBy}, field: "patient.lastName"}`;
      case 'gender':
        return  `sort: {direction: ${config.sort?.sortBy}, field: "patient.gender, patient.lastName"}`;
      case 'patientId':
        return  `sort: {direction: ${config.sort?.sortBy}, field: "patient.mrn,patient.lastName"}`;
      case 'dob':
         return  `sort: {direction: ${config.sort?.sortBy}, field: "patient.birthDate, patient.lastName"}`; 
      case 'source':
        return  `sort: {direction: ${config.sort?.sortBy}, field: "patient.additionalInfo.applicationMeta.srcSystemInformation.createdSrcSystem, patient.lastName"}`;
      case 'dateAdded':
         return  `sort: {direction: ${config.sort?.sortBy}, field: "patient.createdAt, patient.lastName"}`; 
      default:
        return `sort: {direction: ASC, field: "patient.lastName"}`;
    }
}

  getAllConfigString(config: patientFilterInput){
      const sortString = this.getSortString(config);
      const pageString = this.getLimitOffsetString(config);
      return sortString + (sortString.length ? ', ' + pageString : pageString)
  }

  getLimitOffsetString(config: patientFilterInput){
    let argString = `limit: 10                          
                     offset: 0`;
    
    if(config?.pagination?.limit !== undefined && config.pagination.limit){
      argString  = "limit: " + config.pagination.limit + " "
    }
 
    if(config?.pagination?.offset !== undefined && config.pagination.offset){
      argString += "offset: " + config.pagination.offset + " "
    }
    return argString;
  }

  getPatientWhereString(config: patientFilterInput){
    let whereString = "";

    const today = this.getTodaysDate();
    if(config.startDate){
        whereString += `{_date: {field: "patient.createdAt", op: GTE, value: "${this.getStartTimeOfDate(config.startDate)}"}},`
      }
      if(config.endDate){
        whereString += `{_date: {field: "patient.createdAt", op: LTE, value: "${this.getEndTimeOfDate(config.endDate)}"}},`
      }
      if(!config.startDate && !config.endDate){
        whereString += `{_and: [
            {_date: {field: "patient.createdAt", op: GTE, value: "${this.getLastSevenDayDate()}"}},
            {_date: {field: "patient.createdAt", op: LTE, value: "${today}"}}
        ]},`
    }

    if(config.searchKey.patient){
        whereString += `{_or: [
          {_text: {field: "patient.firstName", op: ILIKE, value: "${config.searchKey.patient.trim()}"}},
          {_text: {field: "patient.lastName", op: ILIKE, value: "${config.searchKey.patient.trim()}"}}]}`;
      }
      if(config.searchKey.patientId){
        whereString += `{_or: [
          {_text: {field: "patient.mrn", op: ILIKE, value: "${config.searchKey.patientId}"}}]}`;
      }
    
    return whereString;
    
  }
  getPatientList(config: patientFilterInput){
   let query = `query PatientList {
          patient: patientsWithoutSurgeryResponse(
            ${this.getAllConfigString(config)}
            where: {_and:  
              [ 
               {_text: {field: "patient.practiceId", op: IN, value: "${this.authService.practiseOrg}"}},
               ${this.getPatientWhereString(config)}
             ]}
          ) {
            totalCount
            patients {
              birthDate
              createdFacility {
                name
                practiceOrg
              }
              createdAt
              createdBy
              email
              firstName
              gender
              lastName
              mrn
              patientId
              practiceId
              phone
              additionalInfo {
                applicationMeta {
                  srcSystemInformation {
                    createdSrcSystem
                  }
                }
              }
          }
          
        }
      }
      
        `;   

      const obj = {
        query: query,
        operationName: 'PatientList'
      }
      const objStringFy = JSON.stringify(
        obj
        );
      return this.graphqlService.callgraphApi(objStringFy);
    }

  getPatientCountWithoutSurgeries() {
    const today = this.getTodaysDate();
    let query = `query PatientWithoutSurgeriesCount {
           patient: patientsWithoutSurgeryResponse(
             where: {_and:  
               [ 
                {_text: {field: "patient.practiceId", op: IN, value: "${this.authService.practiseOrg}"
                }},
                {_and: [
                  {_date: {field: "patient.createdAt", op: GTE, value: "${this.getLastSevenDayDate()}"}},
                  {_date: {field: "patient.createdAt", op: LTE, value: "${today}"}}
                ]},
              ]}
           ) {
            totalCount
          }
       }`;
    const obj = {
      query: query,
      operationName: 'PatientWithoutSurgeriesCount'
    }
    const objStringFy = JSON.stringify(
      obj
    );
    return this.graphqlService.callgraphApi(objStringFy);
  }


  getTodaysDate(){
    return this.getEndTimeOfDate(new Date());
  }

  getLastSevenDayDate() {
    const today = new Date();
    const lastSevenDay = new Date(today);
    lastSevenDay.setDate(today.getDate() - 7);
    return this.getStartTimeOfDate(lastSevenDay);
  }

  getStartTimeOfDate(dateString: Date | string): string {
    const date = moment(dateString).startOf('day');
    return date.format('YYYY-MM-DD HH:mm:ss.SSS');
  }
  getEndTimeOfDate(dateString: Date | string): string {
    const date = moment(dateString).endOf('day');
    return date.format('YYYY-MM-DD HH:mm:ss.SSS');
  }
}
