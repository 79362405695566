import { Injectable } from '@angular/core';
import { lensPriority, lensPriorityDisplay } from '../model/iol.model';
import { patientGenderList } from '../model/patient.model';
import { AuthService } from '../auth.service';
import { SURGERY_TYPE_SHORT_LABEL } from 'src/app/model/surgery';
import { DatePipe } from '@angular/common';
import { simpleCase } from '../model/surgery.model';
import { camelCaseExceptionList, formattedDateStringSlice, sapSerialNumberLength, saturday, sunday } from '../constants/constants';
import { suppliesDataList } from '../constants/auth.constant';
import { formatDate } from '@angular/common';
import { PatientIdSearchType } from 'src/app/model/generic.model';
import moment from 'moment';
import { AppConfigurationService } from './app-configuration.service';
import { ConfigurationFeatures } from '../model/app-configuration.model';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  fieldJsonData = require('../../components/modal-dialogs/alcon-history-log/alcon-history-log-field-mapping.json');
  surgeryTypeShortLabel = SURGERY_TYPE_SHORT_LABEL;

  public simpleCase = simpleCase;
  private surgerySuppliesCollection: {[key: string]: string} = {};

  constructor( private authService: AuthService,
    private configService: AppConfigurationService,
    private datePipe: DatePipe) { }

  public getDateWithZeroHours(date: Date | string){
    let _date;
    const dateStringRegex = /[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}/g;
    if(typeof(date) == "string" && dateStringRegex.test(date)){
      _date = new Date(date+ " 00:00:00"); //make hours zero and avoid going to previous date
    }else{
      const parsedDate = moment(date, this.configService.featureFlags[ConfigurationFeatures.dateFormat]?.toUpperCase());
     _date =  parsedDate.toDate();
    }

    _date.setHours(0,0,0,0);
    return _date;
  }

  public getFileSizeInMb(file: File){
    const fileSizeinMb = file.size / (1024 * 1000);
    return Math.round(fileSizeinMb * 100) / 100;
  }

  public getLensPrefDisplayText(preferencedData:string): string{
    return lensPriorityDisplay[preferencedData] || '';
  }

  calculateAge(dateOfBirth: string | Date): number {
    const dob = new Date(dateOfBirth);
    const today = new Date();
    let age = today.getFullYear() - dob.getFullYear();
    const monthDifference = today.getMonth() - dob.getMonth();
  
    // Check if the birthday has not occurred yet this year
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < dob.getDate())) {
      age--;
    }
  
    return age;
  }
  
  getGender(gender: string){
    const selctedGender = patientGenderList.find(obj => obj.value === gender);
    return selctedGender ? selctedGender.label : gender;
  }

  getYesNo(value : boolean | string){
    return value ? 'Yes' : value === false ? 'No' : ''
  }

  formatLensDiopter(power: string, implantationAxis = '') {
    if (!power || isNaN(parseFloat(power))) return ''
    const num = parseFloat(power);
    const sign = num < 0 ? "-" : "+";
    const absValue = Math.abs(num);
    const [integerPart, decimalPart] = absValue.toString().split(".");
    const paddedInteger = integerPart.padStart(2, '0'); 
    let axisValue = '';
    if(implantationAxis)  {
      axisValue = ` @${implantationAxis}°`;
    }
    return `${sign}${paddedInteger}.${decimalPart || '0'}${axisValue}`;   // Eg. +01.5
  }

  showFormattedData(data:any,field:string){
    if (data === null) return '---';
    if(field ==='eye') return this.showValueForField(data)
    if(field ==='signLanguage') return this.showValueForField(data)
    if(field ==='surgerySite') return this.showValueForField(data)
    if(field ==='surgeryName') return this.showValueForField(data)
    if (field ==='birthDate') return this.formatDOB(data)
    if(field ==='postopMeds') return this.formatPostOpMedsData(data)
    if(field ==='otherSupplies') return ((!data || data?.length === 0)? ' ---':data);
    if(field ==='supplies') return this.formatSupplies(data)
    if (field === 'prefix') return data.replace('[', '').replace(']', '');
    if (field === 'gender') return this.getGender(data);
    if (field === 'iols') return this.createIolsData(data)
    if(field === 'surgeryDate') return this.getSurgeryDateFormat(data);
    if (field === 'waitlistInterest') return this.formatBooleanDataString(data)
    if(field === 'procedure') return this.getSurgeryTypeValue(data);
    if(field === 'complexity') return this.getComplexity(data);
    if (typeof data === 'boolean') return this.formatBooleanData(data, field);
    if (typeof data === 'object' && Array.isArray(data)) return this.formatArrayData(data);
    if (typeof data === 'string') return ((data !== '') ? data:'---');
    return  JSON.stringify(data) ?? '---';
  }

  getComplexity(data: string) {
    if(data) {
      data.toLowerCase() === simpleCase['Simple'] ? data = 'Standard' : data = 'Complex';
      return data;
    } 
    return '---';  
  }

  getSurgeryTypeValue(surgeryType: string): string {
    return this.mapSurgeryTypeShortLabel(<keyof typeof SURGERY_TYPE_SHORT_LABEL>surgeryType);
  }
  
  mapSurgeryTypeShortLabel(surgeryType: keyof typeof this.surgeryTypeShortLabel): string {
    return this.surgeryTypeShortLabel[surgeryType] || surgeryType;

  }
  getSurgeryDateFormat(value: string | Date, format: string = this.configService.featureFlags[ConfigurationFeatures.dateFormat]): string {
    if (!value) return '';
    const utcDate = new Date(value);  
    return this.datePipe.transform(utcDate, format) || ''
  }
  
  
  formatPostOpMedsData(data:string){
    if (data === '') return  '---'
    return this.showValueForField(data)
   }
  showValueForField( field: string, isFacilityOwner: boolean = true){
    if (!field) return '';
    if(field === 'surgeryName') return 'Surgery Name';
    if(field === 'surgerySite') return 'Surgery Site';
    if (field === 'mrn') return this.formatPatientId(isFacilityOwner);
    if(field === 'signLanguage') return 'Sign Language Interpreter';
    if (this.fieldJsonData[field]) return this.fieldJsonData[field]
    return field
  }

  createIolsData(oldDic:any){
    let Diopter = ''
    let LensModel = ''
    let lensType = ''
    let iolManufacturer = ''
    // let isPrimary = ''
    if (oldDic[0] && oldDic[0]["diopter"] as string ) {
      Diopter = oldDic[0]["diopter"] 
    }
    if (oldDic[0] && oldDic[0]["lensModel"] as string){
      LensModel = oldDic[0]["lensModel"]
    }
    if (oldDic[0] && oldDic[0]["lensType"]){
      lensType = oldDic[0]["lensType"]
    }
    if (oldDic[0] && oldDic[0]["iolManufacturer"] ){
      iolManufacturer = oldDic[0]["iolManufacturer"]
    }
    // if(oldDic[0] && oldDic[0]["isPrimary"]){
    //   isPrimary = oldDic[0]["isPrimary"]
    // }
     
    return `[{Primary IOL Diopter:${Diopter},Primary IOL Model:${LensModel},Primary IOL Model Type:${lensType},Primary IOL Manufacturer:${iolManufacturer}}] `
  }

  formatPatientId(isFacilityOwner: boolean = true){
    if(isFacilityOwner)
      return (this.authService.isClinicStaff)? 'Clinic Patient ID':'ASC Patient ID'
    else
      return this.getAssociatedLabelString();
  }
  getAssociatedLabelString(){
    return (this.authService.isClinicStaff)? 'ASC Patient ID':'Clinic Patient ID'
  }
  formatArrayData(arrayData:any){
    if (arrayData.length === 0) return '---'
    if (arrayData[0] === '') return '---'
    return arrayData[0]
   }

   formatDOB(dateString: string): string {
    const formattedDate = this.datePipe.transform(dateString, this.configService.featureFlags[ConfigurationFeatures.dateFormat]);
    return formattedDate || '';
  }
   
  formatSupplies(data:any){
    let str = "";
    for (const key in data) {
    if (data[key]) {
         str += str ? `,${this.showValueForField(key)}` : this.showValueForField(key);
     } 
    }
    if (str === "") {
      str = "---";
    }
    return str
    }
   formatBooleanData(data:any, fieldName?: string){
    let isYesOrNo = false;
    if(fieldName === 'ora' || fieldName === 'lensx'){
      isYesOrNo = true;
    }
     if (data) return isYesOrNo ? 'Yes' : "True";
     return isYesOrNo ? 'No' : "False"
    }
  
    formatBooleanDataString(data:any){
      if (data === 'true') return "True";
      return "False"
     }
   
     showLocalDate(value: string | Date, format: string = ''): string { 
      if(!format) {
        //'MMM d, y \'at\' h:mm'
        format = `${this.configService.featureFlags[ConfigurationFeatures.dateFormatShortMonth]} \'at\' ${this.configService.featureFlags[ConfigurationFeatures.timeFormatNoSpace]}`;
      }
      if (!value) return '';
      // Parse the input string as a Date object
      const utcDate = new Date(value + 'Z');  // Ensure the date is treated as UTC by appending 'Z'
      // Use DatePipe to format the date
      let formated = this.datePipe.transform(utcDate, format) || ''
      // if (format === 'MM/dd/yyyy'){
      //   return formated
      // }
      // //To convert the AM/PM to lowercse
      // if(formated) {
      //   formated+=(this.datePipe.transform(utcDate, 'a')?.toLowerCase())
      // }
      return formated;
    }

    formatSapId(sapId: string | null){
      if (!sapId) {
        return '';
      }
      if (sapId.length < sapSerialNumberLength) {
        return `${sapId?.padStart(
          sapSerialNumberLength,
          '0'
        )}`;
      } else {
        return sapId;
      }
    }

    isPdfDocument(name: string){
      return name.endsWith(".pdf");
    }
    
    getCurrentUTCTime(): string {
      const now = new Date();
      const formattedDate = this.datePipe.transform(now, 'fullDate', 'UTC', 'en-US');
      const formattedTime = this.datePipe.transform(now, 'h:mm:ss a', 'UTC', 'en-US');
      return `${formattedDate}, at ${formattedTime} UTC`;
    }

    isJsonEmpty(json: {[key:string]: any}){
      let isEmpty = true;
      Object.keys(json || {}).forEach((key)=>{
        json[key] && (isEmpty = false);
      })
      return isEmpty;
    }
    getSuppliesDisplayText(key: string){
      if(Object.keys(this.surgerySuppliesCollection).length){
        return this.surgerySuppliesCollection[key];
      }else{
        suppliesDataList.forEach((item)=>(this.surgerySuppliesCollection[item.key] = item.name));
        return this.surgerySuppliesCollection[key];
      }
    }

    public downloadPdfBlobAsFile(docBlob: Blob, fileName: string){
      const objectUrl = window.URL.createObjectURL(docBlob);
      this.downloadDocument(objectUrl, fileName + '.pdf');
    }

    public downloadDocument(url:string, fileName?: string){
      const link = document.createElement("a");
      link.href = url;
      fileName && (link.download = fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }

    public capitalizeFirstLetter(sentence: string){
      return sentence.split(" ").map(word => {
        if (camelCaseExceptionList.includes(word)) return word
        return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase()}).join(" ")
    }

    getTodaysDate() {
      let today= new Date();
      today.setHours(0,0,0,0);
      return this.datePipe.transform(today, this.configService.featureFlags[ConfigurationFeatures.dateFormat]) ?? formatDate(today, 'MM/dd/yyyy', 'en-US');
    }

    getLastSevenDayDate() {
      const today = new Date();
      const lastSevenDay = new Date(today);
      lastSevenDay.setDate(today.getDate() - 7);
      return this.datePipe.transform(lastSevenDay, this.configService.featureFlags[ConfigurationFeatures.dateFormat]) ?? formatDate(lastSevenDay, 'MM/dd/yyyy', 'en-US');
    }

    getNextYearDate() {
      let today= new Date();
      today.setHours(0,0,0,0);
      const nextYearDate = today.setFullYear(today.getFullYear() + 1);
      return this.datePipe.transform(nextYearDate, this.configService.featureFlags[ConfigurationFeatures.dateFormat]) ?? formatDate(nextYearDate, 'MM/dd/yyyy', 'en-US');
    }

    toTestDateEqual(date1: Date, date2: Date){
      date1 = new Date(date1);
      date2 = new Date(date2);
      if(date1 && date2){
        const d1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
        const d2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
        return d1.getTime() === d2.getTime();
      }else{
        return false;
      }
    }

    reloadPage(){
      window.location.reload();
    }

    getValueFromNestedObject(obj: any, key: string) {
      return key.split('.').reduce((o, k) => (o && o[k] !== undefined) ? o[k] : null, obj);
    }
   
    getFormattedDateString(dateString?: string | undefined) {
      let date = new Date();
      if (dateString) {
        date = new Date(dateString);
      }
      const monthFormat = `0${date.getMonth() + 1}`;
      const dayFormat = `0${date.getDate()}`;
      return `${date.getFullYear()}-${monthFormat.slice(
        formattedDateStringSlice
      )}-${dayFormat.slice(formattedDateStringSlice)}`;
    } 
    excludeWeekends(d: Date | null): boolean {
      const day = (d || new Date()).getDay();
      // Prevent Saturday and Sunday from being selected.
      return day !== sunday && day !== saturday;
    }

    formatDateWithLowercaseMeridian(timeString: string): string {
      const localDate = this.showLocalDate(timeString);
      return localDate.replace(/AM/g, 'am').replace(/PM/g, 'pm');
    }
    
  }

