<ng-container *ngIf="(authStateService.authState$ | async)?.isAuthenticated else clinicConnectHeader">
    <adi-header-lib
      (loader)="logoutLoader($event)"
      [host]="headerEndPoint"
      [source]="headerSource"
 ></adi-header-lib>
    <div class="alc_header-secondary">
        <app-sub-header *ngIf="isAccountLoaded"></app-sub-header>
        <div class="alcon-account-selection-container" [ngClass]="{'viewOnlyAccountSelection': showViewOnlySection}">
            <app-view-only-access-indicator *ngIf="showViewOnlySection"></app-view-only-access-indicator>
            <alcon-account-selector-lib
                [host]="headerEndPoint"
                [source]="headerSource"
            >
            </alcon-account-selector-lib>
    </div>
    </div>
</ng-container>
<ng-template #clinicConnectHeader>
<div class="alc_header">
    <div class="alc_header-primary">
        <div class="alc_header-primary-navigation">
            <div class="alc_header-primary-brand">
                <img src="https://alcon.widen.net/content/pmxiwthkgx/webp/Adi_headerlogo.webp?position=c&quality=80&u=uxrrnh&use=ibhcw" alt="Adi Home" class="alc_header-primary-brand-logo adh-logo">          
            </div>
        </div>
    </div>
</div>
</ng-template>
    
