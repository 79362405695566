import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Surgery, EYE_LABEL, SURGERY_TYPE_SHORT_LABEL, RECORD_PER_PAGE, SurgeryUpdatePayload, BulkUpdateSurgeryResponse, IncompleteSurgeriesUserSelection, IOLSelectionStatus } from '../model/surgery';
import { BehaviorSubject, Observable, Subject, of, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { stepperItemConfig } from './components/alcon-surgery-stepper/alcon-surgery-stepper.component';
import { UtilityService } from './service/utility.service';
import { SharedService } from './service/shared.service';
import { basicContractDetails, patientSurgery, patientDetails } from '../pages/patient/patient-details/patient-details.model';
import { filterInput, prefillDetails, resetFilter, surgeryDocumentType, surgeryFilter, surgeryStatus, surgeryType, surgeryTypeRes, surgeryStatusFilter } from './model/surgery.model';
import { Router } from '@angular/router';
import { suppliesDataList } from './constants/auth.constant';
import { HistoryLogResponse } from './alcon-history-log/Model/alcon-history-log-model';
import { lensDbResponse, lensPriority, lensPriorityDisplayOrder } from './model/iol.model';
import { HistoryLogService } from './alcon-history-log/Service/alcon-history-log-service';
import { PatientReadinessService } from './patient-readiness.service';
import { dashboardViewType } from './constants/dashboard-configuration.constants';
@Injectable({
  providedIn: 'root',
})
export class SurgeryService {
  private mccApiUrl = environment.apiUrl + '/' + environment.ccApiRoute;
  public procedureChanged = new Subject<boolean>();
  public addIOLBtnClicked = new Subject<boolean>();
  public patientModelTabClicked = new Subject<boolean>();
  public refreshPatientDetailPage = new Subject<boolean>();

  public associatedASCId = '';
  public associatedClinic = '';

  public addSurgeryFlow: boolean = false;
  public surgeryData: any = {obj: {}, surgeryID: ''};
  public patientData =  {id: '', patientID: '', practiceOrg: '', obj: {}};
  public patientResponse:any = []
  public existingPatientData: any = {};
  public expandedSurgeriesIds:string[] = [];
  public prefillDetails!: prefillDetails;


  allExpanded: boolean = false;

  // Observable boolean to notify date filter change 
  public dateFilterChanged = new Subject<boolean>();
  filterConfig:surgeryFilter = {
    filterInput: {
    startDate: '',
    endDate: '',
    sort: {
      sortBy : '',
      sortField: ''
    },
    searchKey: {
      patient : '',
      surgeon: ''
    },
    pagination: {
      limit: RECORD_PER_PAGE,
      offset: 0
    },
    surgeryStatusFilter: surgeryStatusFilter.plannedSurgeries
  },
    refreshCount: true
  };
  public resetFilter = new Subject<resetFilter>();
  private surgeryFilterSubject = new BehaviorSubject<surgeryFilter>(this.filterConfig);
  surgeryFilterChange$ = this.surgeryFilterSubject.asObservable();

  private hiddenDashboardFieldsSubject = new BehaviorSubject<string[]>([]);
  hiddenDashboardFieldsChange$ = this.hiddenDashboardFieldsSubject.asObservable();

  incompleteSurgeriesUserSelection: IncompleteSurgeriesUserSelection  = {
    list: [],
    count: 0
  }


 
    // Observable boolean to determine the display state of Patient Modal when open by existing patient flow
  private isModalContentInline = new BehaviorSubject<boolean>(false);
  
  updateFilter(filter: filterInput, refreshCount = true) {
    this.surgeryFilterSubject.next({filterInput: filter, refreshCount: refreshCount});
  }

  updateHiddenDashboardFields(hiddenFields: string[]) {
    this.hiddenDashboardFieldsSubject.next(hiddenFields);
  }

  public httpOptionsGetDocs = {};

    // Function to toggle the display state
    setInlineDisplay(inline: boolean) {
      this.isModalContentInline.next(inline);
    }

    // Getter to access the current display state
    get inlineDisplay() {
      return this.isModalContentInline.asObservable();
    }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  public clearExpandedSurgeriesArray(){
    this.expandedSurgeriesIds = []
  }
  public httpOptionsWithResponse = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response' as 'response'
  };

  generateBoundary(): string {
    const commonPrefix = "----";
    const uniqueIdentifier = Math.random().toString(36).substr(2, 10);
    return commonPrefix + uniqueIdentifier;
  }



  boundary: string = this.generateBoundary();

  httpOptions2 = {
    headers: new HttpHeaders({
      'Content-Type': `multipart/form-data; boundary=${this.boundary}`,
    }),
  };

  eyeLabel = EYE_LABEL;
  surgeryTypeShortLabel = SURGERY_TYPE_SHORT_LABEL;

  constructor(
    private httpClient: HttpClient,
    private utilityService: UtilityService,
    private sharedService: SharedService,
    public router: Router,
    private historyLogService: HistoryLogService,
    private patientReadinessService: PatientReadinessService
  ) {
  }

  removeEmptyAxis(obj: any) {
      obj?.iols?.forEach((iol: any) => {
          if (iol?.implantationAxis === '') {
              delete iol.implantationAxis;
          }
      });
      return obj;
  }


  surgery_create(obj: any, practiceOrg: string): Observable<Surgery> {
      const url = this.mccApiUrl + '/surgery'+ `?practiceOrg=${practiceOrg}`;
      return this.httpClient.post<Surgery>(url, JSON.stringify(this.removeEmptyAxis(obj)), this.httpOptionsWithResponse).pipe(
      map(resp => this.sharedService.extractData<any>(resp, url, this.httpOptionsWithResponse)))
  }

  surgery_update(id: any, obj: any, practiceOrg: string): Observable<Surgery> {
    const url = this.mccApiUrl + '/surgery/'+ id + `?practiceOrg=${practiceOrg}`;
    return this.httpClient.put<Surgery>(url, JSON.stringify(this.removeEmptyAxis(obj)),this.httpOptionsWithResponse).pipe(
    map(resp => this.sharedService.extractData<any>(resp, url, this.httpOptionsWithResponse)))
  }

  surgery_cancel(id: any, obj: any, practiceOrg: string) {
    const url = this.mccApiUrl + '/surgery/'+ id + `?practiceOrg=${practiceOrg}`;
    return this.httpClient.put<Surgery>(url, JSON.stringify(this.removeEmptyAxis(obj)),this.httpOptionsWithResponse).pipe(
    map(resp => this.sharedService.extractData<any>(resp, url, this.httpOptionsWithResponse)))
  }

  completeSurgery(praticeOrg: string, patientId: string, surgeryId: string){
    const url = this.mccApiUrl +'/' + praticeOrg + '/patient/'+ patientId +'/surgery/'+ surgeryId + `/complete`;
    return this.httpClient.patch<Surgery>(url, JSON.stringify({"status":"Completed"}),this.httpOptionsWithResponse).pipe(
    map(resp => this.sharedService.extractData<any>(resp, url, this.httpOptionsWithResponse)))
  }

  patient_create(obj: any): Observable<Surgery> {
      const url = this.mccApiUrl + '/patient';
      return this.httpClient.post<HttpResponse<any>>(url, JSON.stringify(obj), this.httpOptionsWithResponse).pipe(
        map(resp => this.sharedService.extractData<any>(resp, url, this.httpOptionsWithResponse))
      )
  }

  patient_update(id: any, obj: any): Observable<Surgery> {
    const url = this.mccApiUrl + '/patient/' + id;
      return this.httpClient.put<HttpResponse<any>>(url, JSON.stringify(obj), this.httpOptionsWithResponse).pipe(
        map(resp => this.sharedService.extractData<any>(resp, url, this.httpOptionsWithResponse))
      )
  }

  getLens(): Observable<lensDbResponse> {
    let country_id = environment.countryIsoCode;
    return this.httpClient
      .get<lensDbResponse>(
        this.mccApiUrl + '/lensDetails?countryISOCode=' + country_id,
        this.httpOptions
      )
  }

  getPrefferedLanguages(): Observable<any[]>{
    const url = this.mccApiUrl + '/patient/supportedLanguages';
    return this.httpClient.get<HttpResponse<any>>(url, this.httpOptionsWithResponse).pipe(
      map(resp => this.sharedService.extractData<any>(resp, url, this.httpOptionsWithResponse))
    )
  }

  uploadDocument(practiceOrg: any, patientId: any, surgeryID: any, file: File, documentType?: string) {
    const queryParams = new HttpParams()
    .set('practiceOrg', practiceOrg)
    .set('patientId', patientId)
    .set('surgeryId', surgeryID)
    .set('documentType', documentType || surgeryDocumentType.surgeryDocument);

    this.httpOptionsGetDocs = {
      params: queryParams
    }
    const formData: FormData = new FormData();
    formData.append('files', file, file.name.toLowerCase());
    return this.httpClient.post<any>(
      this.mccApiUrl + '/surgeryDocuments?',
      formData,
      this.httpOptionsGetDocs

    );
  }

  getDocuments(practiceOrg: any, patientId: any, surgeryID: any, documentType?: string) {
    let queryParams = new HttpParams()
    .set('practiceOrg', practiceOrg)
    .set('patientId', patientId)
    .set('surgeryId', surgeryID)
    if(documentType){
      queryParams = queryParams.set('documentType', documentType);
    }

    this.httpOptionsGetDocs = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      params: queryParams
    }
    return this.httpClient.get<any>(
      this.mccApiUrl + '/surgeryDocuments?' ,
      this.httpOptionsGetDocs
    );
  }

  deleteDocument(practiceOrg: any, patientId: any, surgeryID: any, documentID: any) {
    const queryParams = new HttpParams()
    .set('practiceOrg', practiceOrg)
    .set('patientId', patientId)
    .set('surgeryId', surgeryID)
    .set('documentId', documentID)

    this.httpOptionsGetDocs = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      params: queryParams
    }
    return this.httpClient.delete<any>(
      this.mccApiUrl + '/surgeryDocuments?',
      this.httpOptionsGetDocs
    );
  }

  getSurgeryTypes(): Observable<surgeryTypeRes[]> {
    return this.httpClient
      .get<surgeryTypeRes[]>(this.mccApiUrl + '/surgery-types', this.httpOptions)
  }

  public getSurgeryStepperConfig(surgery: patientSurgery): stepperItemConfig[]{
    const step1: stepperItemConfig = {
      title: "PLANNING FOR SURGERY",
      importantInfomation: [],
      isShowSurgeryCompleteBtn: false
    }
    const step2: stepperItemConfig = {
      title: "DAY OF SURGERY",
      importantInfomation: [],
      isShowSurgeryCompleteBtn: false
    }
    const step3: stepperItemConfig = {
      title: "SURGERY COMPLETED",
      isShowSurgeryCompleteBtn: false
    }
    const surgeryDate = surgery.surgeryData.surgeryDate && this.utilityService.getDateWithZeroHours(surgery.surgeryData.surgeryDate);
    const today = this.utilityService.getDateWithZeroHours(new Date());
    const missingFields = this.getMissingSurgeryInfo(surgery);
    if(!surgeryDate || surgeryDate > today && surgery.surgeryData.status !== surgeryStatus.COMPLETED){
      step1.isActive = true;
      step1.importantInfomation = missingFields;
    }else if(surgeryDate.getTime() === today.getTime() && surgery.surgeryData.status !== surgeryStatus.COMPLETED){
      step1.isDone = true;
      step2.isActive = true;
      step2.importantInfomation = missingFields;
      if(surgery.surgeryData.status === surgeryStatus.READY){
        step2.isShowSurgeryCompleteBtn = true;
      }
    }else{
    if (surgery.surgeryData.status === surgeryStatus.COMPLETED){
      step3.title = "SURGERY COMPLETED"
    }
   else  {
     //Overdue
     step3.title = "OVERDUE"
   }
      step1.isDone = true;
      step2.isDone = true;
      step3.isDone = true;
    }

    return [step1, step2, step3];
  }

  private getMissingSurgeryInfo(surgery:patientSurgery){
    const mandatoryFields = [
      {"label":"Surgery Date", "data": surgery.surgeryData.surgeryDate},
      {"label":"Procedure", "data": surgery.surgeryData.procedure},
      {"label":"Case Complexity ", "data": surgery.surgeryData.complexity},
      {"label":"Pre-surgery verification form", "data": (surgery.surgeryData?.preVerificationForm?.createdAt && surgery.surgeryData?.preVerificationForm?.createdBy) ?? false},
    ];

    if(surgery.surgeryData.procedure === surgeryType.catract || surgery.surgeryData.procedure === surgeryType.exchange){
      mandatoryFields.push({"label":"Eye", "data": surgery.surgeryData.eye});
      (surgery.surgeryData.procedure === surgeryType.catract) && (mandatoryFields.push({"label":"1st or 2nd", "data": surgery.surgeryData.firstOrSecondEye}));
      mandatoryFields.push({"label":"Set For", "data": surgery.surgeryData.setFor});
      mandatoryFields.push({"label":"Post Op Medications", "data": surgery.surgeryData.postopMeds});
      mandatoryFields.push({"label":"Primary IOL", "data": !this.patientReadinessService.checkIsIOLMissing(surgery)});
    }

    const missingFields: string[] = [];
    mandatoryFields.forEach(item => {
      !item.data && (missingFields.push(item.label))
    })
    return missingFields;
  }

 
  clearSurgeryDateFilter(event: any, form: any) {
    event.stopPropagation();
    form.get('startDate')?.reset();
    form.get('endDate')?.reset();
  }

  getUploadHistory(practiceOrg: string, patientId: string, id: string, currentPage: number, recordsPerPage: number, sort: { active: string, direction: string}): Observable<any> {
    const allData = [
      // Add all 15 records here as before
      { fileName: 'OnePatientFileV2.csv', uploadDate: '09/12/2023', uploadedBy: 'John Ramos', status: 'Pending' , noOfPatientsCreated: 30, noOfPatientsUpdated: 5, noOfErrors: 1 , errors: "Couldn't Process row #5" },
      { fileName: 'TwoPatientFileV2.csv', uploadDate: '09/12/2022', uploadedBy: 'Tony Ramos', status: 'Pending', noOfPatientsCreated: 10, noOfPatientsUpdated: 2, noOfErrors: 0 },
      { fileName: 'ThreePatientFileV3.csv', uploadDate: '08/15/2023', uploadedBy: 'Jane Smith', status: 'Uploaded', noOfPatientsCreated: 20, noOfPatientsUpdated: 3, noOfErrors: 1, errors: "Couldn't Process row #2"  },
      { fileName: 'FourPatientFileV1.csv', uploadDate: '07/20/2023', uploadedBy: 'Mike Johnson', status: 'Uploaded', noOfPatientsCreated: 40, noOfPatientsUpdated: 10, noOfErrors: 5 },
      { fileName: 'FivePatientFileV2.csv', uploadDate: '06/18/2023', uploadedBy: 'Emily Davis', status: 'Uploaded', noOfPatientsCreated: 15, noOfPatientsUpdated: 4, noOfErrors: 1 , errors: "Couldn't Process row #5" },
      { fileName: 'SixPatientFileV1.csv', uploadDate: '05/22/2023', uploadedBy: 'David Wilson', status: 'Pending', noOfPatientsCreated: 50, noOfPatientsUpdated: 7, noOfErrors: 0 },
      { fileName: 'SevenPatientFileV3.csv', uploadDate: '04/25/2023', uploadedBy: 'Chris Brown', status: 'In Progress', noOfPatientsCreated: 60, noOfPatientsUpdated: 8, noOfErrors: 3, errors: "Couldn't Process row #2" },
      { fileName: 'EightPatientFileV2.csv', uploadDate: '03/30/2023', uploadedBy: 'Jessica Taylor', status: 'In Progress', noOfPatientsCreated: 70, noOfPatientsUpdated: 6, noOfErrors: 4 },
      { fileName: 'NinePatientFileV1.csv', uploadDate: '02/14/2023', uploadedBy: 'James Anderson', status: 'In Progress', noOfPatientsCreated: 80, noOfPatientsUpdated: 9, noOfErrors: 2 , errors: "Couldn't Process row #5" },
      { fileName: 'TenPatientFileV2.csv', uploadDate: '01/10/2023', uploadedBy: 'Sarah Martin', status: 'In Progress', noOfPatientsCreated: 90, noOfPatientsUpdated: 5, noOfErrors: 0 },
      { fileName: 'ElevenPatientFileV3.csv', uploadDate: '12/22/2022', uploadedBy: 'Brian Lee', status: 'In Progress', noOfPatientsCreated: 25, noOfPatientsUpdated: 7, noOfErrors: 1 , errors: "Couldn't Process row #2" },
      { fileName: 'TwelvePatientFileV1.csv', uploadDate: '11/19/2022', uploadedBy: 'Nancy Clark', status: 'Uploaded', noOfPatientsCreated: 35, noOfPatientsUpdated: 2, noOfErrors: 3 },
      { fileName: 'ThirteenPatientFileV2.csv', uploadDate: '10/15/2022', uploadedBy: 'Andrew Scott', status: 'Uploaded', noOfPatientsCreated: 45, noOfPatientsUpdated: 10, noOfErrors: 4, errors: "Couldn't Process row #2" },
      { fileName: 'FourteenPatientFileV1.csv', uploadDate: '09/11/2022', uploadedBy: 'Megan King', status: 'Pending', noOfPatientsCreated: 55, noOfPatientsUpdated: 3, noOfErrors: 2 },
      { fileName: 'FifteenPatientFileV2.csv', uploadDate: '08/07/2022', uploadedBy: 'Kevin Wright', status: 'Pending', noOfPatientsCreated: 65, noOfPatientsUpdated: 1, noOfErrors: 1 , errors: "Couldn't Process row #5" }
    ];
    return of(allData);
  }

  checkCatractSurgery(patientDetails: patientDetails, surgeryId?: string){
    let catractSurgery = [];
    if(surgeryId){
      catractSurgery = patientDetails?.surgeries.filter((x:any) => (x?.surgeryData?.procedure == surgeryType.catract && x?.surgeryData?.status !== surgeryStatus.CANCELLED) && x?.surgeryId !== surgeryId);
    }else{
      catractSurgery = patientDetails?.surgeries.filter((x:any) => (x?.surgeryData?.procedure == surgeryType.catract && x?.surgeryData?.status !== surgeryStatus.CANCELLED));
    }
    var catractInfo: basicContractDetails = {length: catractSurgery.length};
    if(catractSurgery.length == 1){
      if(catractSurgery[0].surgeryData.eye){
        catractInfo.selectedEye = catractSurgery[0].surgeryData.eye.trim();
      }
      if(catractSurgery[0].surgeryData.firstOrSecondEye){
        catractInfo.selectedFirstOrSecond = catractSurgery[0].surgeryData.firstOrSecondEye;
      }
    }
    return catractInfo;
  }

  checkExchangeSurgery(patientDetails: patientDetails, surgeryId?: string){
    let exchangeSurgery = [];
    if(surgeryId){
      exchangeSurgery = patientDetails?.surgeries.filter((x:any) => (x?.surgeryData?.procedure == surgeryType.exchange && x?.surgeryData?.status !== surgeryStatus.CANCELLED) && x?.surgeryId !== surgeryId);
    }else{
      exchangeSurgery = patientDetails?.surgeries.filter((x:any) => (x?.surgeryData?.procedure == surgeryType.exchange && x?.surgeryData?.status !== surgeryStatus.CANCELLED));
    }

    return exchangeSurgery;
  }
  reloadPage() {
    const currentUrl = window.location.pathname;
    const baseHref = document.querySelector('base')?.getAttribute('href') || '/';
    const relativeUrl = currentUrl.replace(baseHref, ''); // Remove baseHref from currentUrl if present

    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
      this.router.navigate([relativeUrl])
    );
  }

  getSupplies(supplies: any, other_supplies: any,procedure: string) {
    if(procedure === 'Other Surgery'){
      return 'N/A';
    }
    if(supplies) {
      const truekeys = new Set(Object.entries(supplies).filter(([key,value]) => value).map(([key]) => key));
      let trueSuppliesNames = suppliesDataList.filter((item: any) => truekeys.has(item.key) && item.key !== 'other')
      .map(item => item.name);
      if(supplies.other && other_supplies.length > 0) {
        trueSuppliesNames = [...trueSuppliesNames, ...other_supplies];
      }
      const trueSuppliesNamesString = trueSuppliesNames.join(', ');
    if(trueSuppliesNamesString)
      return trueSuppliesNamesString;
    else
      return '---';
    }
    return '---';
  }

  getRecentChanges(practiceOrg: any, patientId: any, surgeryId: any): Observable<HistoryLogResponse> {
    return this.httpClient
      .get<HistoryLogResponse>(`${this.mccApiUrl}/${practiceOrg}/patient/${patientId}/history/logs?surgeryDelta=true&surgeryId=${surgeryId}`, this.httpOptions).pipe(
        map(response => this.historyLogService.updateLogDeltaFields(response))
      );
  }

  mapEyeLabel(eye: keyof typeof this.eyeLabel): string {
    return this.eyeLabel[eye] || eye;

  }

  mapSurgeryTypeShortLabel(surgeryType: keyof typeof this.surgeryTypeShortLabel): string {
    return this.utilityService.mapSurgeryTypeShortLabel(surgeryType);

  }



  public reOrderIOLPreferences(iolPrefobj:any[]){
    const ar = [...iolPrefobj];
    ar.sort((a: any, b: any) => {
      if (lensPriorityDisplayOrder[a.lensPreference] < lensPriorityDisplayOrder[b.lensPreference]) {
        return -1;
      } else if (lensPriorityDisplayOrder[a.lensPreference] > lensPriorityDisplayOrder[b.lensPreference]) {
        return 1;
      } else {
        return 0;
      }
    });
    return ar 
  }
  
  bulkSurgeriesCompletion(accountFacilityID: string, surgeries: SurgeryUpdatePayload[], referenceId = '' ,full?: boolean) {
    let url = this.mccApiUrl + '/' + accountFacilityID + '/surgery/bulkUpdate';
    if(referenceId) {
      url += `/status/${referenceId}?verbosity=${full ? 'full' : 'lite'}`;
      return this.httpClient.get<BulkUpdateSurgeryResponse>(url, this.httpOptionsWithResponse);
    }
    return this.httpClient.patch<BulkUpdateSurgeryResponse>(url, surgeries, this.httpOptionsWithResponse);
  }

  setPrefillDetails(prefillValue : prefillDetails){
    this.prefillDetails = prefillValue;
  }

  //Getting IOL Selection status and gives corresponding text to display
  getPrimaryIOLStatusToDisplay(iols:any){
    switch (this.getIOLSelectionStatus(iols)){
      case IOLSelectionStatus.noIOL:
        return  IOLSelectionStatus.noIOL;
      case IOLSelectionStatus.noModelAndDiopter:
        return IOLSelectionStatus.noModelAndDiopter;
      case  IOLSelectionStatus.noDiopter:
        return this.getPrimaryIOLTextToDisplay(this.getPrimaryIOLItem(iols));
      case  IOLSelectionStatus.iOLSelected:
        return this.getPrimaryIOLTextToDisplay(this.getPrimaryIOLItem(iols));
      default:
        return   IOLSelectionStatus.noIOL;
    }
  }

//Function which gives Primary IOL Selection status e.g.IOL selected
  getIOLSelectionStatus(iol: any){
    if(iol && Array.isArray(iol)) {
      let primaryIol = this.getPrimaryIOLItem(iol)
     
      if(primaryIol){
        if(!primaryIol.model && (!primaryIol.diopter || isNaN(parseFloat(primaryIol.diopter)))){
          return IOLSelectionStatus.noModelAndDiopter;
        }else if(primaryIol.model && (!primaryIol.diopter || isNaN(parseFloat(primaryIol.diopter)))){
          return IOLSelectionStatus.noDiopter;
        }else {
          return IOLSelectionStatus.iOLSelected
        }

      }else{
      return IOLSelectionStatus.noIOL;
      }
    }
    else{
      return IOLSelectionStatus.noIOL;
    }
   }

  getPrimaryIOLItem(iol: any){
    let primaryIolItem;
    primaryIolItem = iol.find((iolItem: any) => {
      return iolItem?.lensPreference === lensPriority.primary;
    });
    return primaryIolItem
  }

 
//Function which gives text o/p e.g.Primary IOL: SN60WF, +06.0. Add implantation axis  here
  getPrimaryIOLTextToDisplay(primaryIolItem: any){
    if(primaryIolItem.model && (!primaryIolItem.diopter || isNaN(parseFloat(primaryIolItem.diopter)))){
      return  "Primary IOL: "+ primaryIolItem.model + ', ' + "diopter pending"
    }else {
      return "Primary IOL: "  + primaryIolItem.model + ', ' + this.utilityService.formatLensDiopter(primaryIolItem.diopter, primaryIolItem.implantationAxis)
    }
  } 

  getTotalIOLsCount(iol: any){
     return (iol?.length)  + ' '  + ((iol && iol.length > 1) ? 'IOLs total': 'IOL total')
  }

  saveUserPreferences(preferencesPayload: any) {

     const url = this.mccApiUrl +'/v2/user/user-preferences';
     return this.httpClient.post<HttpResponse<any>>(url, JSON.stringify(preferencesPayload),this.httpOptionsWithResponse).pipe(
      map(resp => this.sharedService.extractData<any>(resp, url, this.httpOptionsWithResponse))
    )
  }

  getUserPreferences(userId: any) {
    const url = this.mccApiUrl + '/v2/user/user-preferences?userId=' +userId+ '&viewType=' + dashboardViewType;
    return this.httpClient
        .get<any[]>(url, this.httpOptions);
  }


}
