<div class="success-dialog-container">
    <div class="dialog-content-header-icon">
      <mat-icon
      (click)="closeModal()"
      aria-hidden="false"
      aria-label="close icon"
      fontIcon="close">
    </mat-icon>
    </div>
    <div class="dialog-content-header-title">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3412_71595)">
            <circle cx="32" cy="32" r="26" fill="#00AE44"/>
            <path d="M17.4336 32.6915L26.5374 42.3738L46.566 21.626" stroke="white" stroke-width="3"/>
            </g>
            <defs>
            <clipPath id="clip0_3412_71595">
            <rect width="64" height="64" fill="white"/>
            </clipPath>
            </defs>
        </svg>  
        <div class="dialog-content-message">
            <h1>{{title}}</h1>
        </div>
    </div>
  
  <div class="dialog-content-footer">
    <button type="button" class="alc_button alc_button--primary" (click)="closePopup()">{{showDashboardLink ? 'Go to Surgeries Dashboard' : 'Close'}}</button>
  </div>
</div>