import { Injectable } from '@angular/core';
import { GraphqlService } from './graphql.service';

@Injectable({
  providedIn: 'root'
})
export class SurgeonGraphqlService {

  constructor(private graphqlService: GraphqlService) { }

  getSurgeonDetail(facilityId: string){
    let query = `query MyQuery{
        surgeonsByFacility(facilityPracticeOrg: "${facilityId}") {
          firstName
          lastName
          id
          email
          licenseNumber
          licenseStateCd
      }
    }`;
    const obj = {
      query: query,
      operationName: 'MyQuery'
    }
    const objStringFy = JSON.stringify(
      obj
      );
    return this.graphqlService.callgraphApi(objStringFy);
  }

  getSurgeonList(ascId: string, clinicId: string){
    let query = `query MyQuery{
      surgeonsByClinicAndASC(ascPracticeOrg: "${ascId}", clinicPracticeOrg: "${clinicId}") {
          firstName
          lastName
          id
          prefix
      }
    }`;
    const obj = {
      query: query,
      operationName: 'MyQuery'
    }
    const objStringFy = JSON.stringify(
      obj
      );
    return this.graphqlService.callgraphApi(objStringFy);
  }
}
