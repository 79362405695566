<div class="wrapper">
   
    <app-header [facilitiesSource]="facilities" [AccountFacilityID]="AccountFacilityID"></app-header>
    <ng-container *ngIf="!isRouteOutsideAdi; else unProtectedRoutes">
        <alcon-account-switch-popup *ngIf="isAuthenticated"
                    [host]="headerEndPoint"
                    [source]="headerSource"
                    (permissionDetails) = "setPermission($event)"
                >
        </alcon-account-switch-popup>
        <adi-connect-facility 
        *ngIf="isAuthenticated" 
        [host]="headerEndPoint"
        [source]="headerSource">
        </adi-connect-facility>
        <div class="content">
            <router-outlet *ngIf="isAccountLoaded"></router-outlet>
        </div>
        <adi-footer-lib
        *ngIf="isAuthenticated"
        [host]="headerEndPoint"
        [source]="headerSource"
        ></adi-footer-lib>
        <!-- <app-footer></app-footer> -->
         <alc-section-loader *ngIf="(loaderService.isLoading$ | async) || !isAccountLoaded"></alc-section-loader>
        <!-- <app-loader [isLoader]="((loaderService.isLoading$ | async) || !isAccountLoaded) ? 'Yes' : 'No'"></app-loader> -->
    </ng-container>
    <ng-template #unProtectedRoutes>
        <div class="content" (click)="$event.stopPropagation()">
            <router-outlet></router-outlet>
        </div>
    </ng-template>
</div>