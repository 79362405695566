import { Component, EventEmitter, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/auth.service';
import { FilterDateRange } from 'src/app/model/dateRange';
import { SurgeonService } from 'src/app/shared/surgeon.service';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from 'src/app/shared/service/shared.service';
import { Subscription } from 'rxjs';
import { userRoles } from 'src/app/shared/constants/auth.constant';
import { SurgeryService } from 'src/app/shared/surgery.service';
import { AlconASCSettingsModelComponent } from 'src/app/shared/components/alcon-asc-settings-model/alcon-asc-settings-model.component';
import { AlconASCSettingsService } from 'src/app/shared/service/alcon-asc-settings.service';
import { AlconToastMessageService } from 'src/app/shared/alcon-toast-message.service';

@Component({
  selector: 'page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit, OnDestroy{
  @Input() pageTitle: string | undefined;
  @Input() add_new_url: string | undefined;
  @Input() add_new_title: string | undefined;
  @Input() showSetUpArrivalTime:boolean = false;
  enablePastDates: boolean = true;
  AccountFacilityID: any=""
  facSubscription: Subscription = new Subscription;

  @Input()dateRangeFilter: FilterDateRange= {startDate:"", endDate:""};

  @Output() onAddPageChange: EventEmitter<any> = new EventEmitter();
  @Output() dateRangeSelected = new EventEmitter<FilterDateRange>();
  @Output() enableLoading = new EventEmitter<boolean>();
  @Output() surgeryDetails = new EventEmitter<any>();

  dateRangeError = false;
  ascTimeSettings:any = {};
  isAscSettingsConfigured = false;

  constructor(public router: Router,
    private authService: AuthService,
    public surgeonservice: SurgeonService,
    private dialog: MatDialog,
    public sharedService: SharedService,
    public surgeryService: SurgeryService,
    public ascSettingsService:AlconASCSettingsService,
    private toastService: AlconToastMessageService,

    ) {
    }

  ngOnInit(): void {
    
  }


  ngOnChanges(): void {
  }

  addForm()
  {
    this.onAddPageChange.emit();
  }

  setUpArrivalTime(){
    //Call api and check is asc settings configured
    this.checkASCSettings()
  }

  showASCSettingsModel(){
 //show arrival time setting pop up
  this.dialog.open(AlconASCSettingsModelComponent, {
  backdropClass: 'dialog-backdrop',
  position: {
    top:  '80px'
  },
  hasBackdrop: true,
  width: '900px',
  data: {
    ascName: this.authService.facilityName,
    ascTimeSettings:this.ascTimeSettings,
    isAscSettingsConfigured:this.isAscSettingsConfigured
   },
   })
  .afterClosed().subscribe((timeSettings: any) => {
    if (timeSettings)
     this.saveASCSettings(timeSettings)
  });
  }
  
  checkASCSettings(){
    this.enableLoading.emit(true);
    this.ascSettingsService.getAscSettings().subscribe({
      next: (res: any) =>
        {
          this.enableLoading.emit(false);
         if(res?.timeSettings){
          this.ascTimeSettings = res?.timeSettings;
           if(res?.timeSettings?.standardPatientArrivalTime  && res?.timeSettings?.standardPatientArrivalTime != ''){
            
             this.isAscSettingsConfigured = true;
           } else {

            this.isAscSettingsConfigured = false;
           };
         }
         this.showASCSettingsModel()
        },
        error: (err: any) => {
          this.enableLoading.emit(false);
          this.showToastMessage("Error in Saving", "Error");
        }
      });
  }

  saveASCSettings(timeSettings:any){
    this.enableLoading.emit(true);
    this.ascSettingsService.savePreferencesAPI(timeSettings)
    .subscribe({
        next: (res: any) => 
        {
          this.enableLoading.emit(false);
          this.showToastMessage("Changes saved", "Success");

        },
        error: (err: any) => 
        {
          this.enableLoading.emit(false);
          this.showToastMessage("An error occurred!", "Error");

        }
     });
 
  }

  showToastMessage(message: string, type: string){
    this.toastService.showToastMsg(
      message,
      "",
      '',
      type,
      type.toLocaleLowerCase(),
      15000
      );
  }

  showAddNewTitle(): boolean {
    const userRole: string = this.authService.getLoggedInUserRole();
    if (this.add_new_title) {
      if(this.add_new_title === 'Add Surgeon') {
        if(userRole === userRoles.superAdmin || userRole === userRoles.clinicAdmin) {
          return true;
        } else {
          return false;
        }
      } else if(this.add_new_title === 'Add User') {
        if(userRole === userRoles.superAdmin) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  errorAlertDialog(msg:any) {
    const dialogRef = this.dialog.open(AlertDialogComponent,{
      data:{
        message: msg,
        buttonText: {
          cancel: 'Ok'
        }
      },
    });
  }



  ngOnDestroy() {
    this.facSubscription.unsubscribe();
  }
}
