import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

export enum toggleType {
  'short' = 'short',
  'shortAndLabelBold' = 'short--bold-label'
}
@Component({
  selector: 'alcon-toggle-switch',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  templateUrl: './alcon-toggle-switch.component.html',
  styleUrl: './alcon-toggle-switch.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: AlconToggleSwitchComponent
    }
  ]
})
export class AlconToggleSwitchComponent implements ControlValueAccessor{

  @Input() label!: string;
  @Input() selected!: boolean;
  @Input() hideButtonStateLabel: boolean = false;
  @Input() scaleValue: number = 1;
  @Input() customClass: string = '';

  @Output() selectionChanged = new EventEmitter();

  public onText = "Yes";
  public offText = "No";
  //private destroy$ = new Subject<void>();

  onChange = (value: boolean) => {};
  onTouched = () => {};
  touched = false;
  disabled = false;
  
  ngOnInit(){
  }

  public toggleSwitch(){
    this.selected = !this.selected;
    this.markAsTouched();
    this.onChange(this.selected);
    this.selectionChanged.emit(this.selected);
  }

  // private listenForFormChange(){
  //   if(!this.formControl)
  //     return;

  //   this.formControl.valueChanges.pipe(
  //     takeUntil(this.destroy$)
  //   ).subscribe((value)=>{
  //     this.selected = value;
  //   })
  // }

  // ngOnDestroy() {
  //   this.destroy$.next();
  //   this.destroy$.complete();
  // }

  writeValue(value: boolean) {
    this.selected = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
