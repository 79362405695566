import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class CustomIconService {
  private iconConfig = [
    {
      "name": "edit",
      "url": "assets/images/edit_icon.svg"
    }, 
    {
      "name": "edit_nc",
      "url": "assets/images/edit-icon-nc.svg"
    }, 
    {
      "name": "edit_document",
      "url": "assets/images/sign-consent.svg"
    },
    {
      "name": "note_add",
      "url": "assets/images/upload-doc.svg"
    },
    {
      "name": "arrow_down",
      "url": "assets/images/down-arrow.svg"
    },
    {
      "name": "arrow_forward",
      "url": "assets/images/arrow-forward.svg"
    },
    {
      "name": "arrow_backward",
      "url": "assets/images/arrow-backward.svg"
    },
    {
      "name": "alert_triangle",
      "url": "assets/images/alert-triangle.svg"
    },
    {
      "name": "error_circle",
      "url": "assets/images/error-circle.svg"
    },
    {
      "name": "errorOctagon",
      "url": "assets/images/error-octagon.svg"
    },
    {
      "name": "check",
      "url": "assets/images/check.svg"
    },
    {
      "name": "check_nc",
      "url": "assets/images/check_nc.svg"
    },
    {
      "name": "round_check",
      "url": "assets/images/round_check.svg"
    },
    {
      "name": "round_check_green",
      "url": "assets/images/round-check-green.svg"
    },
    {
      "name": "round_check_nc",
      "url": "assets/images/round-check-no-color.svg"
    },
    {
      "name": "round_minus",
      "url": "assets/images/round_minus.svg"
    },
    {
      "name": "round_minus_nc",
      "url": "assets/images/round_minus_nc.svg"
    },
    {
      "name": "long_arrow_forward",
      "url": "assets/images/long-arrow-forward.svg"
    },
    {
      "name": "longArrowDown",
      "url": "assets/images/long-arrow-down.svg"
    },
    {
      "name": "swapVertical",
      "url": "assets/images/swap-vertical.svg"
    },
    {
      "name": "expandAll",
      "url": "assets/images/expand-all.svg"
    },
    {
      "name": "print",
      "url": "assets/images/print.svg"
    },
    {
      "name": "edit_surgery",
      "url": "assets/images/edit_surgery.svg"
    },
    {
      "name": "more_vert",
      "url": "assets/images/more_vert.svg"
    },
    {
      "name": "clinical_notes",
      "url": "assets/images/clinical_notes.svg"
    },
    {
      "name": "calendar_month",
      "url": "assets/images/calendar_month.svg"
    },
    {
      "name": "print_surgery",
      "url": "assets/images/print_surgery.svg"
    },
    {
      "name": "comment_yes",
      "url": "assets/images/comment-yes.svg"
    },
    {
      "name": "comment_no",
      "url": "assets/images/comment-no.svg"
    },
    {
      "name": "view_log",
      "url": "assets/images/view_log.svg"
    },
    {
      "name": "download",
      "url": "assets/images/download.svg"
    },
    {
      "name": "download-white",
      "url": "assets/images/download-white.svg"
    },
    {
      "name": "cloudUpload",
      "url": "assets/images/cloud-upload.svg"
    },
    {
      "name": "close",
      "url": "assets/images/close.svg"
    },
    {
      "name": "filter",
      "url": "assets/images/filter.svg"
    },
    {
      "name": "info",
      "url": "assets/images/info.svg"
    },
    {
      "name": "info_gray",
      "url": "assets/images/info_gray.svg"
    },
    {
      "name": "phone",
      "url": "assets/images/phone.svg"
    },
    {
      "name": "add",
      "url": "assets/images/add.svg"
    },
    {
      "name": "trash",
      "url": "assets/images/trash-icon.svg"
    },
    {
      "name": "dark_light_theme",
      "url": "assets/images/dark_light_theme.svg"
    },
    {
      "name": "logout_icon",
      "url": "assets/images/logout-icon.svg"
    },
    {
      "name": "done_tick_icon",
      "url": "assets/images/tick.svg"
    },
    {
      "name": "stepCheckMark",
      "url": "assets/images/step-checkmark.svg"
    },
    {
      "name": "stepCircle",
      "url": "assets/images/step-circle.svg"
    },
    {
      "name": "stepCircleInactive",
      "url": "assets/images/step-circle-inactive.svg"
    },
    {
      "name": "stepCircleEdit",
      "url": "assets/images/step-edit.svg"
    },
    {
      "name": "grey_status_dot",
      "url": "assets/images/grey_status_dot.svg"
    },
    {
      "name": "amber_status_dot",
      "url": "assets/images/amber_status_dot.svg"
    },
    {
      "name": "arrow_up",
      "url": "assets/images/arrow-up.svg"
    },
    {
      "name": "settings",
      "url": "assets/images/settings-icon.svg"
    },
    {
      "name": "proceed-forward",
      "url": "assets/images/proceed-forward.svg"
    },
    {
      
      "name": "minus-icon",
      "url": "assets/images/minus_button.svg"
    },
    {
      "name": "plus-icon",
      "url": "assets/images/plus-icon.svg"
    },
    {
      "name": "loader",
      "url": "assets/images/loader.svg"
    },
    {
      "name": "eye-icon",
      "url": "assets/ico-view.svg"
    },
    {
      "name": "link-open",
      "url": "assets/images/link-open.svg"
    }

  ]
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {}

  public registerIcons(){
    this.iconConfig.forEach((icon)=>{
      this.matIconRegistry.addSvgIcon(
        icon.name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(icon.url)
      );
    })
  }
}
