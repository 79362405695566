<div class="alcon-ore-timeout-container" [ngClass]="{'alcon-ore-timeout-container--dark': isDarkThemeApplied}">
    <div class="alcon-ore-timeout-header alcon-timeout-incomplete" *ngIf="oreService.timeOutPending | async;">
        <span class="alcon-timeout-incomplete">TIME-OUT PENDING</span>
    </div> 
    <div class="alcon-ore-timeout-body">
        <div class="alcon-ore-timeout-avatar">
            <!-- <alcon-or-day-avatar [isDarkTheme]="true" [displayText]="'Right/2nd eye'" [eye]="'right'"></alcon-or-day-avatar> -->
            <alcon-or-day-avatar [isDarkTheme]="isDarkThemeApplied" [displayText]="eyeLabel" [eye]="eye"></alcon-or-day-avatar>
        </div>
        <div class="alcon-ore-timeout-actions">
            <div *ngFor="let action of (oreService.timeOutData | async)" class="alcon-ore-timeout-action" 
            [ngClass]="{ 
                'alcon-ore-active-action': action.isActive,
                'alcon-ore-last-updated-action': action.isLastUpdated
                }"
            >
                <alcon-or-day-action [timeOutForm]="timeOutForm" [data]="action"></alcon-or-day-action>
            </div>
        </div>
    </div>
    <div class="alcon-ore-timeout-footer">
        <div class="alcon-ore-timeout-footer-item">
            <span class="alcon-ore-timeout-footer-item-label">Model: </span>
            <span class="alcon-ore-timeout-footer-item-value">{{ primaryIol.model ? primaryIol.model : '---' }}</span>
        </div>
        <div class="alcon-ore-timeout-footer-item">
            <span class="alcon-ore-timeout-footer-item-label">Diopter: </span>
            <span class="alcon-ore-timeout-footer-item-value">{{ primaryIol.diopter ? primaryIol.diopter : '---' }}</span>
        </div>
        <div class="alcon-ore-timeout-footer-item" [ngClass]="{'alcon-hide-element': !AXIS_MODELS.includes(lensType)}">
            <span class="alcon-ore-timeout-footer-item-label">Axis: </span>
            <span class="alcon-ore-timeout-footer-item-value">{{ primaryIol.axis ? primaryIol.axis : '---' }}</span>
        </div>
    </div>
</div>