import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, forkJoin, map } from 'rxjs';
import { IOLManufacturer, IOLs, hiddenPayload, lensModel, lensType, savePreferencesPayload, searchIOLFilter } from '../iol-model-preferences.model';
import { SessionStorageService } from 'src/app/shared/session-storage.service';
import { facility, iolModelSaved } from 'src/app/shared/constants/auth.constant';
import { LensService } from 'src/app/shared/service/lens/lens.service';
import { SurgeryService } from 'src/app/shared/surgery.service';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { SharedService } from 'src/app/shared/service/shared.service';
import { AuthService } from 'src/app/shared/auth.service';
import { PreferencesService } from 'src/app/shared/service/preferences.service';
import { preferenceTypes } from 'src/app/shared/constants/constants';

@Injectable({
  providedIn: 'root'
})
export class IolModelPreferencesService {
  iols!: IOLs;
  upDatedIOLs!: IOLs;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  public httpOptionsWithResponse = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response' as 'response'
  };
  
  private mccApiURL = environment.apiUrl + '/' + environment.ccApiRoute;

  iolsSubject = new BehaviorSubject<IOLs>(this.iols);
  iols$ = this.iolsSubject.asObservable();
  public loaderSubject = new Subject<boolean>();
  public searchSubject = new Subject<searchIOLFilter>();
  public isModelSelected = new Subject<boolean>();

  updatedIOLSubject = new BehaviorSubject<IOLs>(this.upDatedIOLs);
  updatedIOL$ = this.updatedIOLSubject.asObservable();

  constructor(private sessionStorageService: SessionStorageService,
    private surgeryService: SurgeryService,
    private lensService: LensService,
    private httpClient: HttpClient,
    private sharedService: SharedService,
    private authService: AuthService,
    private preferencesService: PreferencesService
    ) { }

  updateIols(iols: any) { 
    this.updatedIOLSubject.next(iols);
  }

  getSelectedIOLs():any {
    return this.sessionStorageService.getItem(iolModelSaved);
  }

  setSelectedIOLs(value: any) : void {
    this.sessionStorageService.setItem(iolModelSaved, value);
  }

  removeSelectedIOLs() : void {
    this.sessionStorageService.removeItem(iolModelSaved);
  }

  mergeSavedField(savedIOLs: IOLs){
    let savedValue;
    let upDatedIOLsValue: IOLs = JSON.parse(JSON.stringify(this.iols));

    if(savedIOLs){

      upDatedIOLsValue.manufacturer.forEach(manufacturer => {
        manufacturer.type.forEach(type => {
          type.lens.forEach(lens => {
            savedValue = this.getselectedIOLsValue(savedIOLs, manufacturer, type, lens);
            if(savedValue !== undefined){
                 lens ? lens.isVisible = savedValue : '';
            }
          });
        });
      });
    }
    this.updateIols(upDatedIOLsValue);

  }
  getselectedIOLsValue(savedIOLs: any, manufacturer: IOLManufacturer, type: lensType, lens: lensModel) : undefined | boolean{

    const manu = savedIOLs.manufacturer.find((manu: { name: string; }) => manu.name === manufacturer.name);  
      if (manu) {
        const selctedType = manu.type.find((ty: { name: string; }) => ty.name === type.name);
        if (selctedType) {
          const lensModel = selctedType.lens.find((len: { name: string; }) => len.name === lens.name);
          return lensModel?.isVisible;
        }
      }
      return undefined;
    }
  
 getAllLenses(){
  this.loaderSubject.next(true);
  const lensDetailApi = this.surgeryService.getLens();
  const hiddenPreferencesAPI = this.preferencesService.getPreferencesAPI();

  forkJoin([lensDetailApi, hiddenPreferencesAPI]).subscribe({
    next: (res: any) => {
        const groupedData = this.lensService.groupLensDataForPreference(res[0].data);
        const classifiedData = this.lensService.tagVisibilityPreferenceStatus(groupedData, res[1]?.hiddenIOLs);
        this.iols = {
          manufacturer: classifiedData
        };
        this.iolsSubject.next(this.iols);
        let savedIOLs = this.getSelectedIOLs();
        if(savedIOLs){
          this.isModelSelected.next(true);
          this.mergeSavedField(savedIOLs);
        }
        else{
          this.updateIols(this.iols);
        }

        this.loaderSubject.next(false);
      },
      error:()=>{
        this.loaderSubject.next(false);
      }
    });
  }

  savePreferencesAPI(hiddenItems: hiddenPayload[]){
      const url = this.mccApiURL + '/preferences';
      let payload:savePreferencesPayload = {
        practiceOrg: this.authService.practiseOrg,
        practiceType: this.authService.accountType,
        preferenceType: preferenceTypes.iol,
        hiddenIOLs: hiddenItems
      }
       return this.httpClient.post<HttpResponse<any>>(url, JSON.stringify(payload),this.httpOptionsWithResponse).pipe(
         map(resp => this.sharedService.extractData<any>(resp, url, this.httpOptionsWithResponse))
       )
      }  
}

