import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { fieldPreferencesKey, iolModelSaved, loginConfigs, patientFilters, surgeryFilters } from '../constants/auth.constant';
import { SessionStorageService } from '../session-storage.service';
import { customDialog } from 'src/app/components/modal-dialogs/dialog.model';
import { CustomDialogComponent, dialogAction } from 'src/app/components/modal-dialogs/custom-dialog/custom-dialog.component';
import { DeviceService } from '../device.service';
import { UtilityService } from './utility.service';
import { NavigationService } from './navigation.service';
import { AuthService } from '../auth.service';
import { AlconPdfViewerModelComponent } from '../components/alcon-pdf-viewer-model/alcon-pdf-viewer-model.component';
import { SURGERY_TYPE_SHORT_LABEL } from 'src/app/model/surgery';
import { ConfigurationFeatures } from '../model/app-configuration.model';
import { AppConfigurationService } from './app-configuration.service';
import * as MenuItems from 'src/assets/menus.json';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private paramChangeSubject = new BehaviorSubject<boolean>(false);
  paramChangeSubject$ = this.paramChangeSubject.asObservable();

  public facilityDDRefreshSubject = new Subject();
  menus: any = (MenuItems as any).default;
  
  constructor(
    private dialog: MatDialog,
    private sessionStorageService: SessionStorageService,
    private deviceService: DeviceService,
    private utilityService: UtilityService,
    public navigationService: NavigationService,
    public authService: AuthService,
    public router: Router,
    private configService: AppConfigurationService,

  ) { }

  updateParamChange(data: boolean) {
    this.paramChangeSubject.next(data);
  }

  refreshAlconAccountDropdown(){
    this.facilityDDRefreshSubject.next(true);
  }

  /**
   * Fetch the data from the response received from the http call based on any special service type
   * Handle the custom error codes received in the success response of http call
   */
  extractData<T>(res: any, serviceUrl: string, apiOption: any) {
    // default response is 'response.body' unless requesting full response using 'observe':'response'
    const body = apiOption.hasOwnProperty('observe') && apiOption['observe'] === 'response' ? res.body : res;

    return <T>(body);
  }

  errorAlertDialog(msg:any) {
    const dialogRef = this.dialog.open(AlertDialogComponent,{
      data:{
        message: msg,
        buttonText: {
          cancel: 'Ok'
        }
      },
    });
  }

  showRetryAlert(){
    return new Promise((resolve, reject)=>{
      let dialogData:customDialog = {
        type: 'error',
        icon: "assets/error_octagon.svg",
        title: 'It’s not you - it’s us!',
        text:  "Something went wrong on our end. Give it a few minutes, then try again.",
        buttonText: {
          primary: "Try again",
          secondary: "Cancel",
        }
      };
      const dialogRef = this.dialog.open(CustomDialogComponent, {
        position: {
          top: this.deviceService.getDeviceType() == 'mobile' ? '80px' : '120px',
        },
        panelClass: 'alc_popup-full-max-width',
        hasBackdrop: true,
        backdropClass: 'dialog-backdrop',
        data: dialogData,
      });
      dialogRef.afterClosed().subscribe((result: dialogAction) => {
        if(result == dialogAction.primary){
          resolve(true)
        }else{
          reject()
        };
      })
    })

  }

  saveRevisitedUrls(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
    const baseUrl = state.url.split('?')[0];
    this.sessionStorageService.setItem(loginConfigs.loginUrl, baseUrl);
    this.sessionStorageService.setItem(loginConfigs.loginQuery, route.queryParams)
  }

  formatDateInput(input: any){
    let dateInput = input.target.value
    dateInput = dateInput.replace(/\D/g, '');
    if (dateInput.length > 2 && dateInput.length < 4 ) {
      dateInput = dateInput.replace(/^(\d{2})(\d{0,2})/, '$1/$2');
    }
    if (dateInput.length > 5) {
      dateInput = dateInput.replace(/^(\d{2})(\d{2})(\d{0,4})/, '$1/$2/$3');
    }
    if(this.isDateFormat(dateInput)){
        return this.utilityService.getDateWithZeroHours(dateInput);
    }
    return '';
  }

  isDateFormat(inp:string): boolean{
    const regex = /^\d{2}\/\d{2}\/\d{4}$/;
    return regex.test(inp);
  }

  openAlertDialog(msg: any) {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      hasBackdrop: true,
      backdropClass: 'dialog-backdrop',
      data: {
        message: msg,
        buttonText: {
          cancel: 'Ok',
        },
      },
    });
  }

   
  callAccountSwitchEvents(){
    this.redirectToDashboardIfRequired();
    //To add the functions which needs to execute in Acoount Switch
    this.sessionStorageService.removeItem(surgeryFilters.otherFilters);
    this.sessionStorageService.removeItem(surgeryFilters.headerFilters);
    this.sessionStorageService.removeItem(iolModelSaved);
    this.sessionStorageService.removeItem(fieldPreferencesKey);
    this.sessionStorageService.removeItem(patientFilters);
  }

  private redirectToDashboardIfRequired(){
    const currentUrl = window.location.pathname;
    const baseHref = document.querySelector('base')?.getAttribute('href') || '/';
    let relativeUrl = currentUrl.replace(baseHref, ''); // Remove baseHref from currentUrl if present
    let loginUrlPath = this.sessionStorageService.getItem(loginConfigs.loginUrl);
    if(relativeUrl.toLowerCase().includes('access-denied') || relativeUrl.toLowerCase().includes('patient-details') || 
       loginUrlPath?.toLowerCase().includes('access-denied') || loginUrlPath?.toLowerCase().includes('patient-details')) {
    this.sessionStorageService.removeItem(loginConfigs.loginUrl);

      this.authService.firstLogin();
    }
  }

  public viewOrDownloadDocument(url: string, name: string){
    if(this.utilityService.isPdfDocument(name)){
      this.previewPdfDocument(url, name)
    }else{
      this.utilityService.downloadDocument(url)
    }

  }

  public previewPdfDocument(url: string, name: string){
    this.dialog.open(AlconPdfViewerModelComponent, {
      height: '96%',
      width: '900px',
      hasBackdrop: true,
      backdropClass: 'dialog-backdrop',
      panelClass: 'dialog-no-margin-top',
      disableClose: true,
      data: {
        src: url,
        name: name
      }
     })
  }
  toggleSortByField(field: string, data: any, sortOrder: string = 'ASC') {
    data.sort((a: any, b: any) => {
      const nameA = a[field].toLowerCase();
      const nameB = b[field].toLowerCase();
      if (sortOrder === 'ASC') {
        const check1 = (nameA > nameB ? 1 : 0);
        return nameA < nameB ? -1 : check1;
      } else {
        const check2 = (nameA < nameB ? 1 : 0);
        return nameA > nameB ? -1 : check2;
      }
    });
  }

  getSurgeryTypeLabel(label: string) {
    if (SURGERY_TYPE_SHORT_LABEL.hasOwnProperty(label)) {
      return SURGERY_TYPE_SHORT_LABEL[label as keyof typeof SURGERY_TYPE_SHORT_LABEL];
    } else {
      return '-';
    }
  }

  // Method to check if the current URL ends with any of the admin routes
  isAdminURL() {
    const currentUrl = this.router.url;
    const routes = (this.menus[1]['admins'] as any[]).map(admin => admin.route);
    // Check if current URL ends with any of the routes
    return routes.some(route => currentUrl.endsWith(route));
  }
  
  getPlaceHolderForDate(dateFormat?: string){
    if(!dateFormat)
      dateFormat = this.configService.featureFlags[ConfigurationFeatures.dateFormat]
    switch(dateFormat){
      case 'MM/dd/y': return 'mm/dd/yyyy';
      case 'dd/MM/y': return 'dd/mm/yyyy';
    }
    return 'mm/dd/yyyy';
  }
}
