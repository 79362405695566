import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, map } from 'rxjs';
import { alconHttpOptions } from 'src/app/shared/constants/constants';
import { PatientGraphqlService } from 'src/app/shared/service/alcon-graphql/patient-graphql.service';
import { SharedService } from 'src/app/shared/service/shared.service';
import { environment } from 'src/environments/environment';
import { patientDetails } from 'src/app/pages/patient/patient-details/patient-details.model';
import { UtilityService } from 'src/app/shared/service/utility.service';
import { srcSystemTypes } from 'src/app/model/generic.model';
import { AppConfigurationService } from 'src/app/shared/service/app-configuration.service';
import { ConfigurationFeatures } from 'src/app/shared/model/app-configuration.model';

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  private mccApiUrl = environment.apiUrl + '/' + environment.ccApiRoute;
  
  constructor(
    private patientGraphqlService: PatientGraphqlService,
    private httpClient: HttpClient,
    private sharedService: SharedService,
    private utilityService: UtilityService,
    private configService: AppConfigurationService,
  ) { }


  public getPatientDetails(patientId: string){
    return this.patientGraphqlService.getPatientDetailsGraphQuery({
      limit: 1,
      offset:0,
      patientId: patientId
    }).pipe(map((res)=>(res?.data?.patientResponse?.patients?.length>0)? res?.data?.patientResponse?.patients[0] : null));
  }

  public getPatientById(patientId: string) {
    return  this.patientGraphqlService.getPatientDetailsGraphQuery({
      patientId: patientId
    }).pipe(map((res)=>(res?.data?.patientResponse?.patients?.length>0)? res?.data?.patientResponse?.patients[0] : null));
  }

  clonePatient(patientId: string, practiceOrg: string){
    const url = this.mccApiUrl + `/${practiceOrg}/patient/${patientId}/clone`;
      return this.httpClient.post<HttpResponse<any>>(url, {}, alconHttpOptions).pipe(
        map(resp => this.sharedService.extractData<any>(resp, url, alconHttpOptions))
      )
  }

  public checkWhetherPatientIsCreatedByEHR(patient:patientDetails){
    
     if(patient?.additionalInfo?.applicationMeta?.srcSystemInformation?.createdSrcSystemType === srcSystemTypes.EHR){
     return true
     }
     return false
  }

  public getCreatedEHRName(patient:patientDetails){
    
    if(patient?.additionalInfo?.applicationMeta?.srcSystemInformation?.createdSrcSystemType === srcSystemTypes.EHR){
      return patient?.additionalInfo?.applicationMeta?.srcSystemInformation?.createdSrcSystem
      }
      return ''
  }

  public getImportedByEHRDate(patient:patientDetails){
    
    if(patient?.additionalInfo?.applicationMeta?.srcSystemInformation?.createdSrcSystemType === srcSystemTypes.EHR){
      let createdDate  = patient.additionalInfo.applicationMeta.srcSystemInformation.createdAt
      if (createdDate){
        return  this.utilityService.showLocalDate(createdDate,this.configService.featureFlags[ConfigurationFeatures.dateFormat])
      }
      }
      return ''
  }

  
 public getUpdatedByEHRName(patient:patientDetails){
  
  if(patient?.additionalInfo?.applicationMeta?.srcSystemInformation?.lastUpdateSrcInformation && patient?.additionalInfo?.applicationMeta?.srcSystemInformation?.lastUpdateSrcInformation.length >0){
    return patient.additionalInfo.applicationMeta.srcSystemInformation.lastUpdateSrcInformation.find(update => update.lastUpdatedSrcSystemType === srcSystemTypes.EHR)?.lastUpdatedSrcSystem;
  }
  return this.getCreatedEHRName(patient)
 }
 
 public getUpdatedByEHRDate(patient:patientDetails){
  if(patient?.additionalInfo?.applicationMeta?.srcSystemInformation?.lastUpdateSrcInformation && patient?.additionalInfo?.applicationMeta?.srcSystemInformation?.lastUpdateSrcInformation.length >0){
    let updatedDate  = patient.additionalInfo.applicationMeta.srcSystemInformation.lastUpdateSrcInformation.find(update => update.lastUpdatedSrcSystemType === srcSystemTypes.EHR)?.lastUpdateAt
    if (updatedDate){
      return this.utilityService.showLocalDate(updatedDate,this.configService.featureFlags[ConfigurationFeatures.dateFormat]);
    }
    return ''
  }
  return this.getImportedByEHRDate(patient)
 }

}
