import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, catchError, forkJoin, map, Observable, of, shareReplay, throwError } from 'rxjs';
import { Lens } from 'src/app/model/lens';
import { AuthService } from 'src/app/shared/auth.service';
import { ClinicService } from 'src/app/shared/clinic.service';
import { SurgeonGraphqlService } from 'src/app/shared/service/alcon-graphql/surgeon-graphql.service';
import { LensService } from 'src/app/shared/service/lens/lens.service';
import { SharedService } from 'src/app/shared/service/shared.service';
import { SurgeryService } from 'src/app/shared/surgery.service';

@Injectable({
  providedIn: 'root'
})
export class PatientSurgeryAddEditService {

  private titleList!: any[];
  private preferredLangList!: any[];
  private associatedFacilities!: any[];
  private surgeonCollection: any = {};
  private procedureList!: any[];
  preferredLensCollection$ = new BehaviorSubject<Lens|null>(null);
  lensCollection:any = {};
  public hasHiddenLens:any = {};

  private destroyRef = inject(DestroyRef);

  constructor(
    private sharedService: SharedService,
    private surgeryService: SurgeryService,
    private authService: AuthService,
    public clinicService: ClinicService,
    private surgeonGraphqlService: SurgeonGraphqlService,
    private lensService: LensService
  ) { }

  public getTitles(): Observable<any[]> {
    if(this.titleList)
      return of(this.titleList)

    return this.authService.getTitles()
      .pipe(
        shareReplay(),
        map((res:any)=>{
          this.titleList = res.data;
          return res.data;
        }),
        catchError((err:any)=>{
          this.sharedService.errorAlertDialog(err);
          return throwError(() => err)
        })        
      )
  }

  public getPreferredLangList(): Observable<any[]> {
    if(this.preferredLangList)
      return of(this.preferredLangList)

    return this.surgeryService.getPrefferedLanguages().pipe(
      shareReplay(),
      map((res:any)=>{
        this.preferredLangList = res.data?.filter((lang: { code: string; }) => !lang?.code?.startsWith("en"));
        return this.preferredLangList;
      }),
      catchError((err:any)=>{
        this.sharedService.errorAlertDialog(err);
        return throwError(() => err)
      })
    )
  }

  public getAssociatedFacilities(){
    if(this.associatedFacilities)
      return of(this.associatedFacilities)

    return this.clinicService.details(this.authService.getPracticeOrg()).pipe(
      shareReplay(),
      map((res:any)=>{
        this.associatedFacilities = res.data.associatedFacilities;
        return this.associatedFacilities;
      }),
      catchError((err:any)=>{
        this.sharedService.errorAlertDialog(err);
        return throwError(() => err)
      })
    )
  }

  public getAssociatedSurgeons(ascId: string, clinicId: string) {
    if(this.surgeonCollection[`${ascId}-${clinicId}`])
      return of(this.surgeonCollection[`${ascId}-${clinicId}`])

    return this.surgeonGraphqlService.getSurgeonList(ascId, clinicId).pipe(
      shareReplay(),
      map((res:any)=>{
        this.surgeonCollection[`${ascId}-${clinicId}`] = this.formatSurgeonName(res?.data?.surgeonsByClinicAndASC);
        return this.surgeonCollection[`${ascId}-${clinicId}`];
      }),
      catchError((err:any)=>{
        this.sharedService.errorAlertDialog(err);
        return throwError(() => err)
      })
    )
  }

  private formatSurgeonName(surgeonList: any[]){
    surgeonList?.forEach((surgeon: any) => {
      const prefix = surgeon.prefix && surgeon.prefix != 'Others' ? (surgeon.prefix + '.') : ''
      const name = prefix + ' ' + surgeon.firstName + ' ' + surgeon.lastName;
      surgeon.name = name;
    });
    return surgeonList;
  }

  public getProcedureList() {
    if(this.procedureList)
      return of(this.procedureList)

    return this.surgeryService.getSurgeryTypes().pipe(
      shareReplay(),
      map((res:any)=>{
        this.procedureList = res.data;
        return this.procedureList;
      }),
      catchError((err:any)=>{
        this.sharedService.errorAlertDialog(err);
        return throwError(() => err)
      })
    );
  }

  getLensList(clinicId: string){
    if(this.lensCollection[clinicId]){
      this.preferredLensCollection$.next(this.lensCollection[clinicId]);
    }
      let lensCollection$ =  this.lensService.getLensCollection();
      let hiddenValues$;
      if(!clinicId)
        hiddenValues$ = of(null);
      else{
        hiddenValues$ = this.lensService.getLensPreference(clinicId);
      }

      forkJoin({
        lensCollec: lensCollection$,
        hiddenLens: hiddenValues$
      }).pipe(
        takeUntilDestroyed(this.destroyRef)
      ).subscribe((results : any)  => {
          let lensCollection = this.lensService.groupLensData(results.lensCollec)
          if(results.hiddenLens && results.hiddenLens.length > 0 && lensCollection){
            this.hasHiddenLens[clinicId] = true;
            lensCollection = this.lensService.removeHiddenLens(lensCollection, results.hiddenLens);
          }else{
            this.hasHiddenLens[clinicId] = false;
          }
          this.lensCollection[clinicId] = lensCollection;
          this.preferredLensCollection$.next(this.lensCollection[clinicId]);
        });
  }
}
