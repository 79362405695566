export enum ConfigurationFeatures {
    surgeriesPendingActionWidget = "surgeriesPendingActionWidget",
    enableSurgeryFilter = "enableSurgeryFilter",
    surgeryExportToPDF = "surgeryExportToPDF",
    surgeryExportToExcel = 'surgeryExportToExcel',
    viewSurgeryLog = 'viewSurgeryLog',
    viewPatientLog ='viewPatientLog',
    enableForms = 'enableForms',
    enableIOLPreferences = 'enableIOLPreferences',
    enableFieldPreferences = 'enableFieldPreferences',
    arrivalTimeSettings = 'arrivalTimeSettings',
    ehrSystemName = "ehrSystemName",
    enableEHRWidget = "enableEHRWidget",
    enableINORExperience = 'enableINORExperience',
    dateFormat = 'dateFormat',
    timeFormat = "timeFormat",
    timeFormatNoSpace = "timeFormatNoSpace",
    dateFormatShortMonth = 'dateFormatShortMonth',
    enableArgosWidget = "enableArgosWidget",
}