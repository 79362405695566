import { Injectable } from '@angular/core';
import { addEditRequestOrigin, addEditResumeMode, addEditTab, iolAddEditButtonConfig, managePatientConfig, patientAddEditButtonConfig, patientDataAction, surgeryAddEditButtonConfig } from '../interfaces/patient-surgery-add-edit.interface';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, firstValueFrom, map, mergeMap, Observable, of, retry, Subject } from 'rxjs';
import { PatientService } from 'src/app/services/patient/patient.service';
import { patientDetails, patientSurgery, supplies, iol } from '../../patient/patient-details/patient-details.model';
import { SharedService } from 'src/app/shared/service/shared.service';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { AuthService } from 'src/app/shared/auth.service';
import { appEvents, dataSaveMode, srcSystemTypes } from 'src/app/model/generic.model';
import { addEditPatientForm, patientSaveMode } from 'src/app/shared/model/patient.model';
import { formatDate } from '@angular/common';
import { SurgeryService } from 'src/app/shared/surgery.service';
import { AlconToastMessageService } from 'src/app/shared/alcon-toast-message.service';
import { addEditSurgeryForm, surgerySaveMode, surgeryStatus, surgeryType } from 'src/app/shared/model/surgery.model';
import { eyeList, surgerySuppliesList } from 'src/app/shared/constants/surgery.constants';
import { IOL, iolData } from 'src/app/model/surgery';
import { PatientSurgeryConfirmDialogComponent } from 'src/app/components/patient-surgery-confirm-dialog/patient-surgery-confirm-dialog.component';
import { PatientSurgeryAddEditService } from './patient-surgery-add-edit.service';
import { PatientSurgeryAddEditUtilityService } from './patient-surgery-add-edit-utility.service';
import { CustomDialogComponent, dialogAction } from 'src/app/components/modal-dialogs/custom-dialog/custom-dialog.component';
import { PatientGraphqlService } from 'src/app/shared/service/alcon-graphql/patient-graphql.service';
import { SurgeonService } from 'src/app/shared/surgeon.service';
import { lensPriority } from 'src/app/shared/model/iol.model';
import { FacilityManagementService } from 'src/app/services/facility-management.service';
import { UtilityService } from 'src/app/shared/service/utility.service';
import { PatientImportService } from '../../patient-import/services/patient-import.service';
import { Patient, PatientPayload, SavePayload, Surgery, SurgeryPayload } from '../../patient-import/models/patient-import-model';
import { NavigationService } from 'src/app/shared/service/navigation.service';


@Injectable({
  providedIn: 'root'
})
export class PatientSurgeryAddEditCtrlService {
  
  dialogTitle$ = new BehaviorSubject<string>('');
  patientName$ = new BehaviorSubject<string>('');
  currentFacilityName$ = new BehaviorSubject<string>('');
  patchPatientForm$ = new BehaviorSubject<any>('');
  patchSurgeryForm$ = new BehaviorSubject<any>('');
  patchPrefillSurgeryData$ = new BehaviorSubject<any>('');
  closeAddEditModel$ = new Subject<appEvents | string>();
  silentCloseAddEditModel$ = new Subject<appEvents | string>();
  addEditModelReOpen$ = new Subject<appEvents | string>();
  enableDisableTabs$ = new Subject<addEditTab>();
  goToTab$ = new BehaviorSubject<number>(0);
  patientTabButtons$ = new BehaviorSubject<patientAddEditButtonConfig>({});
  surgeryTabButtons$ = new BehaviorSubject<surgeryAddEditButtonConfig>({});
  iolTabButtons$ = new BehaviorSubject<iolAddEditButtonConfig>({});
  patientTabLabel$ = new BehaviorSubject<string>('Patient info');
  preferredLensCollection$ = this.addEditService.preferredLensCollection$;
  showDuplicatePatientWarning$ = new Subject<boolean>();
  showSurgeryErrorMessage$ = new Subject<string>();
  resetPatientAfterSave$ = new Subject<boolean>();
  patientMrnMandatoryCheck$ = new Subject<boolean>();
  patientDataSaveMode$ = new BehaviorSubject<dataSaveMode | null>(null);
  surgeryDataSaveMode$ = new BehaviorSubject<dataSaveMode | null>(null);

  isPatientFromEHR$ = new BehaviorSubject<boolean>(false);
  isEHRPatientLocked$ = new BehaviorSubject<boolean>(false);

  reloadParent = false;
  surgeonPreferDuplicate = false;
  datePlaceholder = 'mm/dd/yyyy'


  private initConfig!: managePatientConfig
  private patientData!:patientDetails | undefined;
  private importPatientData!:Patient | undefined
  public surgeryData!: patientSurgery | undefined;
  private patientDataSaveMode!: dataSaveMode | null;
  private surgeryDataSaveMode!: dataSaveMode | null;
  private isTabVisible = {
    patient: true,
    surgery: true,
    iol: true
  }
  private isPatientDataLoadInProgress = false;
  private patientData$ = new Subject<patientDetails>();
  
  constructor(
    public dialog: MatDialog,
    private patientService: PatientService,
    private sharedService: SharedService,
    private loaderService: LoaderService,
    private authService: AuthService,
    private surgeryService: SurgeryService,
    private toastService: AlconToastMessageService,
    private addEditService: PatientSurgeryAddEditService,
    private addEditUtility: PatientSurgeryAddEditUtilityService,
    public patientGraphqlService: PatientGraphqlService,
    public surgeonService: SurgeonService,
    private facilityService:FacilityManagementService,
    private utilityService:UtilityService,
    private patientImportService: PatientImportService,
    private navigationService: NavigationService
  ) { 
      this.datePlaceholder = this.sharedService.getPlaceHolderForDate();
  }

  updatePatientDataSaveMode() {
    this.patientDataSaveMode$.next(this.patientDataSaveMode);
  }

  updateSurgeryDataSaveMode() {
    this.surgeryDataSaveMode$.next(this.surgeryDataSaveMode);
  }

  setData(config: managePatientConfig){
    this.initConfig = config;
    this.resetControl();
    this.presetPatientDataIfPresent(config);
    this.computeRequiredData(config);
    this.publishFacilityAndPatientInfo();
  }

  private resetControl(){
    this.patientData = undefined;
    this.surgeryData = undefined;
    this.dialogTitle$.next('');
    this.patchPatientForm$.next('');
    this.patchSurgeryForm$.next('');
    this.patchPrefillSurgeryData$.next('');
    this.goToTab$.next(0);
    this.reloadParent = false;
    this.isTabVisible = {
      patient: true,
      surgery: true,
      iol: true
    };
    this.patientDataSaveMode = null;
    this.surgeryDataSaveMode = null;
    this.updatePatientDataSaveMode();
    this.updateSurgeryDataSaveMode();
    this.surgeryDataSaveMode = null;
    
  }

  private presetPatientDataIfPresent(config: managePatientConfig){
    if(!config.patientData)
      return;

    this.patientData = config.patientData;
  }

  private computeRequiredData(config: managePatientConfig){
    switch(config.action){
      case patientDataAction.addPatient:
        this.prepareAddPatientFlow(config);
        break;
      case patientDataAction.patientPageEditPatient:
        this.preparePatientPageEditPatientFlow(config);
        break;
      case patientDataAction.patientPageAddSurgeryToPatient:
        this.preparePatientPageAddSurgeryToPatientFlow(config);
        break;
      case patientDataAction.editSurgery:
        this.prepareEditSurgeryFlow(config);
        break;
      case patientDataAction.patientPageEditSurgery:
        this.preparePatientPageEditSurgeryFlow(config);
        break;
      case patientDataAction.patientPageCloneSurgery:
        this.preparePatientPageCloneSurgeryFlow(config);
        break;
      case patientDataAction.addSurgeryToExistingPatient:
        this.prepareAddSurgeryToExistingPatientFlow(config);
        break;
      case patientDataAction.patientPageAddIol:
        this.preparePatientPageAddIolFlow(config);
        break;
      case patientDataAction.importPatientAndSurgery:
        this.prepareImportPatientSurgeryFlow(config);
        break;
      default:
        //NA
        break;
    }
  }

  private prepareAddPatientFlow(config: managePatientConfig){
    this.dialogTitle$.next("Add new patient");
    this.patientDataSaveMode = dataSaveMode.add;
    this.updatePatientDataSaveMode();
    this.surgeryDataSaveMode = dataSaveMode.add;
    this.setPatientTabButtons({saveAndClose: true, addSurgeryDetails: true});
    this.setSurgeryTabButtons({saveAndClose:true, addIolSpecs: true});
    this.setIolTabButtons({saveAndFinish:true, cancelSurgery: true});

  }

  private preparePatientPageEditPatientFlow(config: managePatientConfig){
    this.dialogTitle$.next('Edit patient information');
    this.hideSurgeryTab();
    this.hideIolTab();
    this.setPatientTabButtons({saveChanges: true});
    this.getPatientData(config).then(()=>{
      this.publishFacilityAndPatientInfo();
      this.patchPatientForm$.next(this.getSavedPatientFormData());
      this.patientDataSaveMode = dataSaveMode.edit;
      this.updatePatientDataSaveMode();
    })
  }
  private prepareImportPatientSurgeryFlow(config: managePatientConfig){
    this.dialogTitle$.next('Import patient from Argos');
 
   this.patientDataSaveMode = dataSaveMode.addImports;
   this.updatePatientDataSaveMode();

    this.getImportPatientData(config).then(()=>{
      this.publishFacilityAndPatientInfo();
      this.patchPatientForm$.next(this.getImportedPatientFormData());
      if(this.importPatientData?.surgeries && this.importPatientData?.surgeries.length > 0){
        this.surgeryDataSaveMode = dataSaveMode.addImports;
        this.updateSurgeryDataSaveMode();
        this.setPatientTabButtons({reviewSurgeryDetails: true});

        this.evaluateButtonConfigForImport();
        this.patchSurgeryForm$.next(this.getImportedSurgeryFormData());
      }else{
        this.hideSurgeryTab();
        this.hideIolTab();
        this.setPatientTabButtons({importToClinicConnect: true});
      }

    })
  }
  
  private evaluateButtonConfigForImport(){
    if(this.importPatientData?.surgeries && this.importPatientData?.surgeries[0].procedure ===  surgeryType.other){
      this.setSurgeryTabButtons({importToClinicConnect: true});
      this.hideIolTab();
    }else{
      this.setSurgeryTabButtons({reviewIol: true});
      this.setIolTabButtons({importToClinicConnect:true});  
    } 
  }
  private preparePatientPageAddSurgeryToPatientFlow(config: managePatientConfig){
    this.dialogTitle$.next('Add new surgery');
    this.hidePatientTab();
    this.populateDefaultClinicOrAscId();
    this.setSurgeryTabButtons({saveAndClose:true, addIolSpecs: true});
    this.setIolTabButtons({saveAndFinish:true, cancelSurgery: true});
    this.getPatientData(config).then(()=>{
      this.publishFacilityAndPatientInfo();
      this.prefillSurgeryData(config);
      this.patchPatientForm$.next(this.getSavedPatientFormData());
      this.patientDataSaveMode = dataSaveMode.edit;
      this.updatePatientDataSaveMode();
      this.surgeryDataSaveMode = dataSaveMode.add;
    })
  }
  
  prefillSurgeryData(config: managePatientConfig){
      if(!config.prefillSurgeryData)
        return;
      this.patchPrefillSurgeryData$.next(config.prefillSurgeryData);
  }

  private prepareAddSurgeryToExistingPatientFlow(config: managePatientConfig){

    this.dialogTitle$.next('Add new surgery to an existing patient');
    this.patientTabLabel$.next('Review Patient');
    this.populateDefaultClinicOrAscId();
    this.setPatientTabButtons({saveAndClose: true, addSurgeryDetails: true});
    this.setSurgeryTabButtons({saveAndClose:true, addIolSpecs: true});
    this.setIolTabButtons({saveAndFinish:true, cancelSurgery: true});
    this.getPatientData(config).then(()=>{
      this.publishFacilityAndPatientInfo();
      this.patchPatientForm$.next(this.getSavedPatientFormData());
      this.patientDataSaveMode = dataSaveMode.edit;
      this.updatePatientDataSaveMode();
      this.surgeryDataSaveMode = dataSaveMode.add;
    })
  }

  getLensDetail(){
    if(this.surgeryData?.surgeryData.procedure !== surgeryType.other && this.surgeryData?.clinic?.practiceOrg){
       this.getLensList(this.surgeryData?.clinic?.practiceOrg);
    }
  }
  private prepareEditSurgeryFlow(config: managePatientConfig){
    this.dialogTitle$.next('Edit patient and surgery information');
    this.switchToSurgeryTab();
    this.setPatientTabButtons({saveAndClose: true, editSurgeryDetails: true});
    this.setSurgeryTabButtons({saveAndClose:true, addIolSpecs: true, cancelSurgery: true});
    this.setIolTabButtons({saveAndFinish:true, cancelSurgery: true});
    this.getPatientData(config).then(()=>{
      this.publishFacilityAndPatientInfo();
      this.patchPatientForm$.next(this.getSavedPatientFormData());
      this.setCurrentSurgery(config);
      this.patchSurgeryForm$.next(this.getSavedSurgeryFormData());
      this.getLensDetail();
      this.patientDataSaveMode = dataSaveMode.edit;
      this.updatePatientDataSaveMode();
      this.surgeryDataSaveMode = dataSaveMode.edit;
      this.evaluateButtonConfigForEditSurgery();
    })
  }

  private preparePatientPageEditSurgeryFlow(config: managePatientConfig){
    this.dialogTitle$.next('Edit surgery details');
    this.hidePatientTab();
    this.setSurgeryTabButtons({saveAndClose:true, cancelSurgery: true});
    this.setIolTabButtons({saveAndFinish:true, cancelSurgery: true});
    this.getPatientData(config).then(()=>{
      this.publishFacilityAndPatientInfo();
      this.patchPatientForm$.next(this.getSavedPatientFormData());
      this.setCurrentSurgery(config);
      this.patchSurgeryForm$.next(this.getSavedSurgeryFormData());
      this.getLensDetail();
      this.patientDataSaveMode = dataSaveMode.edit;
      this.updatePatientDataSaveMode();
      this.surgeryDataSaveMode = dataSaveMode.edit;
      this.evaluateButtonConfigForEditSurgery();
    })
  }

  private preparePatientPageAddIolFlow(config: managePatientConfig){
    this.dialogTitle$.next('Edit surgery details');
    this.hidePatientTab();
    this.switchToIolTab();
    this.getPatientData(config).then(()=>{
      this.publishFacilityAndPatientInfo();
      this.patchPatientForm$.next(this.getSavedPatientFormData());
      this.setCurrentSurgery(config);
      this.patchSurgeryForm$.next(this.getSavedSurgeryFormData());
      this.getLensDetail();
      this.patientDataSaveMode = dataSaveMode.edit;
      this.updatePatientDataSaveMode();
      this.surgeryDataSaveMode = dataSaveMode.edit;
      this.evaluateButtonConfigForEditSurgery();
    })
  }

  private preparePatientPageCloneSurgeryFlow(config: managePatientConfig){
    this.dialogTitle$.next("Clone surgery");
    this.hidePatientTab();
    this.setSurgeryTabButtons({clone:true});
    this.setIolTabButtons({saveAndFinish:true, cancelSurgery: true});
    this.getPatientData(config).then(()=>{
      this.publishFacilityAndPatientInfo();
      this.patchPatientForm$.next(this.getSavedPatientFormData());
      this.setCurrentSurgery(config);
      this.prepareSurgeryDateForClone()
      this.patchSurgeryForm$.next(this.getSavedSurgeryFormData());
      this.getLensDetail();
      this.patientDataSaveMode = dataSaveMode.edit;
      this.updatePatientDataSaveMode();
      this.surgeryDataSaveMode = dataSaveMode.add;
      
    })
  }

  private prepareSurgeryDateForClone(){
    if(!this.surgeryData)
      return;
    
    this.surgeryData.surgeryData.practiceOrg = "";
    this.surgeryData.surgeryId = "";
    this.surgeryData.surgeryData.surgeryDate = "";
  }

  public evaluateButtonConfigForEditSurgery(formData?: addEditSurgeryForm){
    if((this.initConfig.action == patientDataAction.patientPageCloneSurgery && (!this.surgeryDataSaveMode || this.surgeryDataSaveMode == dataSaveMode.add)) || (this.surgeryDataSaveMode === dataSaveMode.addImports)){
      return;
    }
    const isSurgeryCancellable = !!this.surgeryData;
    if(formData ? (formData.procedure ===  surgeryType.other ): (this.surgeryData?.surgeryData.procedure === surgeryType.other)){
      this.setSurgeryTabButtons({cancelSurgery: isSurgeryCancellable, saveAndFinish: true});
    }else{
      if(this.surgeryData?.surgeryData?.iols?.length){
        this.setSurgeryTabButtons({saveAndClose:true, cancelSurgery: isSurgeryCancellable, editIolSpecs: true});
      }else{
        this.setSurgeryTabButtons({saveAndClose:true, cancelSurgery: isSurgeryCancellable, addIolSpecs: true});
      }  
      if(this.initConfig.action == patientDataAction.patientPageAddIol){
        this.setIolTabButtons({saveAndClose:true, cancelSurgery: true});    
      }else{
        this.setIolTabButtons({saveAndFinish:true, cancelSurgery: true});    
      }
    }
  }

  private populateDefaultClinicOrAscId(){
    if(this.authService.isAscAccount){
      this.patchSurgeryForm$.next({
        ascId: this.authService.practiseOrg
      })
    }else if(this.authService.isClinicAccount){
      this.patchSurgeryForm$.next({
        clinicId: this.authService.practiseOrg
      })
    }
  }

  private hidePatientTab(){
    this.enableDisableTabs$.next({
      isShowPatient: false
    });
    this.isTabVisible.patient = false;
  }

  private hideSurgeryTab(){
    this.enableDisableTabs$.next({
      isShowSurgery: false
    })
    this.isTabVisible.iol = false;
  }

  public hideIolTab(){
    this.enableDisableTabs$.next({
      isShowIol: false
    })
    this.isTabVisible.iol = false;
  }

  public showIolTab(){
    this.enableDisableTabs$.next({
      isShowIol: true
    })
    this.isTabVisible.iol = true;
  }

  private getPatientData(config: managePatientConfig){
    return new Promise((resolve, reject)=>{
      if(!config.patientId){
        reject();
        return;
      }

      if(this.patientData){
        resolve(true);
        return;
      }

      this.loaderService.showLoader();
      this.isPatientDataLoadInProgress = true;
      this.patientService.getPatientDetails(config.patientId).subscribe({
        next: (res)=>{
          this.patientData = res;
          this.initializeEHRPatientdata(res?.additionalInfo?.applicationMeta?.srcSystemInformation?.createdSrcSystemType);
          this.patientData$.next(res);
          this.loaderService.hideLoader();
          this.isPatientDataLoadInProgress = false;
          resolve(true)
        },
        error: ()=>{
          this.sharedService.errorAlertDialog("Failed to load patient data!");
         this.loaderService.hideLoader();
          this.isPatientDataLoadInProgress = false;
          reject();
        }
      })
    })
  }

  private getImportPatientData(config: managePatientConfig){
    return new Promise((resolve, reject)=>{
      if(!config.requestId){
        reject();
        return;
      }

      if(this.patientData){
        resolve(true);
        return;
      }

      this.loaderService.showLoader();
      this.isPatientDataLoadInProgress = true;
      this.patientImportService.getImportedPatientById(config.requestId).subscribe({
        next: (res)=>{
          this.importPatientData = res;
          this.loaderService.hideLoader();
          this.isPatientDataLoadInProgress = false;
          resolve(true)
        },
        error: ()=>{
          this.sharedService.errorAlertDialog("Failed to load patient data!");
          this.loaderService.hideLoader();
          this.isPatientDataLoadInProgress = false;
          reject();
        }
      })
    })
  }

  initializeEHRPatientdata(lastUpdatedSrcSystemType: string | undefined) {
    const isEHR = lastUpdatedSrcSystemType === srcSystemTypes.EHR;
    this.isPatientFromEHR$.next(isEHR);
    if(isEHR) this.isEHRPatientLocked$.next(true);
  }

  getCurrentFacilityMrnFromAPI(returnEmpty?: boolean){
    if(!this.patientData)
    return ''

    return this.facilityService.getCurrentFacilityPatientId(this.patientData!,this.authService.getPracticeOrg(), returnEmpty)
  }

  private getSavedPatientFormData(): addEditPatientForm {
    if(!this.patientData)
      return {}

    return {
      title: this.patientData.prefix ? this.patientData.prefix.replace(/[\[\]]/g, '') : '',
      gender: this.patientData.gender,
      firstName: this.patientData.firstName,
      middleName: this.patientData.middleName,
      lastName: this.patientData.lastName,
      dateOfBirth: this.utilityService.getDateWithZeroHours(this.patientData.birthDate),
      phoneNumberCountryCode: this.patientData.additionalInfo.phoneNumberCountryCode,
      phoneNumber: this.patientData.phone,
      alternateContactName: this.patientData.additionalInfo.alternateContactName,
      alternatePhoneNumber: this.patientData.additionalInfo.alternatePhoneNumber,
      email: this.patientData.email,
      patientMrn: this.getCurrentFacilityMrnFromAPI(true),
      diabetesStatus: this.patientData.additionalInfo.diabetesStatus,
      allergies: this.getAllergyString(this.patientData.additionalInfo.allergyOther),
      preferredLanguage:  {
        code: this.patientData.languageCode,
        display: this.patientData.languageDisplay
      },
      translatorNeeded: this.patientData.additionalInfo.translatorNeeded,
      patientTimeOfDayPreference: this.patientData.additionalInfo.patientTimeOfDayPreference,
      comments: this.patientData.additionalInfo.comments,
      signLanguage: this.patientData.additionalInfo.signLanguage
    }
  }
  private getImportedPatientFormData(): addEditPatientForm {
    if(!this.importPatientData)
      return {}
      
    return {
      gender: this.importPatientData.gender,
      firstName: this.importPatientData.firstName,
      middleName: this.importPatientData.middleName,
      lastName: this.importPatientData.lastName,
      dateOfBirth: this.utilityService.getDateWithZeroHours(this.importPatientData.birthDate),
      patientMrn: this.importPatientData.mrn
    }
  }

  private getImportedSurgeryFormData(){
    if(!this.importPatientData || !this.importPatientData.surgeries || this.importPatientData.surgeries.length <= 0)
      return {}

    const surgery = this.importPatientData.surgeries[0];
    return {
      clinicId: surgery?.clinicId,
      ascId: surgery?.ascId,
      surgeryDate: surgery?.surgeryDate ? this.utilityService.getDateWithZeroHours(surgery.surgeryDate) : undefined,
      procedure: surgery?.procedure,
      eye: surgery?.eye,
      surgeonId: surgery?.argosSurgeon?.id,
      reviewed: false
    }
  }

  private setReviewedSurgery(){
    return {
      reviewed: true
    }
  }
  private setIolData(){
    if(this.importPatientData && this.importPatientData.surgeries && this.importPatientData.surgeries.length > 0) {
      const surgery:Surgery = this.importPatientData.surgeries[0];
      if(this.surgeryData?.surgeryData.procedure !== surgeryType.other && surgery.iolManufacturer && (this.surgeryData?.surgeryData.iols && this.surgeryData?.surgeryData.iols?.length <= 0 )){
        const iols: iol = {
          diopter: surgery.lensPower ? this.utilityService.formatLensDiopter(surgery.lensPower) : '',
          iolManufacturer: surgery.iolManufacturer ?? '',
          lensPriority: surgery.lensPriority ?? '',
          lensModel: surgery.lensModel ?? '',
          lensType: surgery.lensType ?? ''
        } 
        this.surgeryData?.surgeryData && (this.surgeryData.surgeryData.iols = [iols]);
      }
    }
  }
  private getAllergyString(allergies: any){
    return allergies ? (Array.isArray(allergies) ? allergies.join(', ') : allergies) : '';
  }

  private publishFacilityAndPatientInfo(){
    this.patientData && this.patientData.firstName && this.patientName$.next(this.patientData.firstName+' '+this.patientData.lastName);
    this.currentFacilityName$.next(this.authService.facilityName);
  }

  private setCurrentSurgery(config: managePatientConfig){
    if(!config.surgeryId)
      return;

    this.surgeryData = this.patientData?.surgeries?.find(surgery => config.surgeryId === surgery.surgeryId);
  }

  getAdditionalSurgeryDetails(){
    if(!this.surgeryData)
      return undefined;

    return {
      isORExperienceStarted: !!this.surgeryData?.surgeryData?.inORExperience?.enteredORDttm,
      isConsentFormSign: !!(this.surgeryData?.surgeryData?.consentForm && this.surgeryData?.surgeryData?.consentForm?.status == 'Submitted'),
      isVerificationFormSign: !!this.surgeryData?.surgeryData?.preVerificationForm,
      isOwner: this.patientData ? this.facilityService.isFacilityOwner(this.patientData!.practiceId,this.authService.practiseOrg) : true,
      associatedId: this.getAssociatedId()
    }
  
  }

  isPatientMrnMandatory(){
    if(this.patientData && !this.facilityService.isFacilityOwner(this.patientData!.practiceId,this.authService.practiseOrg)){
     return !!this.facilityService.getCurrentFacilityPatientId(this.patientData!,this.authService.getPracticeOrg(), true);
    }
    return true
  }

  isPatientCloningAllowed(): boolean {
    const practiceOrg = this.authService.getPracticeOrg();
    const isOwner = this.patientData?.practiceId ? this.facilityService.isFacilityOwner(this.patientData.practiceId, practiceOrg) : false;
    const hasSecondaryMrn = this.patientData ? this.facilityService.getCurrentFacilityPatientId(this.patientData, practiceOrg, true) : null;
  
    return !isOwner && !!hasSecondaryMrn;
  }
  
  private getAssociatedId(){
    if( this.patientData && this.surgeryData){
      let associatedPatientId =       this.facilityService.getAssociatedFacilityPatientId(this.patientData,this.authService.practiseOrg,this.surgeryData)
      return  this.utilityService.getAssociatedLabelString()+`: ${associatedPatientId}`
    }
    return  this.utilityService.getAssociatedLabelString()+`: ---`
   }
  private getSavedSurgeryFormData(){
    if(!this.surgeryData)
      return {}

    return {
      clinicId: this.surgeryData.clinic?.practiceOrg,
      surgeonId: this.surgeryData.surgeon?.id,
      ascId: this.surgeryData.asc?.practiceOrg,
      surgeryDate: this.surgeryData.surgeryData?.surgeryDate ? this.utilityService.getDateWithZeroHours(this.surgeryData.surgeryData.surgeryDate) : undefined,
      procedure: this.surgeryData.surgeryData.procedure,
      complexity: this.surgeryData.surgeryData.complexity,
      ora: this.surgeryData.surgeryData.ora,
      lensx: this.surgeryData.surgeryData.lensx,
      waitlistInterest: this.surgeryData.surgeryData.waitlistInterest,
      eye: this.surgeryData.surgeryData.eye,
      firstOrSecondEye: this.surgeryData.surgeryData.firstOrSecondEye,
      setFor: this.surgeryData.surgeryData.setFor,
      generalAnesthesia: this.surgeryData.surgeryData.generalAnesthesia,
      surgeryComment: this.surgeryData.surgeryData.surgeryComment,
      postopMeds: this.surgeryData.surgeryData.postopMeds,
      supplies: this.convertSuppliesToArray(this.surgeryData.surgeryData.supplies),
      otherSupplies: this.surgeryData.surgeryData.otherSupplies && this.surgeryData.surgeryData.otherSupplies.join(','),
      otherMeds: this.surgeryData.surgeryData.otherMeds,
      surgeryName: this.surgeryData.surgeryData.surgeryName,
      surgerySite: this.surgeryData.surgeryData.surgerySite,
      clearance: this.surgeryData.surgeryData.clearance
    }
  }

  public hasSurgery(){
    return this.surgeryData?.surgeryId || this.surgeryDataSaveMode === dataSaveMode.addImports ? true : false;
  }

  public getSavedIolFormData(){
    if(!this.surgeryData)
      return {};

    return {
      iols: this.surgeryData.surgeryData.iols,
      includeSecondPrimary:this.surgeryData.surgeryData.includeSecondPrimary
    }
  }

  public getSurgeryStatus(){
    return this.surgeryData?.surgeryData?.status;
  }

  public getSurgeonPreference(){
      return this.surgeonService.getSurgeonDetails(this.surgeryData?.surgeon?.id);
  }

  getSurgeonBackUpPreference() {
    return new Promise((resolve, rej)=>{
      this.surgeonService.getSurgeonDetails(this.surgeryData?.surgeon?.id).subscribe({
        next: (res: any) => {
          if (res) 
          {
           const record = res.data;
           this.surgeonPreferDuplicate = record.preference?.duplicateOne || record.preference?.duplicateTwo
           resolve(true);
          }
        },
        error: (err: any) => {
          this.sharedService.errorAlertDialog(err);
          rej();
        }
      });
    })
  }
  private convertSuppliesToArray(supplies: any){
    if(!supplies)
      return []

    const supplyArray = [];
    for(const key in supplies){
      if(supplies[key] == true){
        supplyArray.push(key);
      }
    }
    return supplyArray;
  }

  public savePatientInfo(formData: addEditPatientForm, mode: patientSaveMode){
    return new Promise((resolve)=>{
      let payload = this.formatPatientDataForSave(formData);
      if(this.patientDataSaveMode == dataSaveMode.add){
        this.saveNewPatient(payload).then((res:any)=>{
          this.handleAfterPatientDataSave(mode, payload, res?.data?.patientId);
          this.patientDataSaveMode = dataSaveMode.edit;
          this.updatePatientDataSaveMode();
          resolve(true);
        })
      }else if(this.patientDataSaveMode == dataSaveMode.edit){
        if (this.patientData && !this.facilityService.isFacilityOwner(this.patientData!.practiceId,this.authService.practiseOrg)){
          payload = this.getAssociatedMrnAddedPayload(payload)
        }
        this.editPatient(payload).then(()=>{
          this.handleAfterPatientDataSave(mode, payload);
          resolve(true);
        })
      }else if(this.patientDataSaveMode == dataSaveMode.addImports){
        this.handleAfterPatientDataSave(mode, payload, '');
        this.updatePatientDataSaveMode();
        if(mode == patientSaveMode.importPatient){
            this.savePatientAndSurgeryInfo();
        }else{
          this.patchSurgeryForm$.next(this.setReviewedSurgery());
          this.savePatientAndAddEditSurgeryHandle();
          resolve(true);
        }
      }
    })
  }

  private getAssociatedMrnAddedPayload(payload: any){
    let formValue = payload;
    if(payload.patientMrn.trim()){
        const associatedMrnObject = 
        {
          practiceOrg: this.authService.getPracticeOrg(),
          secondaryMrn: payload.patientMrn.trim()
        }
        formValue.associatedMrn = associatedMrnObject
    }else{
      delete formValue.associatedMrn;
    }
    formValue.patientMrn = this.patientData?.mrn
    return formValue
  }

  private saveNewPatient(payload: any){
    return new Promise((resolve, reject)=>{
      this.loaderService.showLoader();
      this.surgeryService.patient_create(payload).subscribe({
        next: (res)=>{
          this.loaderService.hideLoader();
          resolve(res);
        },
        error: (err)=>{
          if(err && err.data?.isExistingPatient) {
            this.showDuplicatePatientWarning$.next(true);
          }else{
            this.sharedService.errorAlertDialog(err);
          }
          this.loaderService.hideLoader();
          reject(err)
        }
      })
    })
  }

  private editPatient(payload: any){
    return new Promise((resolve, reject)=>{
      if(!this.isPatientDataChanged(payload)){
        resolve('');
        return;
      }
      this.loaderService.showLoader();
      this.surgeryService.patient_update(this.patientData?.patientId, payload).subscribe({
        next: (res)=>{
          this.loaderService.hideLoader();
          resolve(res);
        },
        error: (err)=>{
          if(err && err.data?.isExistingPatient) {
            this.showDuplicatePatientWarning$.next(true);
          }else{
            this.sharedService.errorAlertDialog(err);
          }
          if(!this.facilityService.isFacilityOwner(this.patientData!.practiceId)){
            if(err.includes('MRN should not be null or empty')){
              err = "Once added, you can change your patient ID (medical record number) but not leave it empty."
            }
          }
          this.loaderService.hideLoader();
          reject(err)
        }
      })
    })
  }

  private formatPatientDataForSave(formData: addEditPatientForm){
    return {
      allergies: formData.allergies,
      alternateContactName: formData.alternateContactName,
      alternatePhoneNumber: formData.alternatePhoneNumber,
      comments: formData.comments,
      dateOfBirth: formData.dateOfBirth && formatDate(formData.dateOfBirth,'yyyy-MM-dd',"en-US"),
      diabetesStatus: formData.diabetesStatus,
      email: formData.email,
      firstName: formData.firstName,
      gender: formData.gender,
      lastName: formData.lastName,
      middleName: formData.middleName,
      patientMrn: formData.patientMrn,
      patientTimeOfDayPreference: formData.patientTimeOfDayPreference,
      phoneNumber: formData.phoneNumber,
      phoneNumberCountryCode: formData.phoneNumberCountryCode,
      practiceOrg: this.patientData?.practiceId || this.authService.practiseOrg,
      preferredLanguage: formData.preferredLanguage,
      signLanguage: formData.signLanguage,
      title: formData.title,
      translatorNeeded: formData.translatorNeeded
    }
  }

  private isPatientDataChanged(payload:any){
    return payload.allergies !== this.getAllergyString(this.patientData?.additionalInfo.allergyOther)
    || payload.alternateContactName !== this.patientData?.additionalInfo.alternateContactName
    || (payload.alternatePhoneNumber || '') !== (this.patientData?.additionalInfo.alternatePhoneNumber || '')
    || payload.comments !== this.patientData?.additionalInfo.comments
    || payload.dateOfBirth !== this.patientData?.birthDate
    || payload.diabetesStatus !== this.patientData?.additionalInfo.diabetesStatus
    || payload.email !== this.patientData?.email
    || payload.firstName !== this.patientData?.firstName
    || payload.gender !== this.patientData?.gender
    || payload.lastName !== this.patientData?.lastName
    || payload.middleName !== this.patientData?.middleName
    || payload.patientMrn !== this.patientData?.mrn
    || payload.patientTimeOfDayPreference !== this.patientData?.additionalInfo.patientTimeOfDayPreference
    || payload.phoneNumber !== this.patientData?.phone
    || payload.phoneNumberCountryCode !== this.patientData?.additionalInfo.phoneNumberCountryCode
    || (payload.preferredLanguage?.code || '') !== (this.patientData?.languageCode || '')
    || payload.signLanguage !== this.patientData?.additionalInfo.signLanguage
    || payload.title !== (this.patientData?.prefix ? this.patientData?.prefix.replace(/[\[\]]/g, '') : '')
    || payload.translatorNeeded !== this.patientData?.additionalInfo.translatorNeeded
    || (!this.facilityService.isFacilityOwner(this.patientData!.practiceId,this.authService.practiseOrg) && payload.associatedMrn?.secondaryMrn !==  this.facilityService.getAssociatedMrn(this.patientData!))
  }

  private handleAfterPatientDataSave(mode: patientSaveMode, payload: any, patientId?: string){
    const isPatientDataChanged = this.isPatientDataChanged(payload);
    isPatientDataChanged && this.patientDataSaveMode !== dataSaveMode.addImports && this.setParentReloadStatus();
    this.storePatientDataAfterSave(payload, patientId);
    this.populateDefaultClinicOrAscId();
    this.publishFacilityAndPatientInfo();
    this.resetPatientAfterSave$.next(true);
    switch(mode){
      case patientSaveMode.savePatientAndClose:
        this.savePatientAndCloseHandle(isPatientDataChanged)
        break;
      case patientSaveMode.savePatientAndAddEditSurgery:
        this.savePatientAndAddEditSurgeryHandle()
        break;
      case patientSaveMode.patientPageSaveChanges:
        this.savePatientPageSaveChangesHandle()
        break;
      case patientSaveMode.tabSwitchSaveChanges:
        this.patientTabSwitchAwayDataSaveHandle()
        break;
    }
  }

  private patientTabSwitchAwayDataSaveHandle(){
    switch(this.initConfig.action){
      case patientDataAction.patientPageCloneSurgery:
        this.toastService.showSuccessToast("Surgery cloned");
        break;
    }
  }

  private storePatientDataAfterSave(payload: any, patientId?: string){
    const userData = this.getPatientStoreFormat(payload, patientId);
      if(!this.patientData){
        this.patientData = {
        ...userData.basicInfo,
        surgeries: [],
        additionalInfo: {
          ...userData.additionalInfo
        }
      }
    }else{
      this.patientData = {
        ...this.patientData,
        ...userData.basicInfo,
        additionalInfo: {
          ...this.patientData.additionalInfo,
          ...userData.additionalInfo
        },
        associatedMrnMappings: [
          ...userData.associatedMrnMappings
        ]
      }
    };
    if(this.patientData && !this.facilityService.isFacilityOwner(this.patientData!.practiceId,this.authService.practiseOrg))
      this.patientMrnMandatoryCheck$.next(true);
  }

  private getPatientStoreFormat(payload:any, patientId?: string){
    const basicInfo:any = {
      firstName: payload.firstName,
      lastName: payload.lastName,
      middleName: payload.middleName,
      email: payload.email,
      gender: payload.gender,
      mrn: payload.patientMrn,
      phone: payload.phoneNumber,
      birthDate: payload.dateOfBirth,
      prefix: payload.title,
      languageCode: payload.preferredLanguage?.code,
      languageDisplay: payload.preferredLanguage?.display,
      practiceId: payload.practiceOrg,
    }
    patientId && (basicInfo.patientId = patientId);
    return {
      basicInfo,
      additionalInfo: {
        comments: payload.comments,
        diabetesStatus: payload.diabetesStatus,
        allergyOther: payload.allergies,
        translatorNeeded: payload.translatorNeeded,
        patientTimeOfDayPreference: payload.patientTimeOfDayPreference,
        alternateContactName: payload.alternateContactName,
        alternatePhoneNumber: payload.alternatePhoneNumber,
        signLanguage: payload.signLanguage,
        phoneNumberCountryCode: payload.phoneNumberCountryCode
      },
      associatedMrnMappings:[{
        associatedPracticeId: this.authService.practiseOrg,
        associatedMrn: (this.patientData && !this.facilityService.isFacilityOwner(this.patientData!.practiceId,this.authService.practiseOrg)) ? payload.associatedMrn?.secondaryMrn: ''
      }]
    }
  }

  private savePatientAndCloseHandle(isPatientDataChanged: boolean){
    if(!isPatientDataChanged){
      this.toastService.showInfoToast("No changes to save!");
      return;
    }
    this.closeAddEditModel(this.surgeryData && appEvents.reloadData);
    const message = this.patientDataSaveMode == dataSaveMode.add ? 'Patient created successfully.' : 'Patient updated successfully.';
    const linkText = this.surgeryData ? 'Edit surgery details' : 'Add surgery details';
    this.toastService.showSuccessToast(
      message,
      linkText,
      this.patientDataSaveMode == dataSaveMode.add ? addEditResumeMode.addSurgery : addEditResumeMode.editSurgery
    );
  }

  private savePatientAndAddEditSurgeryHandle(){
    this.switchToSurgeryTab()
  }

  private savePatientPageSaveChangesHandle(){
    this.closeAddEditModel();
    this.toastService.showSuccessToast('Patient information updated.');
  }

  public closeAddEditModel(data?:appEvents){
    setTimeout(()=>{
      this.closeAddEditModel$.next(data || "");
    })    
  }

  public silentCloseAddEditModel(){
    setTimeout(()=>{
      this.silentCloseAddEditModel$.next("");
    })    
  }

  public saveSurgeryInfo(formData: addEditSurgeryForm, mode: surgerySaveMode){
    return new Promise((resolve)=>{
      const payload = this.formatSurgeryDataForSave(formData);
      if(this.surgeryDataSaveMode == dataSaveMode.add){
        this.addNewSurgery(payload).then((res:any)=>{
          this.handleAfterSurgeryDataSave(mode, payload, res?.data?.surgeryId);
          this.surgeryDataSaveMode = dataSaveMode.edit;
          this.setPatientTabButtons({saveAndClose: true, editSurgeryDetails: true});
          resolve(true);
        })
      }else if(this.surgeryDataSaveMode == dataSaveMode.edit){
        this.editSurgery(payload).then(()=>{
          this.handleAfterSurgeryDataSave(mode, payload);
          resolve(true);
        })
      }else if(this.surgeryDataSaveMode == dataSaveMode.addImports){
        this.handleAfterSurgeryDataSave(mode, payload);
        if(mode == surgerySaveMode.importPatientAndSurgery){
            this.savePatientAndSurgeryInfo();
        }else{
          resolve(true);
        }
      }
    })
  }

  public savePatientAndSurgeryInfo(){
    return new Promise((resolve)=>{
      const payload = this.formatPatientSurgeryDataForImport();
      this.addNewPatientAndSurgery(payload).then((res:any)=>{
        const patientName = `${payload?.patientRequest?.lastName}, ${payload?.patientRequest?.firstName}`;
        this.handleAfterPatientSurgerySave(res?.patientId, patientName);
        resolve(true);
      })
      
    })
  }

  routeToPatientDetailsPage(patientId: string){
    this.navigationService.navigateTo(['patient-details', patientId]);
  }

  handleAfterPatientSurgerySave(patientId: string, patientName: string){
    let dialogMessage = `${patientName} has been successfully imported to Clinic Connect`;
    
    const dialogRef = this.dialog.open(CustomDialogComponent,{
      hasBackdrop: true,
      backdropClass: 'dialog-backdrop',
      data: {
        type: 'cancel-surgery',
        icon: "assets/images/round-check-green.svg",
        title: dialogMessage,
        buttonText: {
          primary: "View patient in Clinic Connect",
          secondary: "Go to Argos import list",
        },
      },
    });
    dialogRef.afterClosed().subscribe((value: dialogAction) => {    
      if(value == dialogAction.primary) {
        this.routeToPatientDetailsPage(patientId);
      }
      this.closeAddEditModel(appEvents.reloadData);
    })
  }

  private addNewPatientAndSurgery(payload:any){
    return new Promise((resolve, reject)=>{
      this.loaderService.showLoader();
      this.patientImportService.savePatientAndSurgery(payload).subscribe({
        next: (res)=>{
          this.loaderService.hideLoader();
          resolve(res);
        },
        error: (err)=>{
          if(err && err.data?.isExistingPatient) {
            this.showDuplicatePatientWarning$.next(true);
          }else{          
            this.sharedService.showRetryAlert().then(()=>{
              this.savePatientAndSurgeryInfo();
            });
          }
          this.loaderService.hideLoader();
          reject(err)
        }
      })
    })
  }

  
  private formatPatientSurgeryDataForImport(): SavePayload | null{
    if(!this.patientData || !this.initConfig.requestId)
      return null
    let patientPayload: PatientPayload ={
      title: this.patientData.prefix ? this.patientData.prefix.replace(/[\[\]]/g, '') : '',
      gender: this.patientData.gender,
      firstName: this.patientData.firstName,
      middleName: this.patientData.middleName,
      lastName: this.patientData.lastName,
      dateOfBirth: this.patientData.birthDate && formatDate(this.patientData.birthDate,'yyyy-MM-dd',"en-US"),
      phoneNumberCountryCode: this.patientData.additionalInfo.phoneNumberCountryCode,
      phoneNumber: this.patientData.phone ?? '',
      alternateContactName: this.patientData.additionalInfo.alternateContactName,
      alternatePhoneNumber: this.patientData.additionalInfo.alternatePhoneNumber,
      email: this.patientData.email ?? '',
      patientMrn: this.getCurrentFacilityMrnFromAPI(true),
      diabetesStatus: this.patientData.additionalInfo.diabetesStatus,
      allergies: this.getAllergyString(this.patientData.additionalInfo.allergyOther),
      preferredLanguage:  {
        code: this.patientData.languageCode,
        display: this.patientData.languageDisplay
      },
      translatorNeeded: this.patientData.additionalInfo.translatorNeeded,
      patientTimeOfDayPreference: this.patientData.additionalInfo.patientTimeOfDayPreference ?? '',
      comments: this.patientData.additionalInfo.comments,
      signLanguage: this.patientData.additionalInfo.signLanguage,
      practiceOrg: this.authService.practiseOrg
    }   
    let payload:SavePayload = {
      importRequestId: this.initConfig.requestId,
      patientRequest: patientPayload,
    }
    
    if(this.surgeryData)
    {
      let surgeryPayload: SurgeryPayload = {
        clinicId: this.surgeryData.clinic?.practiceOrg ?? '',
        surgeonId: this.surgeryData.surgeon?.id ?? '',
        ascId: this.surgeryData.asc?.practiceOrg ?? '',
        surgeryDate: this.surgeryData.surgeryData?.surgeryDate && formatDate(this.surgeryData.surgeryData.surgeryDate,'yyyy-MM-dd',"en-US"),
        procedure: this.surgeryData.surgeryData.procedure,
        complexity: this.surgeryData.surgeryData.complexity,
        ora: this.surgeryData.surgeryData.ora,
        lensx: this.surgeryData.surgeryData.lensx,
        waitlistInterest: this.surgeryData.surgeryData.waitlistInterest,
        eye: this.surgeryData.surgeryData.eye,
        firstOrSecondEye: this.surgeryData.surgeryData.firstOrSecondEye,
        setFor: this.surgeryData.surgeryData.setFor,
        generalAnesthesia: this.surgeryData.surgeryData.generalAnesthesia,
        surgeryComment: this.surgeryData.surgeryData.surgeryComment,
        postopMeds: this.surgeryData.surgeryData.postopMeds,
        supplies: this.surgeryData.surgeryData.supplies,
        otherSupplies: this.surgeryData.surgeryData.otherSupplies,
        otherMeds: this.surgeryData.surgeryData.otherMeds ?? '',
        surgeryName: this.surgeryData.surgeryData.surgeryName,
        surgerySite: this.surgeryData.surgeryData.surgerySite,
        clearance: this.surgeryData.surgeryData.clearance,
        practiceOrg: this.authService.practiseOrg,
        iols: this.surgeryData.surgeryData.iols
      }
      payload.surgeryRequest = surgeryPayload;
    }
    return payload;
  }
  public async saveIolInfo(iols: iolData, mode: surgerySaveMode){
    return new Promise(async (resolve, reject)=>{
      const payload = this.formatIolDataForSave(iols);
      const duplicateIOL = this.compareIolData(payload.iols);
      await this.getSurgeonBackUpPreference();

      if(duplicateIOL && !this.surgeonPreferDuplicate){
        this.loaderService.hideLoader();
        await this.addEditUtility.showDuplicateDialog();
      }
      if(this.surgeryDataSaveMode == dataSaveMode.addImports){
        this.surgeryData?.surgeryData && (this.surgeryData.surgeryData.iols = iols.iols);
          if(mode === surgerySaveMode.saveIOLandClose){
            this.savePatientAndSurgeryInfo();
          }
      }else{
        this.editIol(payload).then(()=>{
          this.handleAfterSurgeryDataSave(mode, payload);
          resolve(true);
        })
      }
      
    });
  }

  private addNewSurgery(payload:any){
    return new Promise((resolve, reject)=>{
      this.loaderService.showLoader();
      this.surgeryService.surgery_create(payload, this.authService.practiseOrg).subscribe({
        next: (res)=>{
          this.loaderService.hideLoader();
          resolve(res);
        },
        error: (err)=>{
          if(err.includes('The patient already has a surgery scheduled') && payload.procedure === surgeryType.other) {
              this.showSurgeryErrorMessage$.next(err);
          }else{
            this.sharedService.errorAlertDialog(err);
          }
          this.loaderService.hideLoader();
          reject(err)
        }
      })
    })
  }

  private editSurgery(payload:any){
    return new Promise((resolve, reject)=>{
      if(!this.isSurgeryDataChanged(payload)){
        resolve('');
        return;
      }
      this.loaderService.showLoader();
      this.surgeryService.surgery_update(this.surgeryData?.surgeryId, payload, payload.practiceOrg).subscribe({
        next: (res)=>{
          this.loaderService.hideLoader();
          resolve(res);
        },
        error: (err)=>{
          if(err.includes('The patient already has a surgery scheduled') && payload.procedure === surgeryType.other) {
            this.showSurgeryErrorMessage$.next(err);
          }else{
            this.sharedService.errorAlertDialog(err);
          }
          this.loaderService.hideLoader();
          reject(err)
        }
      })
    })
  }

  private editIol(payload:any){
    return new Promise((resolve, reject)=>{
      if(!this.isSurgeryIolChanged(payload.iols, payload.includeSecondPrimary)){
        resolve('');
        return;
      }
      this.loaderService.showLoader();
      this.surgeryService.surgery_update(this.surgeryData?.surgeryId, payload, payload.practiceOrg).subscribe({
        next: (res)=>{
          this.surgeryData && (this.surgeryData.surgeryData.iols = payload.iols);
          this.setParentReloadStatus();
          this.loaderService.hideLoader();
          resolve(res);
        },
        error: (err)=>{
          this.sharedService.errorAlertDialog(err);
          this.loaderService.hideLoader();
          reject(err)
        }
      })
    })
  }
  private isSurgeryDataChanged(payload: any){
    return this.surgeryData?.asc?.practiceOrg !== payload.ascId 
    || this.surgeryData?.surgeryData.clearance !== payload.clearance
    || this.surgeryData?.clinic?.practiceOrg !== payload.clinicId
    || this.surgeryData?.surgeryData.complexity !== payload.complexity
    || this.surgeryData?.surgeryData.eye !== payload.eye
    || this.surgeryData?.surgeryData.firstOrSecondEye !== payload.firstOrSecondEye
    || this.surgeryData?.surgeryData.generalAnesthesia !== payload.generalAnesthesia
    || this.surgeryData?.surgeryData.lensx !== payload.lensx
    || this.surgeryData?.surgeryData.ora !== payload.ora
    || this.surgeryData?.surgeryData.otherMeds !== payload.otherMeds
    || this.surgeryData?.surgeryData.otherSupplies?.join(',') !== payload.otherSupplies?.join(',')
    || this.surgeryData?.surgeryData.postopMeds !== payload.postopMeds
    || this.surgeryData?.surgeryData.procedure !== payload.procedure
    || this.surgeryData?.surgeryData.setFor !== payload.setFor
    || this.isSuppliesObjectChanged(this.surgeryData?.surgeryData.supplies as supplies, payload.supplies)
    || this.surgeryData?.surgeon?.id !== payload.surgeonId
    || this.surgeryData?.surgeryData.surgeryComment !== payload.surgeryComment
    || (this.surgeryData?.surgeryData.surgeryDate || '') !== (payload.surgeryDate || '')
    || this.surgeryData?.surgeryData.surgeryName !== payload.surgeryName
    || this.surgeryData?.surgeryData.surgerySite !== payload.surgerySite
    || this.surgeryData?.surgeryData.waitlistInterest !== payload.waitlistInterest    
  }

  private isSurgeryIolChanged(iols: IOL[], includeSecondPrimary: boolean){
    if(this.surgeryData?.surgeryData.includeSecondPrimary !== includeSecondPrimary){
      return true;
    }
    let existingSurgeryIOL = this.surgeryData?.surgeryData.iols;
    if(JSON.stringify(existingSurgeryIOL) === JSON.stringify(iols)){
      return false;
    }
    if(existingSurgeryIOL && existingSurgeryIOL.length > 0 && iols.length > 0){
      if(existingSurgeryIOL.length != iols.length){
        return true;
      }
      const ALL_PROPERTIES: (keyof IOL)[] = Object.keys(iols[0]) as (keyof IOL)[];
      if(this.getDifferenceObject<IOL>(existingSurgeryIOL, iols, ALL_PROPERTIES).length > 0){
        return true;
      }
        return false;
    }
    return true;
  }

  getDifferenceObject<T>(array1: T[], array2: T[], keys: Array<keyof T>) {
      return array1.filter(
        (obj1) => !array2.some((obj2) => keys.every((k) => obj1[k] === obj2[k])),
      );    
  }
  
  private isSuppliesObjectChanged(supplyObj1: supplies, supplyObj2: supplies){
    return supplyObj1.ctr !== supplyObj2.ctr
    || supplyObj1.gatt !== supplyObj2.gatt
    || supplyObj1.hydrus !== supplyObj2.hydrus
    || supplyObj1.irisHooks !== supplyObj2.irisHooks
    || supplyObj1.istent !== supplyObj2.istent
    || supplyObj1.kdb !== supplyObj2.kdb
    || supplyObj1.malyuginRings !== supplyObj2.malyuginRings
    || supplyObj1.omni !== supplyObj2.omni
    || supplyObj1.other !== supplyObj2.other
    || supplyObj1.trypanBlue !== supplyObj2.trypanBlue
  }

  private storeSurgeryDataAfterSave(payload: any, surgeryId?:string){
    const userData = this.getSurgeryStoreFormat(payload)
    if(!this.surgeryData){
      this.surgeryData = {
        asc: {
          ...userData.asc,
          name: ""
        },
        clinic: {
          ...userData.clinic,
          name: ""
        },
        surgeon: {
          ...userData.surgeon,
          firstName: "",
          lastName: "",
          email: "",
          prefix: ""
        },
        surgeryId: payload.surgeryId || surgeryId,
        surgeryData:{
          ...userData.surgeryData,
          consentForm: {status: ""},
          isDeleted: false,
          status: "",
          iols: [],
          includeSecondPrimary: false,
          applicationMeta: {documents: []},
          preVerificationForm: null,
          updatedAt: ""
        },
        updatedAt: "",
        iolSurgeonPreferences: []
      }
    }else{
      this.surgeryData = {
        ...this.surgeryData,
        asc: {
          ...userData.asc,
          name: ""
        },
        clinic: {
          ...userData.clinic,
          name: ""
        },
        surgeon: {
          ...userData.surgeon,
          firstName: "",
          lastName: "",
          email: "",
          prefix: ""
        },
        surgeryId: payload.surgeryId || surgeryId,
        surgeryData:{
          ...this.surgeryData.surgeryData,
          ...userData.surgeryData
        }
      }
    }
    this.surgeryDataSaveMode === dataSaveMode.addImports && this.setIolData();
  }

  private getSurgeryStoreFormat(payload: any){
    return {
      surgeryData: {
        clearance: payload.clearance,
          complexity: payload.complexity,
          eye: payload.eye,
          firstOrSecondEye: payload.firstOrSecondEye,
          generalAnesthesia: payload.generalAnesthesia,
          lensx: payload.lensx,
          ora: payload.ora,
          otherMeds: payload.otherMeds,
          otherSupplies: payload.otherSupplies,
          patientId: payload.patientId,
          postopMeds: payload.postopMeds,
          practiceOrg: payload.practiceOrg,
          procedure: payload.procedure,
          setFor: payload.setFor,
          supplies: payload.supplies,
          surgeryComment: payload.surgeryComment,
          surgeryDate: payload.surgeryDate,
          surgeryName: payload.surgeryName,
          surgerySite: payload.surgerySite,
          waitlistInterest: payload.waitlistInterest,
          ascId: payload.ascId,
          clinicId: payload.clinicId
      },
      asc: {practiceOrg: payload.ascId},
      clinic: {practiceOrg: payload.clinicId},
      surgeon: {id: payload.surgeonId},
    }
  }

  private formatSuppliesForSave(supplies: string[], procedure: string){
    const supplyList:any = {};
    if(procedure !== surgeryType.other){
      surgerySuppliesList.forEach(supply => {
        supplyList[supply.value] = supplies.includes(supply.value);
      })
    }
    return supplyList;
  }

  private formatSurgeryDataForSave(formData: addEditSurgeryForm){
    let payload:any = {
      ascId: formData.ascId,
      clearance: formData.clearance,
      clinicId: formData.clinicId,
      complexity: formData.complexity,
      eye: formData.eye,
      firstOrSecondEye: formData.firstOrSecondEye,
      generalAnesthesia: formData.generalAnesthesia,
      lensx: formData.lensx,
      ora: formData.ora,
      otherMeds: formData.otherMeds,
      otherSupplies: formData?.otherSupplies ? formData?.otherSupplies?.split(',') : [],
      patientId: this.patientData?.patientId,
      postopMeds: formData.postopMeds,
      practiceOrg: this.surgeryData?.surgeryData.practiceOrg || this.authService.practiseOrg,
      procedure: formData.procedure,
      setFor: formData.setFor,
      supplies: this.formatSuppliesForSave(formData.supplies || [], formData.procedure || ''),
      surgeonId: formData.surgeonId,
      surgeryComment: formData.surgeryComment,
      surgeryDate: formData.surgeryDate && formatDate(formData.surgeryDate,'yyyy-MM-dd',"en-US"),
      surgeryName: formData.surgeryName,
      surgerySite: formData.surgerySite,
      waitlistInterest: formData.waitlistInterest,
      iols: this.surgeryData?.surgeryData.iols || []
    }

    this.surgeryData?.surgeryId && (payload = {...payload, surgeryId: this.surgeryData?.surgeryId})

    return payload;
  }

  private formatIolDataForSave(iols: iolData){
    let surgeryData = this.formatSurgeryDataForSave(this.getSavedSurgeryFormData());
    return { ...surgeryData, ...iols };
  }

  private handleAfterSurgeryDataSave(mode: surgerySaveMode, payload:any, surgeryId?: string){
    const isSurgeryDataChanged = this.isSurgeryDataChanged(payload);
    isSurgeryDataChanged && this.surgeryDataSaveMode !== dataSaveMode.addImports && this.setParentReloadStatus();
    this.storeSurgeryDataAfterSave(payload, surgeryId);  
    switch(mode){
      case surgerySaveMode.saveSurgeryAndClose:
        this.saveSurgeryAndCloseHandle(isSurgeryDataChanged)
        break;
      case surgerySaveMode.saveSurgeryAndAddEditIols:
        this.saveSurgeryAndAddEditIolsHandle()
        break;
      case surgerySaveMode.tabSwitchSurgerySaveChanges:
        this.saveSurgeryAndAddEditIolsHandleByTab()
        break;
      case surgerySaveMode.saveAndFinishSurgery:
        this.saveAndFinishSurgeryHandle()
        break;
      case surgerySaveMode.saveIOLandClose:
        this.saveAndFinishSurgeryHandle()
        break;
      case surgerySaveMode.cloneSurgeryAndClose:
        this.cloneSurgeryAndCloseHandle()
        break;
        case surgerySaveMode.reviewIOLs:
          this.saveSurgeryAndAddEditIolsHandle()
          break;
    }
  }

  private saveSurgeryAndCloseHandle(isSurgeryDataChanged:boolean){
    if(!isSurgeryDataChanged){
      this.toastService.showInfoToast("No changes to save!");
      return;
    }
    this.closeAddEditModel();
    const message = this.surgeryDataSaveMode == dataSaveMode.add ? 'Surgery created successfully.' : 'Surgery updated successfully.' ;
    const linkText = this.surgeryData?.surgeryData?.iols?.length ?  'Edit IOL specs' : 'Add IOL specs';
    this.toastService.showSuccessToast(
      message,
      linkText,
      this.surgeryDataSaveMode == dataSaveMode.add ? addEditResumeMode.addIol : addEditResumeMode.editIol
    );
  }

  private cloneSurgeryAndCloseHandle(){
    this.closeAddEditModel(appEvents.reloadData);
    this.toastService.showSuccessToast('Surgery cloned');
  }
  
  openConfirmDialog(title: string, showDashboardLink: boolean){
    this.dialog.open(PatientSurgeryConfirmDialogComponent, {
      hasBackdrop: true,
      backdropClass: 'dialog-backdrop',
      panelClass: 'alc_add_patient_dialog',
      disableClose: true,
      data: { 'title': title , 'showDashboardLink': showDashboardLink },
    });
  }

  private saveSurgeryAndAddEditIolsHandle(){
    this.getLensDetail();
    this.switchToIolTab()
  }

  private saveSurgeryAndAddEditIolsHandleByTab(){
    this.getLensDetail();
  }
  private saveAndFinishSurgeryHandle(){
    switch(this.initConfig.action){
      case patientDataAction.addPatient:
        this.showSuccessModal({showDashboardLink: true, title: "Patient & surgery created successfully"});
        this.silentCloseAddEditModel();
        break;
      case patientDataAction.editSurgery:
        this.showSuccessModal({showDashboardLink: true, title: "Patient & surgery updated successfully"});
        this.silentCloseAddEditModel();
        break;
      case patientDataAction.patientPageAddSurgeryToPatient:
        this.showSuccessModal({showDashboardLink: false, title: "Surgery created successfully"});
        this.silentCloseAddEditModel();
        break;
      case patientDataAction.addSurgeryToExistingPatient:
        this.showSuccessModal({showDashboardLink: false, title: "Surgery details added successfully"});
        this.silentCloseAddEditModel();
        break;
      case patientDataAction.patientPageAddIol:
      case patientDataAction.patientPageEditSurgery:
        this.toastService.showSuccessToast('Surgery details updated');
        this.closeAddEditModel();
        break;
    }

  }

  public reopenModel(mode: addEditResumeMode){
    this.reloadParent = false;
    this.restoreModel();

    switch(mode){
      case addEditResumeMode.addSurgery:
        this.reopenAddSurgery()
        break;
      case addEditResumeMode.editSurgery:
        this.reopenEditSurgery()
        break;
      case addEditResumeMode.addIol:
        this.reopenAddIols()
        break;
      case addEditResumeMode.editIol:
        this.reopenEditIols()
        break;
    }
  }

  private restoreModel(){
    this.addEditModelReOpen$.next("")
  }

  private reopenAddSurgery(){
    this.patchPatientForm$.next(this.getSavedPatientFormData());
    this.switchToSurgeryTab()
  }

  private reopenEditSurgery(){
    this.patchPatientForm$.next(this.getSavedPatientFormData());
    this.patchSurgeryForm$.next(this.getSavedSurgeryFormData());
    this.switchToSurgeryTab()
  }

  private reopenAddIols(){
    this.patchPatientForm$.next(this.getSavedPatientFormData());
    this.patchSurgeryForm$.next(this.getSavedSurgeryFormData());
    this.getLensDetail();
    if(this.initConfig.origin === addEditRequestOrigin.patientPage || 
      (this.initConfig.origin === addEditRequestOrigin.patientDashboard && this.initConfig.action === patientDataAction.patientPageAddSurgeryToPatient)){
      this.hidePatientTab();
    }
    this.switchToIolTab()
  }

  private reopenEditIols(){
    this.patchPatientForm$.next(this.getSavedPatientFormData());
    this.patchSurgeryForm$.next(this.getSavedSurgeryFormData());
    this.getLensDetail();
    if(this.initConfig.origin === addEditRequestOrigin.patientPage || 
      (this.initConfig.origin === addEditRequestOrigin.patientDashboard && this.initConfig.action === patientDataAction.patientPageAddSurgeryToPatient)){
      this.hidePatientTab();
    }
    this.switchToIolTab()
  }

  private getTabIndex(tabId: string){
    const visibleTabs = [];
    this.isTabVisible.patient && visibleTabs.push('patient');
    this.isTabVisible.surgery && visibleTabs.push('surgery');
    this.isTabVisible.iol && visibleTabs.push('iol');
    return visibleTabs.indexOf(tabId);
  }

  private switchToSurgeryTab(){
    if(!this.isTabVisible.surgery)
      return;

    const targetTabIndex = this.getTabIndex('surgery');
    this.goToTab$.next(targetTabIndex);
  }

  private switchToIolTab(){
    if(!this.isTabVisible.iol)
      return;

    const targetTabIndex = this.getTabIndex('iol');
    this.goToTab$.next(targetTabIndex);
  }

  private switchToPatientTab(){
    if(!this.isTabVisible.patient)
      return;

    const targetTabIndex = this.getTabIndex('patient');
    this.goToTab$.next(targetTabIndex);
  }

  private setPatientTabButtons(config: patientAddEditButtonConfig){
    this.patientTabButtons$.next(config);
  }

  public setSurgeryTabButtons(config: surgeryAddEditButtonConfig){
    this.surgeryTabButtons$.next(config)
  }
  public setIolTabButtons(config: iolAddEditButtonConfig){
    this.iolTabButtons$.next(config)
  }

  private showSuccessModal(config:{title: string, showDashboardLink: boolean}) {
    this.dialog.open(PatientSurgeryConfirmDialogComponent, {
      hasBackdrop: true,
      backdropClass: 'dialog-backdrop',
      panelClass: 'alc_add_patient_dialog',
      disableClose: true,
      data: { 'title': config.title , 'showDashboardLink': config.showDashboardLink },
    });
  }

  private setParentReloadStatus(){
    this.reloadParent = true;
  }

  public getAssociatedSurgeons(ascId: string, clinicId: string){
    return this.addEditService.getAssociatedSurgeons(ascId, clinicId);
  }

  public getLensList(clinicId: string){
    return this.addEditService.getLensList(clinicId)
  }

  public getFilteredProcedureList(){
    return this.addEditService.getProcedureList().pipe(
      mergeMap(list => {
        if(this.isPatientDataLoadInProgress && this.patientData){
          return this.patientData$.pipe(map(()=>this.addEditUtility.getAllowedProcedures(list, this.patientData?.surgeries, this.initConfig?.surgeryId)));
        }else{
          return of('').pipe(map(()=>this.addEditUtility.getAllowedProcedures(list, this.patientData?.surgeries, this.surgeryData?.surgeryId)));
        }
      })
    )
  }

  public getAssociatedFacilities(){
    return this.addEditService.getAssociatedFacilities()
  }

  public filterFacilitiesByCondition(associatedFacilities$: Observable<any[]>): Observable<any[]> {
    const isFacilityOwner = this.patientData ? this.facilityService.isFacilityOwner(this.patientData!.practiceId,this.authService.practiseOrg) : true;
    return associatedFacilities$.pipe(
      map(facilities => 
        isFacilityOwner ? facilities : facilities.filter(facility => this.patientData?.practiceId === facility.practiceOrg)
      )
    );
  }

  public getTitles(){
    return this.addEditService.getTitles();
  }

  public getPreferredLangList(){
    return this.addEditService.getPreferredLangList();
  }

  public getFilteredEyeList(selectedSurgery:surgeryType){
    return this.addEditUtility.getAllowedEyeList(selectedSurgery, this.patientData?.surgeries, this.surgeryData?.surgeryId)
  }

  public cancelCurrentSurgery() {
    const dialogRef = this.showCancelSurgeryWarning();
    dialogRef.afterClosed().subscribe((value: dialogAction) => {
      if(value == dialogAction.secondary) {
        this.performCancelSurgery().then(()=>{
          this.closeAddEditModel(appEvents.reloadData);
          this.toastService.showSuccessToast('Surgery cancelled');
        });
      }else{
        dialogRef.close();
      }
    })
  }

  public async clonePatient(): Promise<string> {
    if (!this.patientData?.patientId) {
      await this.sharedService.showRetryAlert();
      return this.clonePatient();
    }
    try {
      const res = await firstValueFrom(this.patientService.clonePatient(this.patientData.patientId, this.patientData.practiceId));
      if (res?.data?.patientId) {
        this.toastService.showToastMsg('Patient cloned successfully', '', '', 'Success', 'success');
        return res.data.patientId;
      } else {
        await this.sharedService.showRetryAlert();
        return this.clonePatient();
      }
    } catch (err: any) {
      if (err && err?.data?.isExistingPatient) {
        this.toastService.showToastMsg('You cannot clone this patient because a duplicate patient already exists.', '', '', 'Error', 'error');
        return '';
      } else {
        await this.sharedService.showRetryAlert();
        return this.clonePatient();
      }
    }
  }

  private showCancelSurgeryWarning(){
    let dialogMessage = "";
    let primaryBtnText = "";
    if(this.surgeryData?.surgeryData.operationRoom?.roomId) {
      dialogMessage = "The surgery is already scheduled. By cancelling the surgery, it loses its spot on the schedule and you will no longer be able to edit the surgery.";
      primaryBtnText = "No, go back"
    }else{
      dialogMessage = "You can reschedule by changing the surgery date. If cancelled, you may no longer edit the surgery.";
      primaryBtnText = "No, go back and reschedule";
    }
    return this.dialog.open(CustomDialogComponent,{
      hasBackdrop: true,
      backdropClass: 'dialog-backdrop',
      data: {
        type: 'cancel-surgery',
        icon: "assets/alert-triangle.svg",
        title: 'Are you sure you want to cancel the surgery?',
        text:  dialogMessage,
        buttonText: {
          primary: primaryBtnText,
          secondary: "Yes, cancel surgery",
        },
        isReverseButtonOrder: true
      },
    });
  }

  private performCancelSurgery(){
    return new Promise((resolve, reject)=>{
      this.loaderService.showLoader();
      const payload = {...this.formatSurgeryDataForSave(this.getSavedSurgeryFormData()), status: surgeryStatus.CANCELLED};
      this.surgeryService.surgery_cancel(
        this.surgeryData?.surgeryId, 
        payload, 
        this.surgeryData?.surgeryData.practiceOrg as string
      ).subscribe({
        next: ()=>{
          resolve(true)
          this.loaderService.hideLoader();
        },
        error: (err)=>{
          this.sharedService.errorAlertDialog(err);
          this.loaderService.hideLoader();
          reject(err)
        }
      })
    })
  }

  public checkIfMrnIsDuplicate(value:string) {
    if(value === this.patientData?.mrn)
      return of(false);
    
    let payload: any = {
      patientMRN: value,
      practiceOrg: this.authService.getPracticeOrg(),
    };
    return this.patientGraphqlService.searchPatientMrnGraphQuery(payload).pipe(
      map((res)=>{
        const matchingPatients = res?.data?.patientResponse?.patients.filter((patient:any) => patient.patientId !== this.patientData?.patientId)
        if (matchingPatients.length){
          return true;
        }else{
          return false;
        }
      })
    )
  }

  compareIolData(iols: IOL[]): boolean {
    if(iols && iols.length > 0){
      let duplicateIolFound = false;
      const primaryIolObj = iols.find((iol:any) => iol.lensPriority == lensPriority.primary);
      const otherIols = iols.filter((iol:any) => iol.lensPriority != lensPriority.primary);
      for(let i=1; i<=otherIols.length; i++){
        const alternateIolObj = iols[i];
        if(primaryIolObj && primaryIolObj.iolManufacturer === alternateIolObj.iolManufacturer 
          && primaryIolObj.lensType === alternateIolObj.lensType && primaryIolObj.lensModel === alternateIolObj.lensModel 
          && primaryIolObj.diopter === alternateIolObj.diopter && primaryIolObj.implantationAxis === alternateIolObj.implantationAxis){ 
            duplicateIolFound = true;
            break;
        }
      }
      if(duplicateIolFound) {
        return true;
      }else{
        return false;
      }
    }else{
      return false;
    }
  }

isDuplicateExchangeSurgeryExists(surgeryForm:addEditSurgeryForm){
    return this.addEditUtility.isDuplicateExchangeSurgeryExists(surgeryForm, this.patientData?.surgeries, this.surgeryData?.surgeryId);
}

removePhoneCountryCode(phone: string) {
  if(phone){
    const startIndex = phone.indexOf('(');
    if (startIndex !== -1) {
      return phone.substring(startIndex);
    } 
  }
  return '';
}
}
