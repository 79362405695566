import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NavigationService } from 'src/app/shared/service/navigation.service';
import { SurgeryService } from 'src/app/shared/surgery.service';
@Component({
  selector: 'app-patient-surgery-confirm-dialog',
  templateUrl: './patient-surgery-confirm-dialog.component.html',
  styleUrls: ['./patient-surgery-confirm-dialog.component.scss']
})
export class PatientSurgeryConfirmDialogComponent implements OnInit {
  title: string = '';
  showDashboardLink: boolean = true;
  constructor(public dialogRef: MatDialogRef<PatientSurgeryConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public router: Router, public navigationService: NavigationService
    ,
  private surgeryService: SurgeryService) {}
  closePopup() {
    if(this.showDashboardLink) {
      this.navigationService.navigateTo(['surgeries']);
    } else {
      this.surgeryService.reloadPage();
    }
    this.dialogRef.close();
  }

  closeModal() {
    this.surgeryService.reloadPage();
    this.dialogRef.close();
  }
  ngOnInit() {
     this.title = this.data.title;
     this.showDashboardLink = this.data.showDashboardLink;
  }
}