import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrDayActionComponent } from './or-day-action/or-day-action.component';
import { OrDayAvatarComponent } from './or-day-avatar/or-day-avatar.component';
import { AlconOrExperienceService } from '../../services/alcon-or-experience.service';
import { FormGroup } from '@angular/forms';
import { OrDayAction, primaryIolData } from '../../models/alcon-or-experience.model';
import { AXIS_MODELS } from 'src/app/shared/constants/constants';

@Component({
  selector: 'app-alcon-ore-timeout-capture',
  standalone: true,
  imports: [
    CommonModule,
    OrDayActionComponent,
    OrDayAvatarComponent
  ],
  templateUrl: './alcon-ore-timeout-capture.component.html',
  styleUrl: './alcon-ore-timeout-capture.component.scss'
})
export class AlconOreTimeoutCaptureComponent {
  public isDarkThemeApplied = false;
  AXIS_MODELS = AXIS_MODELS;

  @Input() eyeLabel: string = '';
  @Input() eye: string = '';
  @Input() lensType: string = '';
  @Input() primaryIol: primaryIolData = {
    model: '',
    diopter: '',
    axis: ''
  };
  @Input() timeOutForm: FormGroup  = new FormGroup({});
  constructor(
    public oreService: AlconOrExperienceService
  ){
      this.oreService.isDarkModeEnabled.subscribe(isEnabled => {
        this.isDarkThemeApplied = isEnabled;
      });
  }
}
