import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlconToastMessageComponent } from './alcon-toast-message/alcon-toast-message.component';
import { MatDialog } from '@angular/material/dialog';
import { addEditResumeMode } from '../pages/patientSurgery/interfaces/patient-surgery-add-edit.interface';
@Injectable({
  providedIn: 'root',
})
export class AlconToastMessageService {
  constructor(private snackBar: MatSnackBar, private dialog: MatDialog) {}
  showToastMsg(
    message: string,
    linkText: string,
    resumeEditMode: addEditResumeMode | "",
    messageType: string,
    panelClass: string | string[],
    duration = 20000,
    closeIcon = false
  ) {
    this.snackBar.openFromComponent(AlconToastMessageComponent, {
      data: {
        message: message,
        linkText: linkText,
        resumeEditMode: resumeEditMode,
        messageType: messageType,
        autoClose: closeIcon,
        panelClass: panelClass,
      },
      duration: duration,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: panelClass,
    });
  }

  showSuccessToast(message: string, linkText?: string, resumeEditMode?: addEditResumeMode | "",){
    this.showToastMsg(
      message,
      linkText || '',
      resumeEditMode || '',
      "Success",
      "success"
    );
  }

  showInfoToast(message: string){
    this.showToastMsg(
      message,
      '',
      '',
      "Info",
      "info"
    );
  }

  closeToastMessage() {
    this.snackBar.dismiss();
  }

}
