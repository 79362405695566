import { Component, Inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
  MatSnackBar,
} from '@angular/material/snack-bar';
import { AlconToastMessageService } from '../alcon-toast-message.service';
import { SurgeryService } from '../surgery.service';
import { PatientSurgeryAddEditCtrlService } from 'src/app/pages/patientSurgery/service/patient-surgery-add-edit-ctrl.service';
import { addEditResumeMode } from 'src/app/pages/patientSurgery/interfaces/patient-surgery-add-edit.interface';

@Component({
  selector: 'app-alcon-toast-message',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './alcon-toast-message.component.html',
  styleUrls: ['./alcon-toast-message.component.scss']
})
export class AlconToastMessageComponent {
  @Input() messageType = '';
  constructor(
    public sbRef: MatSnackBarRef<AlconToastMessageComponent>,
    @Inject(MAT_SNACK_BAR_DATA)
    public data: {
      message: string;
      linkText: string;
      resumeEditMode: addEditResumeMode;
      messageType: string;
      autoClose: boolean;
      panelClass: string | string[];
    },
    private snackBar: MatSnackBar,
    private patientAddEditCtrl: PatientSurgeryAddEditCtrlService
  ) {}

  onClose() {
    this.snackBar.dismiss();
  }

  toastLinkClicked(event: MouseEvent, data: any) {
    event.preventDefault();
    this.patientAddEditCtrl.reopenModel(data.resumeEditMode);
  }
}
