import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationFeatures } from '../model/app-configuration.model';
import { AuthService } from '../auth.service';
import { configurationData } from '../constants/auth.constant';
import { alconHttpOptions } from '../constants/constants';
import { environment } from 'src/environments/environment';
import { defaultConfigurationData } from '../constants/app-configuration.constant';

@Injectable({
  providedIn: 'root'
})
export class AppConfigurationService implements OnDestroy{
 featureFlags:any = defaultConfigurationData;
 private mccApiURL = environment.apiUrl + '/' + environment.ccApiRoute;
 localConfiguration:any = {};

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    window.addEventListener('beforeunload', this.onRestoreConfigurationData.bind(this));
   }

  setAppConfiguration(configData: any){
    if(configData && configData.length > 0){
      for (const feature in ConfigurationFeatures) {
        let configValue = this.getConfigValue(feature, configData);
        if(configValue != null)
            this.featureFlags[feature] = this.getConfigValue(feature, configData);
      } 
    }
  }

   getConfigValue(feature: string, configData: any){
    const selectedFilter = configData.filter((x:any) => x.featureName == feature);
    return selectedFilter && selectedFilter[0] ? selectedFilter[0].value : null;
  }
 
  getAppConfiguration(){
    return new Promise((resolve)=>{

        this.localConfiguration = localStorage.getItem(configurationData);
        let retrievedConfiguration: any;
        
        if(this.localConfiguration){
          this.localConfiguration = JSON.parse(this.localConfiguration);
          if(this.localConfiguration[this.authService.practiseOrg]){
            retrievedConfiguration = this.localConfiguration[this.authService.practiseOrg];
            resolve(retrievedConfiguration);
            return;
          }
        }
        
        this.callAppConfigurationService().subscribe({
          next: (res: any) => {
            retrievedConfiguration = res.data;
            if(this.localConfiguration){
              this.localConfiguration[this.authService.practiseOrg] = retrievedConfiguration;
            }else{
              this.localConfiguration = {
                [this.authService.practiseOrg] : retrievedConfiguration
              };
            }          
            localStorage.setItem(configurationData, JSON.stringify(this.localConfiguration));
            resolve(retrievedConfiguration);
          },
          error: () => { 
            //this.errorAlertDialog("Error in configuration API");
            this.handleErrorScenario();
            resolve(null)
          },
          });
         
        });
  }

  // errorAlertDialog(msg:any) {
  //   const dialogRef = this.dialog.open(AlertDialogComponent,{
  //     data:{
  //       message: msg,
  //       buttonText: {
  //         cancel: 'Ok'
  //       }
  //     },
  //   });
  // }

  handleErrorScenario(){
    for (const feature in ConfigurationFeatures) {
      this.featureFlags[feature] = false;
     }
  }
  callAppConfigurationService(practice_org?: string){
    if(!practice_org){
      practice_org = this.authService.practiseOrg;
    }
    const url = this.mccApiURL + '/clinicconnect/featureFlags';
    return this.httpClient
        .get<any>(url, alconHttpOptions);
   // return this.httpClient.get('./assets/stub-data/featureFlag.json');
  }

  public onRestoreConfigurationData(): void {
    if(this.localConfiguration)
      localStorage.setItem(configurationData, JSON.stringify(this.localConfiguration));
  }

  ngOnDestroy(): void {
    window.removeEventListener('beforeunload', this.onRestoreConfigurationData.bind(this));
  }

}
