export const environment = {
  production: false,
  apiUrl: 'https://adi.dev.myalcon.com',
  issuer: 'https://dev2-axon.myalcon.com/oauth2/aus5kk17b3KfCwjA91d7',
  clientId: '0oab4ew3xkER8ULS71d7',
  onetrustScriptSrc: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
  onetrustScriptId: '5bae0436-c4ac-4ed3-9c66-0290e1474325-test',
  sessionTimeOut: 120, //In minutes
  SessionWarningDuration: 5, //In minutes
  ccApiRoute: 'mccapi',
  countryIsoCode: "US", //for now hardcoding as US
  headerApiEndPoint: 'https://adi.dev.myalcon.com/api',
  TEALIUM_ACCOUNT: 'alcon',
  TEALIUM_PROFILE: 'adi-platform',
  TEALIUM_ENV: 'dev',
  baseHref: '/clinicconnect',
  graphQLRoute: 'graphql',
  headerSource: 'clinicconnect',
  adiHomeClientID: '0oadoup28hhYPtA8u1d7',
  oldCCURL: 'clinicconnect.dev.myalcon.com',
  newCCURL: `https://adi.dev.myalcon.com/clinicconnect/en_US/login/sso`,
  customerServiceNo:'1-800-862-5266',
  customerServiceMailId: 'us.customercare@alcon.com',
  oktaFaAcrConfig: 'urn:okta:loa:1fa:any'
};
