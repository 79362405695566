
export const surgeryComplexityList = [
    {label: "Standard", value: "Simple"},
    {label: "Complex", value: "Complex"}
]

export const eyeList = [
    {label: "Left", value: "L"},
    {label: "Right", value: "R"}
]

export const eyeIdList = [
    {label: "1st", value: "1st"},
    {label: "2nd", value: "2nd"}
]

export const eyeSetForList = [
    {label: "Distance", value: "Distance"},
    {label: "Near", value: "Near"},
    {label: "Distance/Near", value: "Distance/Near"}
]

export const surgerySuppliesList = [
    { label: 'Malyugin Ring', value: 'malyuginRings' },
    { label: 'Trypan Blue', value: 'trypanBlue' },
    { label: 'Hydrus', value: 'hydrus' },
    { label: 'iStent', value: 'istent' },
    { label: 'Omni', value: 'omni' },
    { label: 'KDB', value: 'kdb' },
    { label: 'GATT', value: 'gatt' },
    { label: 'CTR', value: 'ctr' },
    { label: 'Iris Hooks', value: 'irisHooks' },
    { label: 'Other', value: 'other' }
];