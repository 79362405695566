import { HttpHeaders } from "@angular/common/http";
import { PatientIdSearchType, searchByOptions } from "src/app/model/generic.model";
import { SurgeryCatSystemFormFields, SurgeryExchSystemFormFields, SurgeryOthSystemFormFields } from "src/app/model/adminFormField";

 export const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
 export const completeSurgeriesMaxLimit = 100;
 export const recentChangesDaysLimitFromToday = 2.592e+8;// 3 days in milli seconds
 export const noTimeZone = 'T00:00:00';
 export const formattedDateStringSlice = -2;
 export const sunday = 0;
 export const saturday = 6;
 export enum DatePickerMode {
  Single = 1,
  Range = 2
}
 export const PostOpMedicationsList = [
    {label: "Routine", value: "Routine"},
    {label: "Combo", value: "Combo"},
    {label: "1x", value: "1x"},
    {label: "2x", value: "2x"},
    {label: "3x", value: "3x"},
    {label: "Other", value: "Other"}
  ];

export enum oraPreferenceValues  {
  point_five_d_ora = 'point_five_d_ora',
  one_d_ora = 'one_d_ora'
}

export enum primaryDuplicateValues {
  one_duplicate = 'one_duplicate',
  two_duplicates = 'two_duplicates'
}

export const sapSerialNumberLength = 10;
export const dateOfBirthMinDate = "1920-01-01";

export const  signLanguageList = [
  { label: 'Not Needed',  value: 'Not Needed'}, 
  { label: 'American Sign Language (ASL)',
    value: 'American Sign Language (ASL)' }
];

export const phoneCountryCodeList = [{
  "name": "United States",
  "dial_code": "+1",
  "code": "US"
},
{
  "name": "Australia",
  "dial_code": "+61",
  "code": "AU"
}
]

export const patientPreferredTimeOfDayList = [
  { label: 'Early', value: 'Early' },
  { label: 'Middle', value: 'Middle' },
  { label: 'Late', value: 'Late' },
]
export const clearanceValues = [

  {label: 'Clearance not needed', value: 'Clearance not needed'},
  {label: 'Clearance needed, not received', value: 'Clearance needed, not received'},
  {label: 'Clearance needed and received', value: 'Clearance needed and received'}

]

export enum DeviceTypes {
  mobile = 'mobile',
  tablet = 'tablet',
  desktop = 'desktop'
}

interface Dimension {
  width: number;
  height: number;
}

interface SignDimensions {
  [DeviceTypes.mobile]: Dimension;
  [DeviceTypes.tablet]: Dimension;
  [DeviceTypes.desktop]: Dimension;
}

export const signDimensions: SignDimensions = {
  [DeviceTypes.mobile]: { width: 200, height: 150 },
  [DeviceTypes.tablet]: { width: 516, height: 150 },
  [DeviceTypes.desktop]: { width: 576, height: 204.8 },
};

export const alconHttpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

export const alconPdfDocHttpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'accept': 'application/pdf'
  }),
  responseType: 'blob' as 'json'
};

export enum preferenceTypes{
  iol = 'IOL' ,
  arrivalTime = 'PREOP_ARRIVALTIME',
  patientForm = 'PATIENT_FIELDS',
  cataractSurgeryForm = 'CATARACT_SURGERY_FIELDS',
  exchangeSurgeryForm = 'EXCHANGE_SURGERY_FIELDS',
  otherSurgeryForm = 'OTHER_SURGERY_FIELDS',
  iolForm = 'IOL_FIELDS'
}

export const camelCaseExceptionList = [ 'IOL' ]

export const AXIS_MODELS = ['TORIC'];

export enum fieldAdminPreferenceTypes {
  PatientFieldPreference = 'PATIENT_FIELDS',
  CataractSurgeryFields = 'CATARACT_SURGERY_FIELDS',
  OtherSurgeryFields = 'OTHER_SURGERY_FIELDS',
  ExchangeSurgeryFields = 'EXCHANGE_SURGERY_FIELDS',
  iolFields = 'IOL_FIELDS',
  allFields = 'ALL_FIELDS'

}

export enum surgeryFields {
  CataractSurgery = 'Cataract Surgery',
  ExchangeSurgery = 'Exchange Surgery',
  OtherSurgery = 'Other Surgery'
}
export enum patientMenuItems{
  clonePatient = 'clonePatient'
}

export interface SurgeryFieldConfig {
  procedure: surgeryFields;
  requiredFields: string[];
  preferenceType: preferenceTypes;
}

export interface PreferenceFieldConfig {
  surgeryFields: SurgeryFieldConfig[];
  patientFields: string[];
}

export const surgeryFieldsConfig = [
  { 
    procedure: surgeryFields.OtherSurgery, 
    requiredFields: SurgeryOthSystemFormFields,
    preferenceType: preferenceTypes.otherSurgeryForm
  },
  { 
    procedure: surgeryFields.CataractSurgery, 
    requiredFields: SurgeryCatSystemFormFields,
    preferenceType: preferenceTypes.cataractSurgeryForm
  },
  { 
    procedure: surgeryFields.ExchangeSurgery, 
    requiredFields: SurgeryExchSystemFormFields,
    preferenceType: preferenceTypes.exchangeSurgeryForm
  }
];

export const iolTableFields = ['lensPreference', 'diopter', 'model', 'lensType', 'manufacturer']



export enum searchByPatientPage {
  Patient = 'patient',
  PatientId = 'patientID'
}


export const searchByPatientIdOptions = [
    {label:PatientIdSearchType.Asc, value:PatientIdSearchType.Asc, placeholder:'Enter the ASC Patient ID'},
    {label:PatientIdSearchType.Clinic, value:PatientIdSearchType.Clinic, placeholder:'Enter the Clinic Patient ID'}
  ];

export const searchByPatientPageId = [
  {label:searchByOptions.Patient, value: searchByPatientPage.Patient, placeholder:'Enter patient name'},
  {label:searchByOptions.PatientId, value: searchByPatientPage.PatientId, placeholder:'Enter patient ID'}
];

export enum dialogAction{
  primary,
  secondary,
  close
}
