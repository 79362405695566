import { CommonModule } from '@angular/common';
import { Component, Input, input } from '@angular/core';
import { AlconOrExperienceService } from '../../../services/alcon-or-experience.service';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { InORExperienceKeys } from '../../../constants/alcon-or-experience.constants';
import { MatIconModule } from '@angular/material/icon';
import { OrDayAction } from '../../../models/alcon-or-experience.model';
import { SharedService } from 'src/app/shared/service/shared.service';
import { pagePermission } from 'src/app/shared/constants/auth.constant';
import { PermissionService } from 'src/app/shared/service/permission.service';

@Component({
  selector: 'alcon-or-day-action',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatIconModule],
  templateUrl: './or-day-action.component.html',
  styleUrl: './or-day-action.component.scss',
})
export class OrDayActionComponent {
  @Input() timeOutForm: FormGroup = new FormGroup({});
  currentActionEditInProgress = false;
  inputFocused: boolean = false;
  pagePermission = pagePermission;
  constructor(
    public oreService: AlconOrExperienceService,
    public sharedService: SharedService,
    public permissionService: PermissionService
  ) {}

  @Input() data: any = '';

  callTime() {
    this.oreService.oreProcessIsLoading = true;
    const field: InORExperienceKeys = this.data.field;
    const timeOutDataCurrent: OrDayAction[] = JSON.parse(
      JSON.stringify(this.oreService.timeOutData.getValue())
    );
    const recordedTime = this.oreService.formatToHHMMAmPm(true);
    const timeOutData = timeOutDataCurrent.map((item, index) => {
      if (item.field === field) {
        /* update the edited field */
        item.recordedTime = recordedTime;
        item.isActive = false;
        item.isLastUpdated = true;
        if (timeOutDataCurrent[index + 1]) {
          timeOutDataCurrent[index + 1].isActive = true;
        }
      } else if(item.isLastUpdated) {
        item.isLastUpdated = false;
      }
      return item;
    });
    const reqPayload = this.oreService.getUpdateOREPayload(
      this.timeOutForm.get('surgeryId')?.value, 
      timeOutDataCurrent,
      this.timeOutForm.get('surgeryDate')?.value,
    );
    this.oreService
      .updateActionORE(reqPayload, this.timeOutForm.get('patientId')?.value, this.timeOutForm.get('practiceOrg')?.value)
      .subscribe({
        next: (result: any) => {
          this.oreService.oreProcessIsLoading = false;
          /* update the changes locally once the date is saved in backend */
          this.timeOutForm.get(field)!.setValue(recordedTime);
          this.oreService.timeOutData.next(timeOutData);
          this.cancelEdit();
        },
        error: () => {
          this.cancelEdit();
          this.oreService.oreProcessIsLoading = false;
          this.sharedService.showRetryAlert().then(()=>{
            this.callTime();
          });
        },
      });
  }

  editTime() {
    this.currentActionEditInProgress = this.oreService.editInProgress = true;
  }

  saveTime() {
    this.oreService.oreProcessIsLoading = true;
    const timeOutData: OrDayAction[] = JSON.parse(
      JSON.stringify(
        this.oreService.timeOutData.getValue().map((val) => {
          if (val.field === this.data.field) {
            val.recordedTime = this.timeOutForm.get(this.data.field)?.value;
          }
          return val;
        })
      )
    );
    const reqPayload = this.oreService.getUpdateOREPayload(
      this.timeOutForm.get('surgeryId')?.value,
      timeOutData,
      this.timeOutForm.get('surgeryDate')?.value
      
    );
    this.oreService
      .updateActionORE(reqPayload, this.timeOutForm.get('patientId')?.value, this.timeOutForm.get('practiceOrg')?.value)
      .subscribe({
        next: (result: any) => {
          this.oreService.oreProcessIsLoading = false;
          this.oreService.timeOutData.next(timeOutData);
          this.cancelEdit();
        },
        error: () => {
          this.oreService.oreProcessIsLoading = false;
          this.sharedService.showRetryAlert().then(()=>{
            this.saveTime();
          });
        },
      });
  }

  cancelEdit(reset = false) {
    if(reset) {
      this.timeOutForm.get(this.data.field)!.setValue(this.data.recordedTime);
    }
    this.currentActionEditInProgress = this.oreService.editInProgress = false;
  }
}
