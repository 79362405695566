export enum surgeryTabType{
    catract = "cataract",
    other = "other",
    cancelled= "cancelled",
    undefined= "undefined",
    exchange="exchange"
}

export const patientGenderList = [
    { label: 'Female', value: 'female' },
    { label: 'Male', value: 'male' },
    { label: 'Other', value: 'other' }
  ];

export enum patientModelTab{
    patient = "patient",
    surgery = "surgery",
    iol= "iol"
}

export interface addEditPatientForm {
    title?: string | null,
    gender?: string | null,
    firstName?: string | null,
    middleName?: string | null,
    lastName?: string | null,
    dateOfBirth?: Date | null,
    phoneNumberCountryCode?: string | null,
    phoneNumber?: string | null,
    alternateContactName?: string | null,
    alternatePhoneNumber?: string | null,
    email?: string | null,
    patientMrn?: string | null,
    diabetesStatus?: boolean | null,
    allergies?: string | null,
    preferredLanguage?: preferredLanguage | null,
    translatorNeeded?: boolean | null,
    patientTimeOfDayPreference?: string | null,
    comments?: string | null,
    signLanguage?: string | null,
    associatedMrn?:associatedMrn| null
}

interface preferredLanguage{
    code: string,
    display: string
}
interface associatedMrn{
    practiceOrg: string,
    secondaryMrn: string
}

export enum patientSaveMode{
    savePatientAndClose = 1,
    savePatientAndAddEditSurgery,
    patientPageSaveChanges,
    tabSwitchSaveChanges,
    importPatient,
    reviewPatientAndEditSurgery
}
