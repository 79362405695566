import { Injectable } from '@angular/core';
import { AuthService } from '../../auth.service';
import { GraphqlService } from './graphql.service';
import { patientImportListType } from 'src/app/model/generic.model';
import { ImportPatientFilterInput } from 'src/app/pages/patient-import/models/patient-import-model';

@Injectable({
  providedIn: 'root',
})
export class PatientImportGraphqlService {
  constructor(private graphqlService: GraphqlService, private readonly authService: AuthService) {}


  getImportedPatientList() {
    let query = `query ArgosPatientAndSurgeryResponse {
      argosPatientAndSurgeryResponse () {
      totalCount
      limit
      offset
      patients {
        firstName 
        middleName
        lastName
        birthDate
        gender
        mrn
        createdAt
        requestId
        surgeries {
          procedure
          eye
          ascId
          clinicId
          lensPower
          lensModel
          lensType
          lensPriority
          iolManufacturer
          surgeryDate
          existingSurgeryId
          createdAt
        }
        existingPatient {
          patientId
          practiceId
          originSystem
          mrn
          active
          firstName
          middleName
          lastName
          prefix
          email
          phone
          languageCode
          languageDisplay
          languageText
          gender
          birthDate
          createdAt
          createdBy
          updatedAt
          updatedBy
          srcSystem
          srcSystemType
      }
    }
  }`;

    const obj = {
      query: query,
      operationName: 'ArgosPatientAndSurgeryResponse',
    };
    const objStringFy = JSON.stringify(obj);
    return this.graphqlService.callgraphApi(objStringFy);
  }

  getImportedPatientCount() {
    let query = `query MyQuery {
      argosPatientAndSurgeryResponse(
        where: {_and: 
        [
          {_text: {field: "argos.patient.practiceId", op: IN, value: "${this.authService.practiseOrg}"}},
          {_text: {field: "argos.patient.status", op:EQ, value:"ACTIVE"}},
        ]}
      ) {
        totalCount
      }
    }`;
    const obj = {
      query: query,
      operationName: 'MyQuery',
    };
    const objStringFy = JSON.stringify(obj);
    return this.graphqlService.callgraphApi(objStringFy);
}

 getAllConfigString(config: ImportPatientFilterInput){
      const sortString = this.getSortString(config);
      const pageString = this.getLimitOffsetString(config);
      return sortString + (sortString.length ? ', ' + pageString : pageString)
  }
getSortString(config: ImportPatientFilterInput){
    switch (config?.sort?.sortField){
      case 'name':
        return  `sort: {direction: ${config.sort?.sortBy}, field: "argos.patient.lastName"}`;
      case 'gender':
        return  `sort: {direction: ${config.sort?.sortBy}, field: "argos.patient.gender, argos.patient.lastName"}`;
      case 'patientId':
        return  `sort: {direction: ${config.sort?.sortBy}, field: "argos.patient.mrn,argos.patient.lastName"}`;
      case 'dob':
         return  `sort: {direction: ${config.sort?.sortBy}, field: "argos.patient.birthDate, argos.patient.lastName"}`; 
      case 'surgeryType':
        return  `sort: {direction: ${config.sort?.sortBy}, field: "argos.patient.surgeries[0].procedure, argos.patient.lastName"}`;
      case 'dateFetched':
         return  `sort: {direction: ${config.sort?.sortBy}, field: "argos.patient.createdAt, argos.patient.lastName"}`; 
      default:
        return `sort: {direction: ASC, field: "argos.patient.lastName"}`;
    }
}

  getLimitOffsetString(config: ImportPatientFilterInput){
    let argString = `limit: 10                          
                     offset: 0`;
    
    if(config?.pagination?.limit !== undefined && config.pagination.limit){
      argString  = "limit: " + config.pagination.limit + " "
    }
 
    if(config?.pagination?.offset !== undefined && config.pagination.offset){
      argString += "offset: " + config.pagination.offset + " "
    }
    return argString;
  }
  //Import new and existing patients
  getPatientImportList(config: any, listType: patientImportListType) {
    let queryString = '';
    switch (listType) {
      case patientImportListType.new:
        queryString = `${this.getNewPatientsQuery(config)}}`;
        break;
      case patientImportListType.existing:
        queryString = `${this.getExistingPatientsQuery(config)}`;
        break;
      case patientImportListType.both:
        queryString =
          this.getNewPatientsQuery(config) +
          this.getExistingPatientsQuery(config);
        break;
    }
    let query = `query MyQuery { ${queryString}`;
    const obj = {
      query: query,
      operationName: 'MyQuery',
    };
    const objStringFy = JSON.stringify(obj);
    return this.graphqlService.callgraphApi(objStringFy);
  }

  getNewPatientsQuery(config: any) {
    let argString = ''
  
     argString += `where: {_and:  
        [
       {_text: {field: "argos.patient.existingPatientId", op: ISEMPTY, value: ""}},
       {_text: {field: "argos.patient.status", op:EQ, value:"ACTIVE"}},
        {_text: {field: "argos.patient.practiceId", op: IN, value: "${config.practiceOrg}"}}
    ]}
     `
    return `newPatient: argosPatientAndSurgeryResponse(
      ${this.getAllConfigString(config)}
      ${argString}
    ) {
      totalCount
    patients {
      firstName
      practiceId
      lastName
      birthDate
      gender
      mrn
      createdAt
      requestId
      surgeries {
        procedure
      }
  }}
    `;
  }

getExistingPatientsQuery(config: any) {
  let argString = ''
  argString += `where: {_and:  
  [
 {_text: {field: "argos.patient.existingPatientId", op: IS_NOT_EMPTY, value: ""}},
  {_text: {field: "argos.patient.status", op:EQ, value:"ACTIVE"}},
  {_text: {field: "argos.patient.practiceId", op: IN, value: "${config.practiceOrg}"}}
]}
`
 
  return `existingPatient: argosPatientAndSurgeryResponse(
    ${this.getAllConfigString(config)}
    ${argString}
  ) {
   totalCount
    patients {
      firstName
      practiceId
      lastName
      birthDate
      gender
      mrn
      createdAt
      requestId
      surgeries {
        procedure
      }
      existingPatient {
        birthDate
        practiceId
        firstName
        lastName
        gender
        mrn
        patientId
      }
    }
  }
  }`;
  }

  getImportedPatientByID(id: string) {
    let query = `query getImportedPatientByID {
        argosPatientAndSurgeryResponse ( where: {_text: {field: "argos.patient.requestId", op: EQ, value: "${id}"}}) {
          patients {
            practiceId
            firstName 
            middleName
            lastName
            birthDate
            gender
            mrn
            surgeries {
              ascId
              clinicId
              procedure
              surgeryDate
              eye
              lensPower
              lensModel
              lensType
              lensPriority
              iolManufacturer
              existingSurgeryId
              surgeonEmailId
              argosAsc {
                name
                practiceOrg
              }
              argosClinic {
                name
                practiceOrg
              }
              argosSurgeon {
                email
                firstName
                id
                lastName
              }
            }
          }
      }
    }`;

    const obj = {
      query: query,
      operationName: 'getImportedPatientByID',
    };
    const objStringFy = JSON.stringify(obj);
    return this.graphqlService.callgraphApi(objStringFy);
  }
}
