import { Injectable } from '@angular/core';
import { GraphqlService } from './graphql.service';
import { surgeryInput, surgeryTab, searchKeys, surgeryStatus, surgeryDocumentType, surgeryStatusFilter } from '../../model/surgery.model';
import { EyeSurgeryFilter } from 'src/app/model/EyeSurgeryFilter';
import { surgeryFilterInput } from 'src/app/model/surgeryFilterInput';
import { formatDate } from '@angular/common';
import { PreferenceFieldConfig, SurgeryFieldConfig, completeSurgeriesMaxLimit } from '../../constants/constants';
import { InORExperienceKeys } from 'src/app/pages/or-experience/constants/alcon-or-experience.constants';

@Injectable({
  providedIn: 'root'
})
export class SurgeryGraphqlService {

  isExport = false;
  constructor(private graphqlService: GraphqlService) { }
 
  getHomePageSurgeryCounts(config: surgeryInput, tabName: string){
    let query = `query SurgeryCountForDifferentTabs {
      unScheduled: surgeryResponse(
        ${this.getAllConfigString(config,tabName, surgeryTab.unScheduled)}
        where: {_and: [
          ${this.getSurgeryWhereQuery(config, surgeryTab.unScheduled)}
        ]}
      ){
        totalCount
      }
        incompleteSurgeries: surgeryResponse(
        where: {_and: [
          ${this.getIncompleteSurgeriesCondition(config.clinicId ?? '', config.ascId ?? '')}
        ]}
      ) {
        totalCount
      }
    }
      `;
      const obj = {
        query: query,
        operationName: 'SurgeryCountForDifferentTabs'
      }
      const objStringFy = JSON.stringify(
        obj
        );
      return this.graphqlService.callgraphApi(objStringFy);
    }

  getSurgerySummaryCounts(config: surgeryInput, tabName: string, filterData?:EyeSurgeryFilter, isExport?:boolean, form?:surgeryDocumentType | undefined, fieldPreferences?: PreferenceFieldConfig | undefined){
    let query = `query SurgeryCountForDifferentTabs {
      allSurgeries: surgeryResponse(
        ${this.getAllConfigString(config,tabName, surgeryTab.all)}
        where: {_and: [
          ${this.getSurgeryWhereQuery(config, surgeryTab.all)}
          ${this.filterDataConversion(filterData)}
        ]}
      ) {
        totalCount
      }
      iolSelectionPending: surgeryResponse(
        ${this.getAllConfigString(config, tabName, surgeryTab.iolPending)}
        where: {_and: [
          ${this.getSurgeryWhereQuery(config, surgeryTab.iolPending)}
          ${this.filterDataConversion(filterData)}
        ]}
      ) {
        totalCount
      }
      unScheduled: surgeryResponse(
        ${this.getAllConfigString(config,tabName, surgeryTab.unScheduled)}
        where: {_and: [
          ${this.getSurgeryWhereQuery(config, surgeryTab.unScheduled)}
          ${this.filterDataConversion(filterData)}
        ]}
      ) {
        totalCount
      }
      surgeryDetailsIncomplete: surgeryResponse(
        ${this.getAllConfigString(config,tabName, surgeryTab.surgeryIncomplete)}
        where: {_and: [
          ${this.getSurgeryWhereQuery(config, surgeryTab.surgeryIncomplete,  form, fieldPreferences)}
          ${this.filterDataConversion(filterData)}
          ]}
      ) {
        totalCount
      }
      patientInfoIncomplete: surgeryResponse(
        ${this.getAllConfigString(config,tabName, surgeryTab.patientInfoIncomplete)}
          where: {_and: [
            ${this.getSurgeryWhereQuery(config, surgeryTab.patientInfoIncomplete, form, fieldPreferences)}
            ${this.filterDataConversion(filterData)}
        ]}
        patientWhere: ${this.getPatientCondition(fieldPreferences?.patientFields)}
        ) {
          totalCount
        }
      missingForm: surgeryResponse(
        ${this.getAllConfigString(config, tabName, surgeryTab.missingForm)}
        where: {_and: [
          ${this.getSurgeryWhereQuery(config, surgeryTab.missingForm, form)}
          ${this.filterDataConversion(filterData)}
        ]}
      ) {
        totalCount
      }
      incompleteSurgeries: surgeryResponse(
        where: {_and: [
          ${this.getIncompleteSurgeriesCondition(config.clinicId ?? '', config.ascId ?? '')}
        ]}
      ) {
        totalCount
      }
      cancelledSurgeries: surgeryResponse(
        ${this.getAllConfigString(config, tabName, surgeryTab.cancelledSurgeries)}
        where: {_and: [
          ${this.getSurgeryWhereQuery(config, surgeryTab.cancelledSurgeries)}
          ${this.filterDataConversion(filterData)}
        ]}
      ) {
        totalCount
      }
      completedSurgeries: surgeryResponse(
        ${this.getAllConfigString(config, tabName, surgeryTab.completedSurgeries)}
        where: {_and: [
          ${this.getSurgeryWhereQuery(config, surgeryTab.completedSurgeries)}
          ${this.filterDataConversion(filterData)}
        ]}
      ) {
        totalCount
      }
    }
      `;


      const obj = {
        query: query,
        operationName: 'SurgeryCountForDifferentTabs'
      }
      const objStringFy = JSON.stringify(
        obj
        );
      return this.graphqlService.callgraphApi(objStringFy);
  }

  getPatientCondition(patientFields: string[] | undefined) {
    const conditionsArray: string[] = [];
    patientFields?.forEach(field => {
      conditionsArray.push(`{_text: {field: "patient.${field}", op: ISEMPTY, value: ""}}`);
    });
    let condition = conditionsArray.join(',');
    return `{_or: [${condition}]}`;
  }
  getSurgerySummary(config: surgeryInput, tabName: string, filterData?:EyeSurgeryFilter, isExport?:boolean, form?:surgeryDocumentType | undefined ,fieldPreferences?: PreferenceFieldConfig | undefined){
    this.isExport = isExport || false;
    let query = '';
    if(config.surgeryStatusFilter === surgeryStatusFilter.plannedSurgeries){
      const patientSearch = tabName === surgeryTab.patientInfoIncomplete ? `patientWhere: ${this.getPatientCondition(fieldPreferences?.patientFields)}` : ''; 
        query = `query SurgeryList {
          surgeries: surgeryResponse(
            ${this.getAllConfigString(config,tabName, tabName)}
            where: {_and: [
              ${this.getSurgeryWhereQuery(config, tabName, form, fieldPreferences)}
              ${this.filterDataConversion(filterData)}
            ]}
            ${patientSearch}
          ) {
            ${this.getFieldStringSurgery(tabName, tabName)}
          }
        }
      
        `;
    }else if(config.surgeryStatusFilter === surgeryStatusFilter.missingDOS){
      tabName = surgeryTab.unScheduled;
      query = `query SurgeryList {
        surgeries: surgeryResponse(
          ${this.getAllConfigString(config,tabName, tabName)}
          where: {_and: [
            ${this.getSurgeryWhereQuery(config, tabName)}
            ${this.filterDataConversion(filterData)}
          ]}
        ) {
          ${this.getFieldStringSurgery(tabName, tabName)}
        }
      }
    
    `;
    } else if(config.surgeryStatusFilter === surgeryStatusFilter.cancelledSurgery){
      tabName = surgeryTab.cancelledSurgeries;
      query = `query SurgeryList {
        surgeries: surgeryResponse(
          ${this.getAllConfigString(config,tabName, tabName)}
          where: {_and: [
            ${this.getSurgeryWhereQuery(config, tabName)}
            ${this.filterDataConversion(filterData)}
          ]}
        ) {
          ${this.getFieldStringSurgery(tabName, tabName)}
        }
      }
    
    `;
    } else if(config.surgeryStatusFilter === surgeryStatusFilter.completedSurgery) {
      tabName = surgeryTab.completedSurgeries;
      query = `query SurgeryList {
        surgeries: surgeryResponse(
          ${this.getAllConfigString(config,tabName, tabName)}
          where: {_and: [
            ${this.getSurgeryWhereQuery(config, tabName)}
            ${this.filterDataConversion(filterData)}
          ]}
        ) {
          ${this.getFieldStringSurgery(tabName, tabName)}
        }
      }
    
    `;
    }


      const obj = {
        query: query,
        operationName: 'SurgeryList'
      }
      const objStringFy = JSON.stringify(
        obj
        );
      return this.graphqlService.callgraphApi(objStringFy);
    }


    generatFiltereQuery(input: surgeryFilterInput[]): string {
      const result = input?.filter(item=>item.value?.length > 0).map((item) => {
        return `{_or: [${item.value.map((val:string) => {
          return `{_text: {field: "${item.type}", op: EQ, value: "${val}"}}`;
        }).join(', ')}]}`;
      }).join(', ');
  
      return  result;
    }
  
    filterDataConversion(filterData?:EyeSurgeryFilter){
      const procedure:surgeryFilterInput = {
        type:"surgery.surgeryData.procedure",
        value:[]
      }
      const eye:surgeryFilterInput = {
        type:"surgery.surgeryData.eye",
        value:[]
      }
  
      const ora:surgeryFilterInput = {
        type:"surgery.surgeryData.ora",
        value:[]
      }
  
      const waitlist:surgeryFilterInput = {
        type:"surgery.surgeryData.waitlistInterest",
        value:[]
      }
  
      const postopMeds:surgeryFilterInput = {
        type:"surgery.surgeryData.postopMeds",
        value:[]
      }

      const clinicId:surgeryFilterInput = {
        type:"surgery.surgeryData.clinicId",
        value:[]
      }

      const ascId:surgeryFilterInput = {
        type:"surgery.surgeryData.ascId",
        value:[]
      }

      const femto:surgeryFilterInput = {
        type:"surgery.surgeryData.lensx",
        value:[]
      }
  
  
      if(filterData?.cataractSurgery) procedure.value.push("Cataract Surgery");
      if(filterData?.otherSurgery) procedure.value.push("Other Surgery");
      if(filterData?.exchangeSurgery) procedure.value.push("Exchange Surgery");
      if(filterData?.leftEye) eye.value.push("L");
      if(filterData?.rightEye) eye.value.push("R");
      if(filterData?.oraYes) ora.value.push("true");
      if(filterData?.oraNo) ora.value.push("false");
      if(filterData?.waitlistNo) waitlist.value.push("false");
      if(filterData?.waitlistYes) waitlist.value.push("true");
      if(filterData?.routineDrops) postopMeds.value.push("Routine Drops");
      if(filterData?.comboDrops) postopMeds.value.push("Combo Drops");
      if(filterData?.clinicId) clinicId.value.push(filterData?.clinicId);
      if(filterData?.ascId) ascId.value.push(filterData?.ascId);
      if(filterData?.femtoYes) femto.value.push("true")
      if(filterData?.femtoNo) femto.value.push("false")
      let supplies = '{_or: [';
      if(filterData?.ctr) supplies+= `{_text: {field: "surgery.surgeryData.supplies.ctr", op: EQ, value: "true"}},`
      if(filterData?.gatt) supplies+= `{_text: {field: "surgery.surgeryData.supplies.gatt", op: EQ, value: "true"}},`
      if(filterData?.hydrus) supplies+=`{_text: {field: "surgery.surgeryData.supplies.hydrus", op: EQ, value: "true"}},`
      if(filterData?.istent) supplies+=`{_text: {field: "surgery.surgeryData.supplies.istent", op: EQ, value: "true"}},`
      if(filterData?.irisHooks) supplies+=`{_text: {field: "surgery.surgeryData.supplies.irisHooks", op: EQ, value: "true"}},`
      if(filterData?.kdb) supplies+=`{_text: {field: "surgery.surgeryData.supplies.kdb", op: EQ, value: "true"}},`
      if(filterData?.malyuginRings) supplies+=`{_text: {field: "surgery.surgeryData.supplies.malyuginRings", op: EQ, value: "true"}},`
      if(filterData?.omni) supplies+=`{_text: {field: "surgery.surgeryData.supplies.omni", op: EQ, value: "true"}},`
      if(filterData?.trypanBlue) supplies+=`{_text: {field: "surgery.surgeryData.supplies.trypanBlue", op: EQ, value: "true"}},`
      if(filterData?.other) supplies+=`{_text: {field: "surgery.surgeryData.supplies.other", op: EQ, value: "true"}},`
      supplies+=']}'

      let postOpMeds = '';
      if(filterData?.postopMeds?.length){
        postOpMeds += '{_or: [';
        filterData?.postopMeds?.forEach( item => {
          postOpMeds += `{_text: {field: "surgery.surgeryData.postopMeds", op: EQ, value: "${item}"}},`
        });
        postOpMeds += ']}';
      }

  
      const filterDetails = [procedure,waitlist,ora,eye,postopMeds, clinicId, ascId, femto]
  
      let filterQuery = this.generatFiltereQuery(filterDetails);
      if(postOpMeds) {
        filterQuery += "," + postOpMeds;
      }
      if(supplies !=="{_or: []}"){
        return filterQuery + ","+supplies;
      }else{
        return filterQuery;
      }
  
  
    }

    getTodaysDate(){
      let today= new Date();
      today.setHours(0,0,0,0);
      return formatDate(today, 'yyyy-MM-dd', 'en-US');
    }

    getNextYearDate(){
      let today= new Date();
      today.setHours(0,0,0,0);
      const nextYearDate = today.setFullYear(today.getFullYear() + 1);
      return formatDate(nextYearDate, 'yyyy-MM-dd', 'en-US');
    }
    getSurgeryWhereQuery(config: surgeryInput, type: string, form?: surgeryDocumentType | undefined, preferenceFieldConfig?: PreferenceFieldConfig | undefined){
      let whereString = "";
      if(type !== surgeryTab.cancelledSurgeries && type !== surgeryTab.completedSurgeries) {
        whereString = `{_text: {field: "surgery.surgeryData.status", op: NIN, value: "${surgeryStatus.COMPLETED}, ${surgeryStatus.CANCELLED}"}},`;
      }
      const today = this.getTodaysDate();
      if(type != surgeryTab.unScheduled){
        let start_date = config.startDate;
        if(config.startDate){
          if(config.startDate < today && type != surgeryTab.all && type!= surgeryTab.cancelledSurgeries && type != surgeryTab.completedSurgeries){
            start_date = today;
          }
          whereString += `{_date: {field: "surgery.surgeryData.surgeryDate", op: GTE, value: "${start_date}"}},`
        }
        if(config.endDate){
          whereString += `{_date: {field: "surgery.surgeryData.surgeryDate", op: LTE, value: "${config.endDate}"}},`
        }
        if(!config.startDate && !config.endDate && type){
          let date_query = `{_and: [
            {_date: {field: "surgery.surgeryData.surgeryDate", op: GTE, value: "${today}"}},
            {_date: {field: "surgery.surgeryData.surgeryDate", op: LTE, value: "${this.getNextYearDate()}"}}
          ]}`
          if(type == surgeryTab.cancelledSurgeries) {
            whereString += `{_or: [
              ${date_query},
              {_and: [
                {_date: {field: "surgery.surgeryData.surgeryDate", op: IS_NULL, value: ""}}
              ]},
            ]},`
          } else {
            whereString += `${date_query},`
          }       
        }
      }
      if(!type) {
        whereString += `{_and: [
          {_date: {field: "surgery.surgeryData.surgeryDate", op: LTE, value: "${today}"}}
        ]},`
      }
      if(config.clinicId){
        whereString += `{_text: {field: "surgery.surgeryData.clinicId", op: IN, value: "${config.clinicId}"}},`
      }
      if(config.ascId){
        whereString += `{_text: {field: "surgery.surgeryData.ascId", op: IN, value: "${config.ascId}"}},`
      }

      if(type === surgeryTab.iolPending){
        whereString += `{_or: [
          {_and: [
            {_array: {field: "surgery.surgeryData.iols", op: IS_EMPTY}},
            {_text: {field: "surgery.surgeryData.procedure", op: IN, value: "Cataract Surgery, Exchange Surgery"}}
          ]},
          {_and: [
            {_array: {field: "surgery.surgeryData.iols", op: EXISTS, innerWhere:{
              _and : [
                {_text:{field: "surgery.surgeryData.iols.lensPriority", op:EQ, value:"PRI"}},
                {_or : [
                  {_text:{field: "surgery.surgeryData.iols.diopter", op:ISEMPTY, value:""}},
                  {_text:{field: "surgery.surgeryData.iols.lensModel", op:ISEMPTY, value:""}}
                ]}
              ]
            }}},
            {_text: {field: "surgery.surgeryData.procedure", op: EQ, value: "Cataract Surgery"}}
          ]}
        ]}`
      }
      if (type === surgeryTab.surgeryIncomplete) {
        let conditionIncompleteArray: string[] = [];
        preferenceFieldConfig?.surgeryFields?.forEach((configItem) => {
          let subConditionIncompleteArray: string[] = [];
          if(configItem?.requiredFields?.length) {
            configItem.requiredFields.forEach(field => {
              subConditionIncompleteArray.push(`{_text: {field: "surgery.surgeryData.${field}", op: EQ, value: ""}}`);
            });
          }  else {
            subConditionIncompleteArray.push(`{_text: {field: "surgery.surgeryData.procedure", op: EQ, value: ""}}`);
          }
          
          let subConditionIncomplete = subConditionIncompleteArray.join(',');
          conditionIncompleteArray.push(`
            {_and: [
              {_text: {field: "surgery.surgeryData.procedure", op: EQ, value: "${configItem.procedure}"}},
              {_or: [${subConditionIncomplete}]} 
            ]}
          `);
        });
        let conditionIncomplete = `{_or: [${conditionIncompleteArray.join(',')}]}`;
        whereString += conditionIncomplete;
      }

          if(type === surgeryTab.missingForm && form == surgeryDocumentType.consentForm){
            whereString += `{_and: [
              {_text: {field: "surgery.surgeryData.consentForm.status", op: EQ, value: "Incomplete"}}
            ]}`;
          }
          if(type === surgeryTab.missingForm && form == surgeryDocumentType.preVerification){
            whereString += `{_and: [
              {_text: {field: "surgery.surgeryData.preVerificationForm", op: ISEMPTY, value: ""}}
            ]}`;
          }
          if(type === surgeryTab.unScheduled){
            whereString += `{_date: {field: "surgery.surgeryData.surgeryDate", op: IS_NULL, value: ""}}`;
          }
          if(type === surgeryTab.cancelledSurgeries) {
            whereString +=  `{_text: {field: "surgery.surgeryData.status", op: EQ, value: "Cancel"}}`;
          }
          if(type === surgeryTab.completedSurgeries) {
            whereString +=  `{_text: {field: "surgery.surgeryData.status", op: EQ, value: "Completed"}}`;
          }
          if(config?.searchKey !== undefined && config.searchKey){
            whereString += this.getSearchString(config.searchKey);
          }     
          return whereString;
    }

    getExportField(){
      return `
      totalCount
      surgeries {
        surgeryId
        patient {
          firstName
          createdFacility{
            name
            practiceOrg
          }
          associatedMrnMappings {
            associatedMrn
            associatedPracticeId
            createdAt
            createdBy
            parentPracticeId
            patientId
            updatedAt
            updatedBy
          }
          lastName
          patientId
          birthDate
          practiceId
          mrn
          gender
          phone
          createdAt
          updatedAt
          languageDisplay
          additionalInfo {
            allergyOther
            diabetesStatus
            alternateContactName
            alternatePhoneNumber
            comments
            patientTimeOfDayPreference
            translatorNeeded
            applicationMeta {
              srcSystemInformation {
                createdSrcSystem
                createdSrcSystemType
                createdAt
                lastUpdateSrcInformation {
                  lastUpdatedSrcSystem
                  lastUpdatedSrcSystemType
                  lastUpdateAt
                }
              }
            }
          }
          middleName
          prefix
          email
        }
        surgeryData {
          ascId
          preVerificationForm  {
            createdAt
            createdBy
          }
          ascSapId
          procedure
          clearance
          clinicId
          surgeryDate
          surgeryName
          surgerySite
          createdAt
          updatedAt
          eye
          setFor
          postopMeds
          otherMeds
          lensx
          ora
          otherSupplies
          surgeonId
          practiceOrg
          applicationMeta {
            documents {
              documentId
            }
          }
          supplies {
            istent
            hydrus
            trypanBlue
            malyuginRings
            omni
            kdb
            gatt
            ctr
            irisHooks
            other
          }
          consentForm {
            status
            referenceId
          }
          includeSecondPrimary
          iols {
            diopter
            iolManufacturer
            lensPriority
            lensModel
            lensType
            implantationAxis
          }
          status
          complexity
          clearance
          firstOrSecondEye
          surgeryComment
          generalAnesthesia
          waitlistInterest
          practiceOrg
        }
        surgeon {
          firstName
          lastName
          id
          prefix
        }
          iolSurgeonPreferences {
              brand
              diopter
              lensPreference
              lensType
              manufacturer
              model
              implantationAxis
            }
        asc {
          name
          practiceOrg
        }
        clinic {
          name
        }
        surgeryId
        surgeonId
      }`;
    }
    getFieldStringSurgery(selectedTab: string, currentTab: string){
  
        if(selectedTab !== currentTab){
          return `totalCount`;
        }
        if(this.isExport){
          return this.getExportField();
        }

      return `
      totalCount
      surgeries {
        surgeryId
        updatedAt
        patient {
          firstName
          lastName
          email
          prefix
          middleName
          createdFacility{
            name
            practiceOrg
          }
          additionalInfo {
            patientTimeOfDayPreference
            allergyOther
            signLanguage
            alternatePhoneNumber
            alternateContactName
          }
          associatedMrnMappings {
            associatedMrn
            associatedPracticeId
            createdAt
            createdBy
            parentPracticeId
            patientId
            updatedAt
            updatedBy
          }
          patientId
          birthDate
          practiceId
          mrn
          gender
          phone
          createdAt
          updatedAt
          languageDisplay
          additionalInfo {
            comments
          }

        }
         iolSurgeonPreferences {
           brand
           diopter
           lensPreference
           lensType
           manufacturer
           model
           implantationAxis
          }
        surgeryData {
          ascId
          preVerificationForm  {
            createdAt
            createdBy
          }
          clinicId
          procedure
          clearance
          surgeryDate
          surgeryName
          surgerySite
          createdAt
          updatedAt
          eye
          setFor
          postopMeds
          otherMeds
          lensx
          ora
          otherSupplies
          surgeonId
          applicationMeta {
            documents {
              documentId
            }
          }
          supplies {
            istent
            hydrus
            trypanBlue
            malyuginRings
            omni
            kdb
            gatt
            ctr
            irisHooks
            other
          }
          consentForm {
            status
            referenceId
          }
          includeSecondPrimary
          iols {
            diopter
            iolManufacturer
            lensPriority
            lensModel
            lensType
            implantationAxis
          }
          status
          complexity
          firstOrSecondEye
          surgeryComment
          practiceOrg
        }
        surgeon {
          firstName
          lastName
          id
          prefix
        } 
        asc {
          name
          practiceOrg
        }
        clinic {
          name
          practiceOrg
        }
        surgeryId
        surgeonId
      }`;
    }

  getLimitOffsetString(config: surgeryInput){
    if(this.isExport){
      return '';
    }
    let argString = `limit: 10                          
                     offset: 0`;
    
    if(config?.pagination?.limit !== undefined && config.pagination.limit){
      argString  = "limit: " + config.pagination.limit + " "
    }
 
    if(config?.pagination?.offset !== undefined && config.pagination.offset){
      argString += "offset: " + config.pagination.offset + " "
    }
    return argString;
  }

  getSortString(config: surgeryInput){
    switch (config?.sort?.sortField){
      case 'name':
        return  `sort: {direction: ${config.sort?.sortBy}, field: "patient.lastName"}`;
      case 'dos':
        return  `sort: {direction: ${config.sort?.sortBy}, field: "surgery.surgeryData.surgeryDate, patient.lastName"}`;
      case 'procedure':
        return  `sort: {direction: ${config.sort?.sortBy}, field: "surgery.surgeryData.procedure,patient.lastName"}`;
      case 'dob':
         return  `sort: {direction: ${config.sort?.sortBy}, field: "patient.birthDate, patient.lastName"}`; 
      default:
        return `sort: {direction: ASC, field: "surgery.surgeryData.surgeryDate, patient.lastName"}`;
    }
}

getSearchString(searchText: searchKeys){
  let searchstring = '';
  if(searchText.patient){
    searchstring += `{_or: [
      {_text: {field: "patient.firstName", op: ILIKE, value: "${searchText.patient.trim()}"}},
      {_text: {field: "patient.lastName", op: ILIKE, value: "${searchText.patient.trim()}"}}]}`;
  }
  if(searchText.surgeon){
    searchstring += `{_or: [
      {_text: {field: "surgery.surgeryData.surgeonId", op: IN, value: "${searchText.surgeon}"}}]}`;
  }

    return searchstring;

}

  getAllConfigString(config: surgeryInput,selectedTab: string, currentTab: string){
    if(selectedTab === currentTab){
      const sortString = this.getSortString(config);
      const pageString = this.getLimitOffsetString(config);
      return sortString + (sortString.length ? ', ' + pageString : pageString)
    }
    return '';
  }

  getSurgeryDetails(surgeryId: string) {
    let query = `query SurgeryDetailsById {
      surgeryResponse(
        where: {_text: {field: "surgery.surgeryId", op: EQ, value: "${surgeryId}"}}
      ) {
        surgeries {
          asc {
            name
            practiceOrg
          }
          clinic {
            name
            practiceOrg
          }
          surgeryData {
            inORExperience {
                ${InORExperienceKeys.EnteredORDttm}
              }
            iols {
              diopter
              iolManufacturer
              implantationAxis
              lensPriority
              lensModel
              lensType
              brand
            }
            preVerificationForm  {
              createdAt
              createdBy
            }
            includeSecondPrimary
            ascId
            ascSapId
            clinicId
            complexity
            lensx
            ora
            otherSupplies
            patientId
            procedure
            setFor
            status
            practiceOrg
            operationRoom {
              roomId
            }
            applicationMeta {
              documents {
                documentId
              }
            }
            supplies {
              ctr
              hydrus
              irisHooks
              gatt
              istent
              kdb
              malyuginRings
              omni
              other
              trypanBlue
            }
            surgeryDate
            surgeryName
            surgerySite
            updatedAt
            waitlistInterest
            surgeryComment
            surgeryId
            generalAnesthesia
            internalNotes
            firstOrSecondEye
            eye
            postopMeds
            otherMeds
            clearance
            consentForm {
              status
            }
            surgeonId
          }
          iolSurgeonPreferences {
              brand
              diopter
              lensPreference
              lensType
              manufacturer
              model
            }
          surgeon {
            firstName
            lastName
            email
            id
            prefix
          }
          createdBy
          createdAt
        }
      }
    } `;
  
      const obj = {
        query: query,
        operationName: 'SurgeryDetailsById'
      }
      const objStringFy = JSON.stringify(
        obj
        );
      return this.graphqlService.callgraphApi(objStringFy);
    }
  
  getIncompleteSurgeriesCondition(clinicId: string, ascId: string) {
    const today = this.getTodaysDate();
    return ` {_text: {field: "surgery.surgeryData.status", op: IN, value: "${surgeryStatus.READY}"}}, 
    {_date: {field: "surgery.surgeryData.surgeryDate", op: LTE, value: "${today}"}},
    {_text: {field: "surgery.surgeryData.clinicId", op: IN, value: "${clinicId}"}},
    {_text: {field: "surgery.surgeryData.ascId", op: IN, value: "${ascId}"}}`;
  } 

  getTotalCountSurgery(clinicId: string, ascId: string){
      let query = `query totalSurgeryCount {
        surgeryResponse(
          where: {_and: [
            {_text: {field: "surgery.surgeryData.clinicId", op: IN, value: "${clinicId}"}},
            {_text: {field: "surgery.surgeryData.ascId", op: IN, value: "${ascId}"}}
          ]})
          {
            totalCount
          }
       }`
       const obj = {
        query: query,
        operationName: 'totalSurgeryCount'
        }
        const objStringFy = JSON.stringify(
        obj
        );
        return this.graphqlService.callgraphApi(objStringFy);
  }

  getIncompleteSurgeries(clinicId: string, ascId: string) {
    let query = `query IncompleteSurgeries {
      surgeryResponse(
        limit: ${completeSurgeriesMaxLimit}
        sort: {direction: ASC, field: "surgery.surgeryData.surgeryDate, patient.lastName"}
        where: {_and: [ 
          ${this.getIncompleteSurgeriesCondition(clinicId, ascId)}
        ]}
        ) {
        totalCount
        surgeries {
          surgeryId
          surgeonId 
          surgeryData {
            surgeonId
            procedure
            preVerificationForm  {
              createdAt
              createdBy
            }
            surgeryDate
            eye
            practiceOrg
            ascId
            clinicId
          }
          patient {
            firstName
            lastName
            patientId
            practiceId
          }
          surgeon {
            firstName
            lastName
            prefix
            id
          }
        }
      }
    } `;

    const obj = {
    query: query,
    operationName: 'IncompleteSurgeries'
    }
    const objStringFy = JSON.stringify(
    obj
    );
    return this.graphqlService.callgraphApi(objStringFy);
  }

  getIncompleteSurgeriesCount(clinicId: string, ascId: string) {
    let query = `query IncompleteSurgeries {
      surgeryResponse(
        where: {_and: [ 
          ${this.getIncompleteSurgeriesCondition(clinicId, ascId)}
        ]}
        ) {
        totalCount
      }
    } `;

    const obj = {
    query: query,
    operationName: 'IncompleteSurgeries'
    }
    const objStringFy = JSON.stringify(
    obj
    );
    return this.graphqlService.callgraphApi(objStringFy);
  }

  getSurgeriesByRoomIdCount(roomId: string) {
    let query = `query SurgeriesByRoomId {
      surgeryResponse(
        where: {
          _and: [{
              _or: [ 
                {_text: {field: "surgery.surgeryData.operationRoom.roomId", op: ILIKE, value: "${roomId}"}},
                {_text: {field: "surgery.surgeryData.procedureRoom.roomId", op: ILIKE, value: "${roomId}"}}
              ]}
              {_date: {field: "surgery.surgeryData.surgeryDate",op: GTE, value: "${this.getTodaysDate()}" }}
          ]} 
        ) {
        totalCount
      }
    } `;

    const obj = {
    query: query,
    operationName: 'SurgeriesByRoomId'
    }
    const objStringFy = JSON.stringify(
    obj
    );
    return this.graphqlService.callgraphApi(objStringFy);
  }

  getSurgeryDetailsforOR(surgeryId: string) {
    let query = `query SurgeryDetailsById {
      surgeryResponse(
        where: {_text: {field: "surgery.surgeryId", op: EQ, value: "${surgeryId}"}}
      ) {
        surgeries {
          asc {
            name
          }
          patient {
            birthDate
            firstName
            lastName
            gender
            mrn
            practiceId
            additionalInfo {
              diabetesStatus
              allergyOther
              comments
            }
          }
          surgeryData {
            inORExperience {
              ${InORExperienceKeys.EnteredORDttm}
              ${InORExperienceKeys.ExitedORDttm}
              ${InORExperienceKeys.SurgeryEndDttm}
              ${InORExperienceKeys.SurgeryStartDttm}
              ${InORExperienceKeys.TimeOutDttm}
            }
            iols {
              diopter
              iolManufacturer
              lensPriority
              lensModel
              lensType
              implantationAxis
              brand
            }
            surgeryName
            surgerySite
            surgeryDate
            ascId
            ascSapId
            clinicId
            lensx
            ora
            patientId
            procedure
            setFor
            status
            practiceOrg
            surgeryComment
            surgeryId
            firstOrSecondEye
            eye
            postopMeds
            otherMeds
            surgeonId
          }
          iolSurgeonPreferences {
              brand
              diopter
              lensPreference
              lensType
              manufacturer
              model
              implantationAxis
            }
          surgeon {
            firstName
            lastName
            email
            id
            prefix
          }
          createdBy
          createdAt
        }
      }
    } `;
  
      const obj = {
        query: query,
        operationName: 'SurgeryDetailsById'
      }
      const objStringFy = JSON.stringify(
        obj
        );
      return this.graphqlService.callgraphApi(objStringFy);
    }
}
