import { Injectable } from '@angular/core';
import { patientSurgery } from '../../patient/patient-details/patient-details.model';
import { addEditSurgeryForm, eyePosition, surgeryStatus, surgeryType, surgeryTypeRes, surgeryTypeShort } from 'src/app/shared/model/surgery.model';
import { MAX_CATARACT_LEFT_SURGERY_PER_PATIENT, MAX_CATARACT_RIGHT_SURGERY_PER_PATIENT, MAX_CATARACT_SURGERY_PER_PATIENT, MAX_EXCHANGE_LEFT_SURGERY_PER_PATIENT, MAX_EXCHANGE_RIGHT_SURGERY_PER_PATIENT, MAX_EXCHANGE_SURGERY_PER_PATIENT } from '../constants/patient-surgery-add-edit.constants';
import { eyeList } from 'src/app/shared/constants/surgery.constants';
import { SurgeryService } from 'src/app/shared/surgery.service';
import { MatDialog } from '@angular/material/dialog';
import { SurgeryIolspecsDuplicateDialogComponent } from 'src/app/components/surgery-iolspecs-duplicate-dialog/surgery-iolspecs-duplicate-dialog.component';
import { UtilityService } from 'src/app/shared/service/utility.service';

@Injectable({
  providedIn: 'root'
})
export class PatientSurgeryAddEditUtilityService {

  constructor(
    public surgeryService: SurgeryService,
    public dialog: MatDialog,
    private utilityService: UtilityService
  ) { }

  public getAllowedProcedures(procedureList: surgeryTypeRes[], surgeryData?: patientSurgery[], currentSurgeryId?: string) {
    if (!surgeryData?.length) {
      return procedureList;
    }

    const allowedProcedureList: surgeryTypeRes[] = [];
    procedureList.forEach(item => {
      if (item.name == surgeryTypeShort.catract) {
        if (this.isCreateCataractSurgeryAllowed(surgeryData, currentSurgeryId)) {
          allowedProcedureList.push(item)
        }
      } else if (item.name == surgeryTypeShort.exchange) {
        if (this.isCreateExchangeSurgeryAllowed(surgeryData, currentSurgeryId)) {
          allowedProcedureList.push(item)
        }
      } else {
        allowedProcedureList.push(item);
      }
    })

    return allowedProcedureList;
  }

  private isCreateCataractSurgeryAllowed(surgeryData: patientSurgery[], currentSurgeryId?: string) {
    const validCataractSurgeries = surgeryData.filter(surgery =>
      surgery.surgeryId !== currentSurgeryId
      && surgery.surgeryData.status !== surgeryStatus.CANCELLED
      && surgery.surgeryData.procedure === surgeryType.catract
    );
    return validCataractSurgeries.length < MAX_CATARACT_SURGERY_PER_PATIENT;
  }

  private isCreateExchangeSurgeryAllowed(surgeryData: patientSurgery[], currentSurgeryId?: string) {
    const validExchangeSurgeries = surgeryData.filter(surgery =>
      surgery.surgeryId !== currentSurgeryId
      && surgery.surgeryData.status !== surgeryStatus.CANCELLED
      && surgery.surgeryData.procedure === surgeryType.exchange
    );
    return validExchangeSurgeries.length < MAX_EXCHANGE_SURGERY_PER_PATIENT;
  }

  isDuplicateExchangeSurgeryExists(surgeryForm: addEditSurgeryForm, surgeryData?: patientSurgery[], currentSurgeryId?: string){
    const validExcahngeSurgeries = surgeryData?.filter(surgery =>
      surgery.surgeryId !== currentSurgeryId
      && surgery.surgeryData.status !== surgeryStatus.CANCELLED
      && surgery.surgeryData.procedure === surgeryType.exchange
    );
    let existSurgery = validExcahngeSurgeries?.filter(obj => 
      (obj.surgeryData.eye === surgeryForm.eye && surgeryForm.surgeryDate && this.utilityService.toTestDateEqual(new Date(obj.surgeryData.surgeryDate), surgeryForm.surgeryDate))
      );
      if(existSurgery && existSurgery.length > 0){
        return true;
      }
      return false;
  }

  public getAllowedEyeList(selectedProcedure: surgeryType, surgeryData?: patientSurgery[], currentSurgeryId?: string) {
    if (!surgeryData?.length) {
      return eyeList;
    }

    if (selectedProcedure == surgeryType.catract) {
      return this.getAllowedEyesForCataractSurgery(surgeryData, currentSurgeryId);
    } else if (selectedProcedure == surgeryType.exchange) {
      return this.getAllowedEyesForExchangeSurgery(surgeryData, currentSurgeryId);
    } else {
      return [];
    }
  }

  private getAllowedEyesForCataractSurgery(surgeryData: patientSurgery[], currentSurgeryId?: string) {
    const existingCataractSurgeries = surgeryData.filter(surgery => 
      surgery.surgeryId !== currentSurgeryId 
      && surgery.surgeryData.procedure == surgeryType.catract
      && surgery.surgeryData.status !== surgeryStatus.CANCELLED
    )
    const existingLeftCataractSurgery = existingCataractSurgeries.filter( surgery => surgery.surgeryData.eye == eyePosition.left);
    const existingRightCataractSurgery = existingCataractSurgeries.filter( surgery => surgery.surgeryData.eye == eyePosition.right);
    const allowedList:any = [];
    eyeList.forEach(eye => {
      if(eye.value === eyePosition.left && existingLeftCataractSurgery.length < MAX_CATARACT_LEFT_SURGERY_PER_PATIENT){
        allowedList.push(eye)
      }else if(eye.value === eyePosition.right && existingRightCataractSurgery.length < MAX_CATARACT_RIGHT_SURGERY_PER_PATIENT){
        allowedList.push(eye)
      }
    })
    return allowedList;
  }

  private getAllowedEyesForExchangeSurgery(surgeryData: patientSurgery[], currentSurgeryId?: string) {
    const existingExchangeSurgeries = surgeryData.filter(surgery => 
      surgery.surgeryId !== currentSurgeryId 
      && surgery.surgeryData.procedure == surgeryType.exchange
      && surgery.surgeryData.status !== surgeryStatus.CANCELLED
    )
    const existingLeftExchangeSurgeries = existingExchangeSurgeries.filter( surgery => surgery.surgeryData.eye == eyePosition.left);
    const existingRightExchangeSurgeries = existingExchangeSurgeries.filter( surgery => surgery.surgeryData.eye == eyePosition.right);
    const allowedList:any = [];
    eyeList.forEach(eye => {
      if(eye.value === eyePosition.left && existingLeftExchangeSurgeries.length < MAX_EXCHANGE_LEFT_SURGERY_PER_PATIENT){
        allowedList.push(eye)
      }else if(eye.value === eyePosition.right && existingRightExchangeSurgeries.length < MAX_EXCHANGE_RIGHT_SURGERY_PER_PATIENT){
        allowedList.push(eye)
      }
    })
    return allowedList;
  }

  showDuplicateDialog(){
    return new Promise((resolve, reject)=>{
    const dialogRef = this.dialog.open(
      SurgeryIolspecsDuplicateDialogComponent,
      {
        hasBackdrop: true,
        backdropClass: 'dialog-backdrop',
        panelClass: 'alc_add_patient_dialog',
        disableClose: true,
        data: { title: '' },
      }
    );
    dialogRef.afterClosed().subscribe((selectedOption) => {
      if (selectedOption == 'yes') {
        resolve(true);
      } else {
        reject();
      }
    })
  });
}
}
