import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-view-only-access-indicator',
  standalone: true,
  imports: [MatIconModule, MatTooltipModule],
  templateUrl: './view-only-access-indicator.component.html',
  styleUrl: './view-only-access-indicator.component.scss'
})
export class ViewOnlyAccessIndicatorComponent {
  activeTooltip: MatTooltip | undefined;
  toggleTooltip(event: Event, tooltip: MatTooltip) {
    this.activeTooltip = tooltip;
    event.stopPropagation();
    tooltip.toggle();
  }
}
