import { patientDetails, prefillSurgeryData } from "../../patient/patient-details/patient-details.model"

export interface managePatientConfig {
    action: patientDataAction,
    origin: addEditRequestOrigin,
    patientId?: string,
    surgeryId?: string,
    patientData?: patientDetails,
    prefillSurgeryData?: prefillSurgeryData,
    requestId?: string
}

export enum patientDataAction{
    addPatient = 1,
    editSurgery,
    addSurgeryToExistingPatient,
    patientPageEditSurgery,
    patientPageCloneSurgery,
    patientPageAddSurgeryToPatient,
    patientPageEditPatient,
    patientPageAddIol,
    importPatientAndSurgery
}

export enum addEditRequestOrigin{
    surgeriesDashboard = 1,
    homePage,
    patientPage,
    addPatientFunction,
    patientDashboard,
    patientImport
}

export interface fieldConfig {
    id: string,
    isMandatory?: boolean,
    isHidden?: boolean,
}

export interface addEditTab{
    isShowPatient?: boolean,
    isShowSurgery?: boolean,
    isShowIol?: boolean
}

export enum addEditResumeMode{
    addSurgery = 1,
    editSurgery,
    addIol,
    editIol
}

export interface patientAddEditButtonConfig{
    saveAndClose?: boolean,
    addSurgeryDetails?: boolean,
    editSurgeryDetails?: boolean,
    saveChanges?: boolean
    reviewSurgeryDetails?: boolean,
    importToClinicConnect?:boolean
}

export interface surgeryAddEditButtonConfig{
    cancelSurgery?: boolean,
    saveAndClose?: boolean,
    addIolSpecs?: boolean,
    editIolSpecs?: boolean,
    saveAndFinish?: boolean,
    clone?: boolean,
    reviewIol?: boolean,
    importToClinicConnect?:boolean
}

export interface iolAddEditButtonConfig{
    saveAndClose?: boolean,
    saveAndFinish?: boolean,
    importToClinicConnect?:boolean,
    cancelSurgery?: boolean,
}