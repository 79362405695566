import { hiddenPayload } from "../pages/iol-model-preferences/iol-model-preferences.model";
import { preferenceTypes } from "../shared/constants/constants";
import { timeSettings } from "../shared/service/alcon-asc-settings.service";

// Enums
export enum AdminPatientSurgeryFormName {
  patient = 'Patient information',
  surgery = 'Surgery information',
  iolSpecs = 'IOL specifications'
}

export enum SurgeryTypesLabel {
  all = 'All surgeries',
  catract = 'Cataract surgery',
  exchange = 'Exchange surgery',
  other = 'Other surgery',
}

export enum fieldPreferenceModelTitles {
  SurgeryInformation = 'Surgery information',
  IOLSpecifications = 'IOL specs',
  PatientInformation = 'Patient information',
}

export enum requiredOrOptional {
  optional = 'Optional',
  requiredForSurgery = 'RequiredForSurgery',
  Mandatory = "Mandatory",
  requiredForSurgeryLabel = 'Required for surgery'
}

// Interfaces
export interface AdminPatientSurgeryField {
  label: string;
  placeholder: string;
  value?: requiredOrOptional;
  apiFieldName?: string;
}

export interface AdminPatientSurgeryFormFields {
  systemFields: AdminPatientSurgeryField[];
  nonSystemFields: AdminPatientSurgeryField[];
}

export interface PatientSurgeryUIFormObject {
  form: string;
  modalTitle?: string;
  surgeryType: string;
  systemFields: string[];
  requiredForSurgeryFields: string[];
  totalFields: number;
  apiPreferenceType: string;
}

interface AdminFormSurgeryMapper {
  formName: AdminPatientSurgeryFormName;
  surgeryType: string;
  modalTitle?: string;
  systemFields?: string[],
  totalFields: number;
}

export interface FormFieldPreferences {
  preferenceValue: requiredOrOptional;
  fieldsList: string[];
  totalFieldsCount: number;
  preferenceType: preferenceTypes;
}

export interface AdminPatientSurgeryResponseObject {
  practiceOrg: string;
  practiceType: string;
  preferenceType: string;
  hiddenIOLs: hiddenPayload[] | null,
  timeSettings: timeSettings | null,
  fieldPreferences: FormFieldPreferences[];
}

// Constants and Mappings
export const PatientSystemFormFields = ['firstName', 'lastName', 'dateOfBirth', 'gender', 'patientId', 'diabetic', 'translatorNeeded']
export const PatientFormFields: { [key: string]: AdminPatientSurgeryField } = {
  'firstName': {
    label: "First name",
    placeholder: "Input text Eg. John"
  },
  'lastName': {
    label: "Last name",
    placeholder: "Input text Eg. Ramos"
  },
  'dateOfBirth': {
    label: "Date of birth",
    placeholder: "Date Picker Eg. mm/dd/yyyy"
  },
  'gender': {
    label: "Gender",
    placeholder: "Dropdown Eg. Male"
  },
  'patientId': {
    label: "Patient ID",
    placeholder: "Input text Eg. XXXXXXXXX"
  },
  'diabetic': {
    label: "Diabetic",
    placeholder: "Toggle Eg. Yes/No"
  },
  'translatorNeeded': {
    label: "Translator needed",
    placeholder: "Toggle Eg. Yes/No"
  },
  'title': {
    label: "Title",
    placeholder: "Dropdown Eg. Mr"
  },
  'middleName': {
    label: "Middle name",
    placeholder: "Input text Eg. John"
  },
  'patientPhoneNumber': {
    label: "Patient Phone Number",
    placeholder: "Input text Eg. (+51) 947 444 713"
  },
  'email': {
    label: "Patient Email",
    placeholder: "Input text Eg. jhon@gmail.com"
  },
  'timeOfDayPreference': {
    label: "Time of day preference",
    placeholder: "Dropdown Eg. Early"
  },
  'allergies': {
    label: "Allergies",
    placeholder: "Text Eg. Vicodin"
  },
  'preferredLanguage': {
    label: "Preferred language if other than English",
    placeholder: "Input text Eg. Spanish"
  },
  'signLanguageInterpreter': {
    label: "Sign language interpreter",
    placeholder: "Dropdown Eg. Not needed"
  },
  'patientComments': {
    label: "Patient comments",
    placeholder: "Text Eg. Vicodin"
  },
  'alternatePhoneNumber': {
    label: "Alternate Phone Number",
    placeholder: "Input text Eg. (+51) 947 444 713"
  },
  'alternateContactName': {
    label: "Alternate Contact Name",
    placeholder: "Input text Eg. John"
  }
};
export const SurgeryCatSystemFormFields = ['ascClinic','surgeon','surgeryType','eye','waitlist?','generalAnesthesia','ora','femto'];
export const SurgeryFormCatFields: { [key: string]: AdminPatientSurgeryField } = {
    'ascClinic': {
        label: "ASC/Clinic",
        placeholder: "Dropdown · Eg. ASC Name"
    },
    'surgeon': {
        label: "Surgeon",
        placeholder: "Dropdown · Eg. Surgeon Name"
    },
    'surgeryType': {
        label: "Surgery type",
        placeholder: "Dropdown · Eg. Cataract"
    },
    'eye': {
        label: "Eye",
        placeholder: "Dropdown · Eg. Left"
    },
    'surgeryDate': {
        label: "Surgery date",
        placeholder: "Date Picker · Eg. mm/dd/yyyy"
    },
    'caseComplexity': {
        label: "Case Complexity",
        placeholder: "Dropdown · Eg. Standard"
    },
    'medicalClearance': {
        label: "Medical clearance",
        placeholder: "Dropdown · Eg. Not needed"
    },
    '1stOr2nd': {
        label: "1st or 2nd",
        placeholder: "Dropdown · Eg. 1st"
    },
    'setFor': {
        label: "Set For",
        placeholder: "Dropdown · Eg. Distance"
    },
    'ora': {
        label: "ORA",
        placeholder: "Toggle · Eg. Yes/No"
    },
    'femto': {
        label: "Femto",
        placeholder: "Toggle · Eg. Yes/No"
    },
    'supplies': {
        label: "Supplies",
        placeholder: "Dropdown · Eg. Malyugin Ring"
    },
    'postOpMedications': {
        label: "Post Op Medications",
        placeholder: "Dropdown · Eg. 3x"
    },
    'surgeryComments': {
        label: "Surgery comments",
        placeholder: "Input text · Eg. Combination Drops"
    },
    'generalAnesthesia': {
        label: "General Anesthesia",
        placeholder: "Toggle · Eg. Yes/No"
    },
    'waitlist?': {
        label: "Waitlist?",
        placeholder: "Toggle · Eg. Yes/No"
    }
};

export const SurgeryExchSystemFormFields = ['ascClinic','surgeon','surgeryType','eye','waitlist?','generalAnesthesia','ora','femto'];
export const SurgeryFormExchFields: { [key: string]: AdminPatientSurgeryField } = {
    'ascClinic': {
        label: "ASC/Clinic",
        placeholder: "Dropdown · Eg. ASC Name"
    },
    'surgeon': {
        label: "Surgeon",
        placeholder: "Dropdown · Eg. Surgeon Name"
    },
    'surgeryType': {
        label: "Surgery type",
        placeholder: "Dropdown · Eg. Cataract"
    },
    'eye': {
        label: "Eye",
        placeholder: "Dropdown · Eg. Left"
    },
    'surgeryDate': {
        label: "Surgery date",
        placeholder: "Date Picker · Eg. mm/dd/yyyy"
    },
    'caseComplexity': {
        label: "Case Complexity",
        placeholder: "Dropdown · Eg. Standard"
    },
    'medicalClearance': {
        label: "Medical clearance",
        placeholder: "Dropdown · Eg. Not needed"
    },
    'setFor': {
        label: "Set For",
        placeholder: "Dropdown · Eg. Distance"
    },
    'ora': {
        label: "ORA",
        placeholder: "Toggle · Eg. Yes/No"
    },
    'femto': {
        label: "Femto",
        placeholder: "Toggle · Eg. Yes/No"
    },
    'supplies': {
        label: "Supplies",
        placeholder: "Dropdown · Eg. Malyugin Ring"
    },
    'postOpMedications': {
        label: "Post Op Medications",
        placeholder: "Dropdown · Eg. 3x"
    },
    'surgeryComments': {
        label: "Surgery comments",
        placeholder: "Input text · Eg. Combination Drops"
    },
    'generalAnesthesia': {
        label: "General Anesthesia",
        placeholder: "Toggle · Eg. Yes/No"
    },
    'waitlist?': {
        label: "Waitlist?",
        placeholder: "Toggle · Eg. Yes/No"
    }
};

export const SurgeryOthSystemFormFields = ['ascClinic','surgeon','surgeryType','waitlist?','generalAnesthesia'];
export const SurgeryFormOtherFields: { [key: string]: AdminPatientSurgeryField } = {
    'ascClinic': {
        label: "ASC/Clinic",
        placeholder: "Dropdown · Eg. ASC Name"
    },
    'surgeon': {
        label: "Surgeon",
        placeholder: "Dropdown · Eg. Surgeon Name"
    },
    'surgeryType': {
        label: "Surgery type",
        placeholder: "Dropdown · Eg. Cataract"
    },
    'surgeryDate': {
        label: "Surgery date",
        placeholder: "Date Picker · Eg. mm/dd/yyyy"
    },
    'caseComplexity': {
        label: "Case Complexity",
        placeholder: "Dropdown · Eg. Standard"
    },
    'medicalClearance': {
        label: "Medical clearance",
        placeholder: "Dropdown · Eg. Not needed"
    },
    'surgeryName': {
        label: "Surgery name",
        placeholder: "Input text · Eg. Surgery name"
    },
    'surgerySite': {
        label: "Surgery site",
        placeholder: "Input text · Eg. Surgery type"
    },
    'surgeryComments': {
        label: "Surgery comments",
        placeholder: "Input text · Eg. Combination Drops"
    },
    'generalAnesthesia': {
        label: "General Anesthesia",
        placeholder: "Toggle · Eg. Yes/No"
    },
    'waitlist?': {
        label: "Waitlist?",
        placeholder: "Toggle · Eg. Yes/No"
    }
};

export const IolSystemFormFields = ['iolManufacturer','modelType'];
export const IolSpecFormFields: { [key: string]: AdminPatientSurgeryField } = {
    'iolManufacturer': {
        label: "IOL manufacturer",
        placeholder: "Dropdown · Eg. ALCON"
    },
    'modelType': {
        label: "Model type",
        placeholder: "Dropdown · Eg. Toric"
    },
    'iolModel': {
        label: "IOL Model",
        placeholder: "Dropdown · Eg. CCA0T4"
    },
    'iolDiopter': {
        label: "IOL Diopter",
        placeholder: "Dropdown · Eg. +0.80"
    },
    'implantationAxis': {
        label: "Implantation axis",
        placeholder: "Input number · Eg. 175"
    }
}; 

export const formAndSurgeryMapping: { [key: string]: AdminFormSurgeryMapper } = {
  PATIENT_FIELDS: {
    formName: AdminPatientSurgeryFormName.patient,
    modalTitle: fieldPreferenceModelTitles.PatientInformation,
    surgeryType: SurgeryTypesLabel.all,
    systemFields: PatientSystemFormFields,
    totalFields: Object.keys(PatientFormFields).length
  },
  EXCHANGE_SURGERY_FIELDS: {
    formName: AdminPatientSurgeryFormName.surgery,
    modalTitle: fieldPreferenceModelTitles.SurgeryInformation,
    surgeryType: SurgeryTypesLabel.exchange,
    systemFields: SurgeryExchSystemFormFields,
    totalFields: Object.keys(SurgeryFormExchFields).length
  },
  CATARACT_SURGERY_FIELDS: {
    formName: AdminPatientSurgeryFormName.surgery,
    modalTitle: fieldPreferenceModelTitles.SurgeryInformation,
    surgeryType: SurgeryTypesLabel.catract,
    systemFields: SurgeryCatSystemFormFields,
    totalFields: Object.keys(SurgeryFormCatFields).length
  },
  OTHER_SURGERY_FIELDS: {
    formName: AdminPatientSurgeryFormName.surgery,
    surgeryType: SurgeryTypesLabel.other,
    modalTitle: fieldPreferenceModelTitles.SurgeryInformation,
    systemFields: SurgeryOthSystemFormFields,
    totalFields: Object.keys(SurgeryFormOtherFields).length
  },
  IOL_FIELDS: {
    formName: AdminPatientSurgeryFormName.iolSpecs,
    modalTitle: fieldPreferenceModelTitles.IOLSpecifications,
    surgeryType: 'Cataract, Exchange',
    systemFields: IolSystemFormFields,
    totalFields: Object.keys(IolSpecFormFields).length
  },
};  
