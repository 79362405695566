export enum appEvents{
    reloadData = "reloadData"
}

export interface PostOpMeds {
    label: string;
    value: string
}

export enum dataSaveMode{
    add = 1,
    edit,
    addImports
}

export enum patientAccessLevel {
   owner = 'owner',
   viewer = 'viewer'
}

export enum PatientIdSearchType {
    Asc = 'ASC',
    Clinic = 'Clinic',
}

export enum searchByOptions {
    Patient = 'Patient',
    PatientId = 'Patient ID'
}

export enum srcSystemTypes {
    EHR = 'EHR',
}
export interface selectedFilterOptions {
    value:string,
    type:string
  }

export enum patientImportListType{
    new = 'new',
    existing = 'existing',
    both = 'both'
}  