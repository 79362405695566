import { Injectable } from '@angular/core';
import { PatientAndSurgeryCreationEvents, PatientStatus, Surgery, SurgeryVerificationStatus, TooltipObject } from '../model/surgery';
import { HistoryLog, LogDelta } from './alcon-history-log/Model/alcon-history-log-model';
import * as historyLog from '../components/modal-dialogs/alcon-history-log/alcon-history-log-field-mapping.json';
import { AuthService } from './auth.service';
import { surgeryType } from './model/surgery.model';
import { AXIS_MODELS, oraPreferenceValues, preferenceTypes, recentChangesDaysLimitFromToday } from './constants/constants';
import { patientSurgery } from '../pages/patient/patient-details/patient-details.model';
import { UtilityService } from './service/utility.service';
import { FieldPreference } from './model/preference.model';
import { PatientFormFields, SurgeryFormCatFields, SurgeryFormExchFields, SurgeryFormOtherFields, requiredOrOptional } from '../model/adminFormField';
import { PreferencesService } from './service/preferences.service';
import { SurgeryDashboardField } from './constants/dashboard-configuration.constants';
import { surgeryStatus } from './model/surgery.model';
import { PermissionService } from './service/permission.service';
import { pagePermission } from './constants/auth.constant';

@Injectable({
  providedIn: 'root'
})
export class PatientReadinessService {
  newLine: string = '\n  ';
  bullet: string = '\u2022   ';
  historyLog = JSON.parse(JSON.stringify(historyLog));
  pagePermission = pagePermission;

  constructor(
    private utilityService: UtilityService,
    private preferencesService: PreferencesService,
    public permissionService: PermissionService
  ){}

  
  
  getMandatoryFieldsObject(surgery: Surgery, preferences: FieldPreference[] = []): TooltipObject[]{
    let type = preferenceTypes.otherSurgeryForm;
    let surgeryFormFields = SurgeryFormOtherFields;
    const value = requiredOrOptional.requiredForSurgery;
    const mandatoryFields: TooltipObject[] = [];
    const mapping = this.preferencesService.fieldPreferenceMapping;
    let missingIOL = false;
    if(surgery.surgeryData.procedure === surgeryType.exchange) {
      type = preferenceTypes.exchangeSurgeryForm;
      surgeryFormFields = SurgeryFormExchFields;
      missingIOL = this.checkIsIOLMissing(surgery, preferences);
    } else if(surgery.surgeryData.procedure === surgeryType.catract) {
      type = preferenceTypes.cataractSurgeryForm;
      surgeryFormFields = SurgeryFormCatFields;
      missingIOL = this.checkIsIOLMissing(surgery, preferences);
    }
    preferences.forEach( field => {
      if((field.preferenceType === type || field.preferenceType === preferenceTypes.patientForm ) && field.preferenceValue === value ) {
        field.fieldsList.forEach( item => {
          const mappedKey = ( mapping[item] ) ? mapping[item] : item;
          let value = this.utilityService.getValueFromNestedObject((field.preferenceType === type) ? surgery.surgeryData : surgery.patient, mappedKey);
          if(SurgeryDashboardField.Supplies === item) {
            const hasTrueValue = Object.values(value).some(Boolean);
            value =  hasTrueValue ? value : '';
          }
          mandatoryFields.push({
            name: (field.preferenceType === type) ? surgeryFormFields[item]?.label : PatientFormFields[item]?.label,
            value
          });
        });
      }
    });
    if(missingIOL) {
      /* For primal IOL */
      mandatoryFields.push({
        name: 'Primary IOL',
        value: missingIOL
    });
    }
    return mandatoryFields;
  }

  getIncompleteDetails(surgery: Surgery, preferences: FieldPreference[] = []): string{
    let str = '';
    const fieldsObj = this.getMandatoryFieldsObject(surgery, preferences);
    fieldsObj?.forEach((x: TooltipObject) => {
      if(x.value === undefined || x.value === '' || x.value === null || (x.name === 'Primary IOL' && x.value === true)){
        str += this.bullet + x.name + this.newLine;
      }
    })
    /* commenting preVerificationForm check since it is not required for the current release  */
    // if(!surgery.surgeryData.preVerificationForm){
    //   str += this.bullet + 'Pre-verification Form' + this.newLine;
    // }
    str = (str !== '') ? 'Missing fields:' + this.newLine + str : str;
    return str || '';
  }

  getPatientReadinessTooltip(surgery: Surgery, preferences: FieldPreference[] = []): string{
    const missingFields = this.getIncompleteDetails(surgery, preferences);
    return missingFields ? missingFields : 'No missing fields!'
    /* switch (this.getSurgeryVerificationStatus(surgery)){
      case SurgeryVerificationStatus.missingInfo:
        return  this.getIncompleteDetails(surgery, preferences);
      case SurgeryVerificationStatus.completed:
        return 'No missing fields!';
      case SurgeryVerificationStatus.pending:
        return 'Missing fields:' + this.newLine + this.bullet + 'Pre-verification Form';
      default:
        return '';
    } */
  }

  getSurgeryVerificationStatusToolTipMessage(surgery: Surgery): string{

    switch (this.getSurgeryVerificationStatus(surgery)){
      case SurgeryVerificationStatus.missingInfo:
        return 'Verification not ready to be signed, missing patient or surgery info.';
      case SurgeryVerificationStatus.completed:
        return 'Verification done';
      case SurgeryVerificationStatus.pending:
        return  'Verification missing. Start verification from the menu.';
      default:
        return '';
    }
  }

  checkRequiredFields(surgery: any) {
    let formSubmitted = false;
    surgery?.surgeryData?.preVerificationForm?.createdAt && surgery?.surgeryData?.preVerificationForm?.createdBy ? formSubmitted = true : formSubmitted = false;

    if(this.permissionService.appPermission[pagePermission.surgeries].manageMode) {
      if((surgery.surgeryData.status === surgeryStatus.COMPLETED && !formSubmitted) || (surgery.surgeryData.status === surgeryStatus.CANCELLED && !formSubmitted)) {
        return false;
      } else if((surgery.surgeryData.status === surgeryStatus.COMPLETED && formSubmitted) || (surgery.surgeryData.status === surgeryStatus.CANCELLED && formSubmitted)) {
        return true;
      } else {
        if(surgery.surgeryData.procedure === surgeryType.catract) {
          if(surgery.surgeryData.surgeryDate && surgery.surgeryData.setFor && surgery.surgeryData.firstOrSecondEye && !this.checkIsIOLMissing(surgery)) {
            return true;
          } else { return false }
        } else if (surgery.surgeryData.procedure === surgeryType.exchange && !this.checkIsIOLMissing(surgery)) {
          if(surgery.surgeryData.surgeryDate && surgery.surgeryData.setFor) {
            return true;
          } else { return false }
        } else if (surgery.surgeryData.procedure === surgeryType.other) {
          if(surgery.surgeryData.surgeryDate && surgery.surgeryData.surgeryName && surgery.surgeryData.surgerySite) {
            return true;
          }  else { return false }
        }
      }
    } else if(!this.permissionService.appPermission[pagePermission.surgeries].manageMode) {
      if(surgery.surgeryData.procedure === surgeryType.catract) {
        if(surgery.surgeryData.surgeryDate && surgery.surgeryData.setFor && surgery.surgeryData.firstOrSecondEye && !this.checkIsIOLMissing(surgery)) {
          return true;
        } else { return false }
      } else if (surgery.surgeryData.procedure === surgeryType.exchange && !this.checkIsIOLMissing(surgery)) {
        if(surgery.surgeryData.surgeryDate && surgery.surgeryData.setFor) {
          return true;
        } else { return false }
      } else if (surgery.surgeryData.procedure === surgeryType.other) {
        if(surgery.surgeryData.surgeryDate && surgery.surgeryData.surgeryName && surgery.surgeryData.surgerySite) {
          return true;
        }  else { return false }
      }
    }
   
    return false;
  }
 
  getSurgeryVerificationStatus(surgery: Surgery):SurgeryVerificationStatus{
    if (!this.checkRequiredFields(surgery)) {
         return SurgeryVerificationStatus.missingInfo;
    }
     else 
    {
    //if object preVerificationForm existing means surgery verification is submitted. So set status as Done. 
     if (surgery.surgeryData.preVerificationForm){
      return SurgeryVerificationStatus.completed
     }
     else {
      return  SurgeryVerificationStatus.pending;
     }
    }
  }

  
  getPatientSurgeryTooltip(surgery: Surgery): string {
    let comments: string = '';
    if(surgery?.patient?.additionalInfo?.comments && surgery?.patient?.additionalInfo?.comments?.trim() !== '') {
      comments = 'Patient comments: '  + comments + surgery.patient.additionalInfo.comments?.trim() + this.newLine;
    }
    if(surgery?.surgeryData?.surgeryComment && surgery?.surgeryData?.surgeryComment?.trim() !== '') {
      comments = comments + this.newLine + 'Surgery comments: ' + surgery.surgeryData.surgeryComment?.trim(); 
    }
    if(comments === '') { return 'No comments'; }
    return comments;
  }

  checkIsIOLMissing(obj: Surgery | patientSurgery, preferences:FieldPreference[] = [] ){
    let primaryIol;
    if(obj.surgeryData.iols && Array.isArray(obj.surgeryData.iols)) {
        primaryIol = obj.surgeryData.iols.find((iol: any) => {
        return iol?.lensPriority === 'PRI';
      });
    }  
    if(primaryIol && this.anyMissedMandatoryField(primaryIol, preferences)){
      return false;
    }
    return true;
  }

  anyMissedMandatoryField(primaryIol: any, preferences:FieldPreference[] = []){
    // let copiedObj = {...primaryIol };
    // delete copiedObj["implantationAxis"];
    const mandatoryFields = preferences.find( preference => 
      preference.preferenceType === preferenceTypes.iolForm && preference.preferenceValue === requiredOrOptional.requiredForSurgery 
    );
    if(mandatoryFields?.fieldsList?.length) {
      for (let field of mandatoryFields.fieldsList) {
        field = ( this.preferencesService.fieldPreferenceMapping[field] ) ? this.preferencesService.fieldPreferenceMapping[field] : field;
        if((field === 'implantationAxis' && AXIS_MODELS.includes(primaryIol['lensType']?.toUpperCase()) && (primaryIol[field] == null || primaryIol[field] === ''))) {
          return false;
        } else if (field !== 'implantationAxis' && (primaryIol[field] == null || primaryIol[field] === '')) {
          return false;
        }
      }
    }
    return true;
    // return Object.values(copiedObj).every(value => value !== null && value !== undefined && value !== '');
  }
  getRecentChangesTooltipContent(data: HistoryLog[]) {
    const noOfChanges = 2;
    let requiredChanges: string[] = [];
    for(let change of data) {
      if(this.updatedRecently(change.dateTime)) {
        if(change.event && (change.event !== PatientAndSurgeryCreationEvents.patientCreation) &&  (!change.event.includes( PatientAndSurgeryCreationEvents.surgeryCreation))) {
          const eventText = this.utilityService.capitalizeFirstLetter(change.event);
          if(!requiredChanges.includes(eventText)) { requiredChanges.push(eventText); }
        }
        if(change.logDelta && (change.logDelta?.length > 0)) {
          for(let delta of change.logDelta) {
            if(!requiredChanges.includes(delta.field)) { 
              if (delta.field === 'mrn') {
                delta.field  = this.utilityService.formatPatientId()
              }
              requiredChanges.push(delta.field); 
            }
            if(requiredChanges.length === noOfChanges) { break; }
          }
        }
        if(requiredChanges.length === noOfChanges) { break; }
      } else {
          break;
      }
    }
    let content = '';
    if (requiredChanges.length === 0) {
      content = 'No data changed';
    } else {
      content = 'Recent changes:' + this.newLine;
      requiredChanges.forEach((change: string) => {
        content = content + this.bullet + (this.historyLog[change] ? this.historyLog[change] : change) + this.newLine;
      });
    }
    return content;
  }

  hasRecentChanges(surgery: Surgery): boolean { // to check recent changes in past 7 days
    return this.updatedRecently((surgery.patient.updatedAt)) || this.updatedRecently((surgery.updatedAt));
  }

  updatedRecently(updatedAt?: string | null): boolean { // to check recent changes in past 3 days
    if (!updatedAt) return false;
    const activeDuration = recentChangesDaysLimitFromToday; // 3 days in milli seconds
    const today = new Date().valueOf();
    const _updatedAt = new Date(updatedAt.replace(' ', 'T') + 'Z').valueOf();
    if(((today - _updatedAt) <= activeDuration)) {
      return true;
    } else {
      return false;
    }
  }

}
