import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit, ElementRef, SimpleChanges } from '@angular/core';
import { AuthService } from '../../../app/shared/auth.service';
import { SurgeonService } from 'src/app/shared/surgeon.service';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, IsActiveMatchOptions, Router } from '@angular/router';
import { NavigationService } from 'src/app/shared/service/navigation.service';
import { PermissionService } from 'src/app/shared/service/permission.service';
import { AppConfigurationService } from 'src/app/shared/service/app-configuration.service';

@Component({
  selector: 'tab-navigation',
  templateUrl: './tab-navigation.component.html',
  styleUrls: ['./tab-navigation.component.scss']
})

export class TabNavigationComponent implements OnInit, AfterViewInit{

  @Input() menuItems: any;
  @Input() recordcount: number = 0;
  @Output() onNoSurgeriesFound = new EventEmitter<any>();
  @Output() selectedTab = new EventEmitter<string>();

  surgeriesFound: boolean = true;
  AccountFacilityID: string = ''

  @Input() surgerySummary: any
  activeTab="";
  surgeryTabName = 'surgeries';
  currentLocale = 'en_US';
  readonly myMatchOptions: IsActiveMatchOptions = {
    queryParams: 'ignored',
    matrixParams: 'ignored',
    paths: 'exact',
    fragment: 'ignored',
  };

  constructor(
      public service: AuthService,
      private router: Router,
      public surgeonservice: SurgeonService,
      private dialog: MatDialog,
      private elementRef: ElementRef,
      private activatedRoute: ActivatedRoute,
      private navigationService : NavigationService,
      private permissionService: PermissionService,
      private configService: AppConfigurationService
    ) {
        this.currentLocale = this.navigationService.currentLanguage;
      }

  ngOnChanges(): void
  {
    if(this.menuItems != undefined)
    {
      this.updateSurgerySummaryCount()
      this.setNavigateTab()
    }
  }

  ngOnInit(): void {
    this.setNavigateTab();
    if(this.activatedRoute?.snapshot?.data['routeName']){
      this.surgeryTabName = this.activatedRoute?.snapshot?.data['routeName'];
    }
    
  }

  ngAfterViewInit(): void {
    this.scrollToTab();
  }

  scrollToTab() {
    setTimeout(() => {
      const nativeElement = this.elementRef.nativeElement;
      const tabsContainer = nativeElement.querySelector('.alc_tab-navigation .container');
      const activeTab = nativeElement.querySelector('.alc_tab-navigation a.active');
      const scrollPosition = activeTab?.offsetLeft - tabsContainer?.offsetLeft;
      tabsContainer.scrollLeft = scrollPosition;
    }, 100);
  }

  setNavigateTab(){
    this.activeTab = this.router.url;
  }

  isActiveTab(menu: any){
    return menu.route==this.activeTab
  }

  updateSurgerySummaryCount(){
    if(this.surgerySummary){
      this.menuItems[0]['count'] = this.surgerySummary.allSurgeries?.totalCount
      this.menuItems[1]['count'] = this.surgerySummary.patientInfoIncomplete?.totalCount
      this.menuItems[2]['count'] = this.surgerySummary.unScheduled?.totalCount
      this.menuItems[3]['count'] = this.surgerySummary.surgeryDetailsIncomplete?.totalCount
      this.menuItems[4]['count'] = this.surgerySummary.iolSelectionPending?.totalCount
      this.menuItems[5]['count'] = this.surgerySummary.missingForm?.totalCount
    }
  }

  getCheckMenuPermission(menu : string)
  {
    return this.permissionService.hasPageAccess(menu);
  }

  getFeatureStatus(feature: string){
    return feature ? this.configService.featureFlags[feature] : true;
  }

  errorAlertDialog(msg:any) {
    const dialogRef = this.dialog.open(AlertDialogComponent,{
      data:{
        message: msg,
        buttonText: {
          cancel: 'Ok'
        }
      },
    });
  }

  selectTabs(tabName: string){
    this.surgeryTabName = tabName;
    this.selectedTab.emit(tabName);
  }

}
