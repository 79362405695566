import { ImportPatientFieldMap } from "../models/patient-import-model"

export enum patientImportTableLabel {
    PatientName = 'Patient name',
    DateOfBirth = 'Date of Birth',
    Gender = 'Gender',
    PatientIdMRN = 'Patient ID (MRN)',
    SurgeryType = 'Surgery Type',
    DateFetched = 'Date fetched',
  }

  export enum importFromArgosPages {
    NewPatientImport = 'new-patient-import',
    PatientsToUpdate = 'patients-to-update'
  }

  export const patientAdditionalDetails = [
    {
      label: 'Date of Birth',
      sLabel: 'DOB',
      key: 'birthDate',
      date: true
    },
    {
      label: 'Gender',
      key: 'gender'
    },
    {
      label: 'Patient ID (MRN)',
      key: 'mrn'
    }
  ]

  export const patientComparisonData = [
    {label: 'Argos value', key: 'newValue'},
    {label: 'Clinic Connect value', key: 'currentValue'}
  ]
  export const importPatientFieldMap: ImportPatientFieldMap = { 
    patient: [
      { label: 'First name', field: 'firstName', nonNullable: true},
      { label: 'Middle name', field: 'middleName'},
      { label: 'Last name', field: 'lastName', nonNullable: true},
      { label: 'Gender', field: 'gender', nonNullable: true},
      { label: 'Date of birth', field: 'birthDate', nonNullable: true, date: true},
      { label: 'Patient ID (MRN)', field: 'mrn', nonNullable: true}
    ],
    surgery: [
      { label: 'Clinic', field: 'argosClinic.name', ccField: 'clinic.name'},
      { label: 'ASC', field: 'argosAsc.name', ccField: 'asc.name'},
      { label: 'Surgery date', field: 'surgeryDate', ccField:'surgeryData.surgeryDate', date: true},
      { label: 'Surgery type', field: 'procedure', ccField:'surgeryData.procedure'},
      { label: 'Surgeon', field: 'argosSurgeon.firstName', ccField:'surgeon.firstName'},
      { label: 'Eye', field: 'eye', ccField:'surgeryData.eye'}
    ],
    iol: [
      { label: 'IOL Manufacturer', field: 'iolManufacturer'},
      { label: 'Model Type', field: 'lensType'}, 
      { label: 'IOL Model', field: 'lensModel'},
      { label: 'IOL Diopter', field: 'lensPower', ccField: 'diopter'}
    ]
  }

  export enum FieldType {
    Patient = 'patient',
    Surgery = 'surgery',
    IOL = 'iol'
  }

  export const noImportData = { 
    totalCount: 0,
    patients: []
  }
 

