import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import * as patientImportMockData from '../../../../assets/stub-data/patient-import.json';
import { ImportedPatientData, ImportPatients, SavePayload, FieldMapping, ImportPatientFieldMap, ImportPatientFilterInput, SelectedExistingPatient, PatientPayload, Patient, PatientInfo } from '../models/patient-import-model';
import { PatientImportGraphqlService } from 'src/app/shared/service/alcon-graphql/patient-import-graphql.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import * as patientTableMockData from '../../../../assets/stub-data/patient-table-data.json';
import { SharedService } from 'src/app/shared/service/shared.service';
import { patientImportListType } from 'src/app/model/generic.model';
import { patientDetails, patientSurgery } from '../../patient/patient-details/patient-details.model';
import { FieldType, importPatientFieldMap } from '../constants/patients-import.constants';
import { environment } from 'src/environments/environment';
import { alconHttpOptions } from 'src/app/shared/constants/constants';
import { EYE_LABEL, RECORD_PER_PAGE } from 'src/app/model/surgery';
import { AuthService } from 'src/app/shared/auth.service';
import { lensPriority } from 'src/app/shared/model/iol.model';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { UtilityService } from 'src/app/shared/service/utility.service';
import { patientGenderList } from 'src/app/shared/model/patient.model';

@Injectable({
  providedIn: 'root'
})
export class PatientImportService {

  importedPatientData = new BehaviorSubject<ImportedPatientData | undefined>(undefined);
  patientComparisonData = new BehaviorSubject<any>(patientTableMockData);
  importPatientData!:ImportedPatientData;
  private mccApiURL = environment.apiUrl + '/' + environment.ccApiRoute;
  patientDelta = new BehaviorSubject<any>({ patient:[], surgery: [], iol:[]});
  importedPatientFilterChange$ = new BehaviorSubject<ImportPatientFilterInput | null>(null);
  selectedExistingPatient: SelectedExistingPatient = { currentPatient:  undefined, newPatient: undefined }
   defaultFilterConfig:ImportPatientFilterInput = {
     
     sort: {
       sortBy : '',
       sortField: ''
     },
    
     pagination: {
       limit: RECORD_PER_PAGE,
       offset: 0
     },
     practiceOrg:this.authService.practiseOrg
   };
   filterConfig:ImportPatientFilterInput;

  constructor(
    private patientImportGraphqlService: PatientImportGraphqlService,
    private httpClient: HttpClient,
    private sharedService: SharedService,
    private authService:AuthService,
    private loaderService: LoaderService,
    private utilityService: UtilityService,
    
  ) {
    this.filterConfig = {...this.defaultFilterConfig};

   }

  getImportedPatientList(){

    return this.httpClient.get<ImportPatients>('./assets/stub-data/patient-import-new.json');
   // return this.patientImportGraphqlService.getImportedPatientList();
  }

  getImportedPatientById(id: string){
    // return this.httpClient.get<patientDetails>('./assets/stub-data/argos-patient.json');
    return this.patientImportGraphqlService.getImportedPatientByID(id).pipe(map((res)=>(res?.data?.argosPatientAndSurgeryResponse?.patients?.length>0)? res.data.argosPatientAndSurgeryResponse.patients[0] : null));;
  }

  comparePatientDelta(currentPatient: patientDetails, newPatient: Patient) {/* TODO define the type */
    return { 
      patient:this.mapField(importPatientFieldMap.patient, currentPatient, newPatient, FieldType.Patient), 
      surgery:this.mapField(importPatientFieldMap.surgery, currentPatient, newPatient, FieldType.Surgery), 
      iol:this.mapField(importPatientFieldMap.iol, currentPatient, newPatient, FieldType.IOL), }
  }

  mapEyeLabel(eye: keyof typeof EYE_LABEL): string {
    return EYE_LABEL[eye] || eye;

  }

  getFOrmattedValue(object: any, field: string, fieldSet: FieldMapping) {
      let value = this.utilityService.getValueFromNestedObject(object, field);
      if(fieldSet.label === 'Gender' && value){
        value = patientGenderList.find(obj => obj.value === value)?.label ?? value;
      }
      if(fieldSet.label === 'Surgery type' && value){
        value = this.utilityService.getSurgeryTypeValue(value);
      }
      if(fieldSet.label === 'Eye' && value){
        value =  this.mapEyeLabel(<keyof typeof EYE_LABEL>value);
      }
      if(fieldSet.label === 'IOL Diopter' && value){
        value = this.utilityService.formatLensDiopter(value);
      }
      return value;
  }

  mapField(fieldSet: FieldMapping[], currentPatient: any, newPatient: Patient, fieldType: FieldType) { 
  let currentObject = currentPatient;
  let newObject: any = newPatient;
  if(fieldType === FieldType.Surgery) {
    const currentSurgery = currentPatient?.surgeries.find( (item: any) => item.surgeryId === newPatient?.surgeries?.[0]?.existingSurgeryId )
    currentObject = currentSurgery;
    newObject = newPatient?.surgeries?.[0];
  } else if(fieldType === FieldType.IOL) {
    const currentSurgery = currentPatient?.surgeries.find( (item: any) => item.surgeryId === newPatient?.surgeries?.[0]?.existingSurgeryId )
    currentObject = currentSurgery?.surgeryData.iols?.find((iolItem: any) => { return iolItem?.lensPriority === lensPriority.primary});
    newObject = newPatient?.surgeries?.[0];
  }  
  return fieldSet.map( field => {
      const currentValue  = this.getFOrmattedValue(currentObject, (field?.ccField) ? field.ccField : field.field, field) ?? '';
      const newValue = this.getFOrmattedValue(newObject, field.field, field) ?? '';

      return {
        fieldName: field.label,
        newValue,
        currentValue,
        date: field.date
      }
    })
  }

  updateDefaultFilter() {
    this.filterConfig = { ...this.defaultFilterConfig };
  }

  updateFilterConfig(filter: any) {
    this.filterConfig = filter
  }
  getConfig(){
    return this.importedPatientFilterChange$.getValue() ?? this.filterConfig;
  }

  getPatientToImport(config: any,listType:patientImportListType){
    this.loaderService.showLoader();
    this.patientImportGraphqlService.getPatientImportList(config,listType).subscribe({
      next: (res: any) => {
        this.loaderService.hideLoader();
        if(listType === patientImportListType.both){
          this.importPatientData = {
            newPatient: res.data?.newPatient,
            existingPatient: this.getFormattedValue(res.data?.existingPatient)
          }
          this.importedPatientData.next(this.importPatientData);
        }
        if(listType === patientImportListType.new){
          let importPatientData = {
            newPatient: res.data?.newPatient,
            existingPatient: this.importPatientData?.existingPatient
          }
          this.importedPatientData.next(importPatientData);
        }
        if(listType === patientImportListType.existing){
          let importPatientData = {
            newPatient: this.importPatientData?.newPatient,
            existingPatient: this.getFormattedValue(res.data?.existingPatient)
          }
          this.importedPatientData.next(importPatientData);
        }
      },
      error: () => {
        this.loaderService.hideLoader();
      }
  
    })
  }

  getFormattedValue(existingPatientData: PatientInfo) {
    const existingPatientDataList = existingPatientData?.patients;
    existingPatientDataList?.forEach((patient: Patient)=> {
      patient.gender = this.utilityService.getGender(patient.gender);
       if(patient?.existingPatient?.gender) {
        patient.existingPatient.gender = this.utilityService.getGender(patient?.existingPatient?.gender);     
      }
    })
 
   existingPatientData.patients = existingPatientDataList;

   return existingPatientData
  }
 
  discardPatient(requestId: string) {
    const url = this.mccApiURL + '/discard/patient?requestId=' +requestId;
      return this.httpClient
            .delete<any[]>(url, alconHttpOptions);
  }

  createUpdatePatientPayload(importRequestId: string, includeIol: boolean): SavePayload | undefined {
    if(this.selectedExistingPatient?.currentPatient) {
      return {
        importRequestId: importRequestId,
        patientRequest: this.getPatientPayload(this.selectedExistingPatient.currentPatient), 
        surgeryRequest: this.getSurgeryPayload(includeIol)
      };
    }
    return undefined;
  }


  getSurgeryPayload(includeIol: boolean) {
    const currentPatient = this.selectedExistingPatient.currentPatient;
    const newPatient = this.selectedExistingPatient.newPatient;
    const currentSurgery = currentPatient?.surgeries.find( (item: any) => item.surgeryId === newPatient?.surgeries?.[0]?.existingSurgeryId );
    const newSurgery = this.selectedExistingPatient.newPatient?.surgeries?.[0];

    const iols = currentSurgery?.surgeryData?.iols ?? [];
    if(includeIol) {
      const currentPrimaryIolIndex = iols.findIndex((iolItem ) => { return iolItem?.lensPriority === lensPriority.primary});
      if(currentPrimaryIolIndex >= 0){
        const primaryIol = iols[currentPrimaryIolIndex];
        if(newSurgery?.iolManufacturer){ primaryIol.iolManufacturer = newSurgery?.iolManufacturer }
        if(newSurgery?.lensType){ primaryIol.lensType = newSurgery?.lensType }
        if(newSurgery?.lensModel){ primaryIol.lensModel = newSurgery?.lensModel }
        if(newSurgery?.lensPower){ primaryIol.diopter = this.utilityService.formatLensDiopter(newSurgery.lensPower) }
        iols[currentPrimaryIolIndex] = primaryIol;
      } else if(newSurgery?.iolManufacturer || newSurgery?.lensType || newSurgery?.lensModel || newSurgery?.lensPower) {
        iols.push({
          diopter:  newSurgery?.lensPower ? this.utilityService.formatLensDiopter(newSurgery.lensPower) : '', // newSurgery?.lensPower
          iolManufacturer: newSurgery?.iolManufacturer ?? '',
          lensPriority: lensPriority.primary,
          lensModel: newSurgery?.lensModel ?? '',
          lensType: newSurgery?.lensType ?? ''
      })
      }
    }

    return {
      clinicId: (newSurgery?.argosClinic?.practiceOrg) ? newSurgery.argosClinic.practiceOrg : (currentSurgery?.clinic?.practiceOrg ?? ''), //import possible
      ascId: (newSurgery?.argosAsc?.practiceOrg) ? newSurgery.argosAsc.practiceOrg : (currentSurgery?.asc?.practiceOrg ?? ''), //import possible
      eye: (newSurgery?.eye) ? newSurgery?.eye : currentSurgery?.surgeryData.eye ?? '', //import possible
      procedure: (newSurgery?.procedure) ? newSurgery.procedure : currentSurgery?.surgeryData.procedure, //import possible
      surgeryDate: (newSurgery?.surgeryDate) ? newSurgery.surgeryDate : currentSurgery?.surgeryData?.surgeryDate ?? '',//import possible
      surgeonId: (newSurgery?.argosSurgeon?.id) ? newSurgery?.argosSurgeon?.id : currentSurgery?.surgeon?.id ?? '',//currentSurgery?.surgeon?.id ?? '',

      
      complexity: currentSurgery?.surgeryData.complexity,
      ora: currentSurgery?.surgeryData.ora,
      lensx: currentSurgery?.surgeryData.lensx,
      waitlistInterest: currentSurgery?.surgeryData.waitlistInterest,
      firstOrSecondEye: currentSurgery?.surgeryData.firstOrSecondEye,
      setFor: currentSurgery?.surgeryData.setFor,
      generalAnesthesia: currentSurgery?.surgeryData.generalAnesthesia,
      surgeryComment: currentSurgery?.surgeryData.surgeryComment ?? '',
      postopMeds: currentSurgery?.surgeryData.postopMeds,
      supplies: currentSurgery?.surgeryData.supplies,
      otherSupplies: currentSurgery?.surgeryData.otherSupplies,
      otherMeds: currentSurgery?.surgeryData.otherMeds ?? '',
      surgeryName: currentSurgery?.surgeryData.surgeryName,
      surgerySite: currentSurgery?.surgeryData.surgerySite,
      clearance: currentSurgery?.surgeryData.clearance,
      practiceOrg: (newSurgery?.argosClinic?.practiceOrg) ? newSurgery.argosClinic.practiceOrg : (currentSurgery?.clinic?.practiceOrg ?? ''),
      patientId: currentPatient?.patientId ?? '',
      surgeryId: currentSurgery?.surgeryId,
      iols: iols
    }
  }

  private getAllergyString(allergies: any){
    return allergies ? (Array.isArray(allergies) ? allergies.join(', ') : allergies) : '';
  }

  getPatientPayload(patientData: patientDetails):PatientPayload {
    const newPatient = this.selectedExistingPatient.newPatient;
    return {
      title: patientData.prefix ? patientData.prefix.replace(/[\[\]]/g, '') : '', //TODO - verify
      gender: (newPatient?.gender) ? newPatient.gender : patientData.gender, //import possible
      firstName: (newPatient?.firstName) ? newPatient.firstName : patientData.firstName, //import possible
      middleName: (newPatient?.middleName) ? newPatient.middleName : patientData.middleName, //import possible
      lastName: (newPatient?.lastName) ? newPatient.lastName : patientData.lastName, //import possible
      dateOfBirth: (newPatient?.birthDate) ? newPatient.birthDate : ''+patientData.birthDate, //import possible
      patientMrn: (newPatient?.mrn) ? newPatient.mrn : patientData.mrn, //import possible
      phoneNumberCountryCode: patientData.additionalInfo.phoneNumberCountryCode,
      phoneNumber: patientData.phone ?? '',
      alternateContactName: patientData.additionalInfo.alternateContactName ?? '',
      alternatePhoneNumber: patientData.additionalInfo.alternatePhoneNumber ?? '',
      email: patientData.email ?? '',
      diabetesStatus: patientData.additionalInfo.diabetesStatus,
      allergies: this.getAllergyString(patientData.additionalInfo.allergyOther),
      preferredLanguage:  {
        code: patientData.languageCode ?? '',
        display: patientData.languageDisplay ?? ''
      },
      translatorNeeded: patientData.additionalInfo.translatorNeeded,
      patientTimeOfDayPreference: patientData.additionalInfo.patientTimeOfDayPreference ?? '',
      comments: patientData.additionalInfo.comments,
      signLanguage: patientData.additionalInfo.signLanguage,
      practiceOrg: patientData.practiceId,
      id: patientData.patientId
    }
  }

  savePatientAndSurgery(payload: SavePayload){
    const url = this.mccApiURL + '/import/argos/patient';
     return this.httpClient.post<HttpResponse<any>>(url, JSON.stringify(payload), alconHttpOptions).pipe(
       map((resp:any) => this.sharedService.extractData<any>(resp, url, alconHttpOptions))
     )
    } 

}
