import { ConfigurationFeatures } from "../model/app-configuration.model";

export const defaultConfigurationData = {
    [ConfigurationFeatures.surgeriesPendingActionWidget]: true,
    [ConfigurationFeatures.enableSurgeryFilter]: true,
    [ConfigurationFeatures.surgeryExportToPDF]: true,
    [ConfigurationFeatures.surgeryExportToExcel]: true,
    [ConfigurationFeatures.viewSurgeryLog]: true,
    [ConfigurationFeatures.viewPatientLog]: true,
    [ConfigurationFeatures.enableForms]: true,
    [ConfigurationFeatures.enableIOLPreferences]: true,
    [ConfigurationFeatures.enableFieldPreferences]: true,
    [ConfigurationFeatures.arrivalTimeSettings]: true,
    [ConfigurationFeatures.ehrSystemName]: '',
    [ConfigurationFeatures.enableEHRWidget]: false,
    [ConfigurationFeatures.enableINORExperience]: false,
    [ConfigurationFeatures.enableArgosWidget]: false,
    [ConfigurationFeatures.dateFormat] : 'MM/dd/y',
    [ConfigurationFeatures.timeFormat] : "h:mm a",
    [ConfigurationFeatures.timeFormatNoSpace] : "h:mma",
    [ConfigurationFeatures.dateFormatShortMonth] : 'MMM d, y'
}