import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { lensTypeNames } from '../model/iol.model';
import { Subscription } from 'rxjs';
import { IolModelPreferencesService } from '../../pages/iol-model-preferences/services/iol-model-preferences.service';

@Component({
  selector: 'app-alcon-searchable-multiselect-dropdown',
  standalone: true,
  imports: [CommonModule,
    MatIconModule,
    MatSelectModule,MatInputModule, MatFormFieldModule, FormsModule, ReactiveFormsModule, CommonModule
  ],
  templateUrl: './alcon-searchable-multiselect-dropdown.component.html',
  styleUrl: './alcon-searchable-multiselect-dropdown.component.scss'
})
export class AlconSearchableMultiselectDropdownComponent implements OnInit, OnChanges{

  searchableMultiSelectControl = new FormControl();
  searchKey = '';
  @Input() allOptions: Array<lensTypeNames> = [];
  @Output() selectedOptions: EventEmitter<string> = new EventEmitter();
  selectedDataOrg: Array<lensTypeNames> = [];
  allMultiDropdownData:any;
  selectedData: any = [];
  selectedQueryMatch = 1;
  unSelectedOptionsQueryMatch = 0;
  @ViewChild('searchableSelect', {static: false}) searchableSelect: any;
  existingSelectedIDs: string = "";

  constructor(){}
  close(isOpen: boolean){
    if(isOpen){
      this.handleKeyboardAction();
      this.segregateOption();
    } else {
      if(this.searchableMultiSelectControl.value && this.searchableMultiSelectControl.value.length > 0){
        this.selectedData = this.searchableMultiSelectControl.value;
        const selValue = this.selectedData.map((val: any) => val.id).join(', ');
        this.selectedOptions.emit(selValue);
        this.existingSelectedIDs = selValue;
      }else{
        this.selectedOptions.emit('');
        this.existingSelectedIDs = '';
      }
      this.resetSearch();
    }
    this.evaluateOptionCountForDisplay();
  }
  resetSearch(){
    this.searchKey = '';
    this.allMultiDropdownData.forEach((option:any) => {option['isSearchNotMatching'] = false});
    this.selectedData = this.selectedDataOrg;
    this.segregateOption();
    this.evaluateOptionCountForDisplay();
  }

  ngOnInit() {
    this.allMultiDropdownData = this.allOptions;
    this.evaluateOptionCountForDisplay();
  }

  updateSelectedOptions(selectedIdsString: string): void {
    const selectedIdsArray = selectedIdsString.split(',').map(id => id.trim());
    this.selectedDataOrg = this.allOptions.filter((option: any) => selectedIdsArray.includes(option.id.toString()));
    this.searchableMultiSelectControl.setValue(this.selectedDataOrg);
   this.selectedDataOrg.forEach((item:any) => {item['isSelected'] = true})
    this.selectedData = this.selectedDataOrg;
    
    this.allMultiDropdownData = this.allMultiDropdownData.map((obj:any) => ({
      ...obj,
      isSelected: selectedIdsArray.includes(obj.id),
      isSearchNotMatching: false
    }));
    
    this.evaluateOptionCountForDisplay();
  }

  ngOnChanges(changes: SimpleChanges): any 
  {
    if(changes["allOptions"] && this.allOptions){
      this.allMultiDropdownData = this.allOptions;
      this.evaluateOptionCountForDisplay();
    }
    if(this.existingSelectedIDs.length && this.allOptions.length ){
      this.updateSelectedOptions(this.existingSelectedIDs);
    }
  }
  searchOptions(value: any){
    if(value){
      this.allMultiDropdownData.forEach((name: any) =>{
        if(name.name.toLowerCase().includes(value.toLowerCase())) {
                name['isSearchNotMatching'] = false;
              }else{
                name['isSearchNotMatching'] = true;
              }
      });
        this.evaluateOptionCountForDisplay();
    } else{
      this.resetSearch();
    }
  }

  onSelectionChange($event: any){
   this.selectedDataOrg = $event.value;
  }

  segregateOption(){
    this.allMultiDropdownData = this.allMultiDropdownData.map((obj:any) => ({
      ...obj,
      isSelected: this.existingSelectedIDs.includes(obj.id)
    }));
    this.selectedDataOrg.forEach((item: any) => {item['isSelected'] = true})
  }

  evaluateOptionCountForDisplay(){
    const selectedMatch = this.allMultiDropdownData?.filter((option:any) => option['isSelected'] && !option['isSearchNotMatching']);
    const allOptionsMatch = this.allMultiDropdownData?.filter((option:any) => !option['isSelected'] && !option['isSearchNotMatching']);
    this.selectedQueryMatch = selectedMatch?.length;
    this.unSelectedOptionsQueryMatch = allOptionsMatch?.length;
  }

  clearAll(){
    this.selectedDataOrg = [];
    this.selectedData =[];
    this.allMultiDropdownData = this.allOptions;
    this.searchKey = "";
    this.searchableMultiSelectControl.setValue(this.selectedDataOrg);
    this.segregateOption();
    this.evaluateOptionCountForDisplay();
  }


  handleKeyboardAction(){
    this.searchableSelect._handleKeydown = (event: KeyboardEvent) => {
      if (event.key === 'Enter'){
        this.searchableSelect.close();
        return
      }
      if (!this.searchableSelect.disabled) {
        this.searchableSelect.panelOpen
          ? this.searchableSelect._handleOpenKeydown(event)
          : this.searchableSelect._handleClosedKeydown(event);
      }
    };
  }
}
