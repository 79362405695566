<div *ngIf="!isDarkTheme" class="or-avatar-light-container">
    <img src="assets/images/or-avatar-light.png" class="or-avatar-light-face or-avatar-face">
    <div class="or-avatar-text-box">
        <div class="or-avatar-text">
            {{displayText}}
            <mat-icon *ngIf="displayText.includes('---')" class="alcon-ore-alert-icon" svgIcon="alert_triangle"></mat-icon>
        </div>            
    </div>
    <div class="or-avatar-eye-pointer" [ngClass]="{'or-avatar-eye-pointer--right': eye == 'right'}">
        <svg width="205" height="202" viewBox="0 0 205 202" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="102.807" cy="102.114" r="16.0338" fill="#0075CE"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M102.207 153.556C130.175 153.556 152.847 130.884 152.847 102.917C152.847 74.9492 130.175 52.2772 102.207 52.2772C74.2401 52.2772 51.5681 74.9492 51.5681 102.917C51.5681 130.884 74.2401 153.556 102.207 153.556ZM164.426 102.917C164.426 137.279 136.57 165.135 102.207 165.135C67.8452 165.135 39.9892 137.279 39.9892 102.917C39.9892 68.5543 67.8452 40.6983 102.207 40.6983C136.57 40.6983 164.426 68.5543 164.426 102.917Z" fill="#0075CE"/>
            <path d="M102.205 46.4922L82.3806 12.1558L122.029 12.1558L102.205 46.4922Z" fill="#0075CE"/>
            <path d="M45.7819 102.92L11.4455 122.744L11.4455 83.0961L45.7819 102.92Z" fill="#0075CE"/>
            <path d="M102.207 155.621L122.032 189.957H82.3833L102.207 155.621Z" fill="#0075CE"/>
            <path d="M158.636 102.922L192.973 83.0975V122.746L158.636 102.922Z" fill="#0075CE"/>
        </svg>             
    </div>
</div>
<div *ngIf="isDarkTheme" class="or-avatar-dark-container">
    <img src="assets/images/or-avatar-dark.png" class="or-avatar-dark-face or-avatar-face">
    <div class="or-avatar-text-box">
        <div class="or-avatar-text">
            {{displayText}}
            <mat-icon *ngIf="displayText.includes('---')" class="alcon-ore-alert-icon" svgIcon="alert_triangle"></mat-icon>
        </div>            
    </div>
    <div class="or-avatar-eye-pointer" [ngClass]="{'or-avatar-eye-pointer--right': eye == 'right'}">
        <svg width="205" height="202" viewBox="0 0 205 202" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="102.807" cy="102.114" r="16.0338" fill="#0075CE"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M102.207 153.556C130.175 153.556 152.847 130.884 152.847 102.917C152.847 74.9492 130.175 52.2772 102.207 52.2772C74.2401 52.2772 51.5681 74.9492 51.5681 102.917C51.5681 130.884 74.2401 153.556 102.207 153.556ZM164.426 102.917C164.426 137.279 136.57 165.135 102.207 165.135C67.8452 165.135 39.9892 137.279 39.9892 102.917C39.9892 68.5543 67.8452 40.6983 102.207 40.6983C136.57 40.6983 164.426 68.5543 164.426 102.917Z" fill="#0075CE"/>
            <path d="M102.205 46.4922L82.3806 12.1558L122.029 12.1558L102.205 46.4922Z" fill="#0075CE"/>
            <path d="M45.7819 102.92L11.4455 122.744L11.4455 83.0961L45.7819 102.92Z" fill="#0075CE"/>
            <path d="M102.207 155.621L122.032 189.957H82.3833L102.207 155.621Z" fill="#0075CE"/>
            <path d="M158.636 102.922L192.973 83.0975V122.746L158.636 102.922Z" fill="#0075CE"/>
        </svg>                      
    </div>
</div>